// context/AuthContext.js
import React, { createContext, useState, useEffect } from 'react';
import api from '../authentication/axiosInstance';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [userRole, setUserRole] = useState(null);
  const [userEmail, setUserEmail] = useState(null);
  const [user, setUser] = useState(null);
  const [clientId, setClientId] = useState(null);
  const [userId, setUserId] = useState(null);
  const [loading, setLoading] = useState(true);
  const [hasSnowEdgeAccess, setHasSnowEdgeAccess] = useState(false);
  const [hasRegularRequestAccess, setHasRegularRequestAccess] = useState(false);

  // Move checkAuth outside of useEffect
  const checkAuth = async () => {
    try {
      const token = localStorage.getItem('accessToken');
      if (token) {
        // API call to check authentication by passing the JWT in the Authorization header
        const response = await api.get('/users/check-auth/', {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });
        
        setIsAuthenticated(response.data.isAuthenticated);
        setUserRole(response.data.role);
        setUserEmail(response.data.email);
        setUser(response.data.user);
        setClientId(response.data.client_id);
        setUserId(response.data.user_id);
        setHasSnowEdgeAccess(response.data.has_snowedge_access);
        setHasRegularRequestAccess(response.data.has_regular_request_access);
      } else {
        setIsAuthenticated(false);
        clearAuthState();
      }
    } catch (error) {
      console.error('Auth check failed:', error);
      setIsAuthenticated(false);
      clearAuthState();
    } finally {
      setLoading(false); // Ensure loading is false once checkAuth finishes
    }
  };

  const clearAuthState = () => {
    setUserRole(null);
    setUserEmail(null);
    setUser(null);
    setClientId(null);
    setUserId(null);
    setHasSnowEdgeAccess(false);
    setHasRegularRequestAccess(false);
  };

  // Run checkAuth once when the provider mounts
  useEffect(() => {
    checkAuth();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <AuthContext.Provider value={{
      isAuthenticated,
      setIsAuthenticated,
      userRole,
      setUserRole,
      userEmail,
      setUserEmail,
      clientId,
      setClientId,
      userId,
      setUserId,
      user,
      setUser,
      checkAuth, // Expose checkAuth function
      loading,
      hasSnowEdgeAccess,
      setHasSnowEdgeAccess,
      hasRegularRequestAccess,
      setHasRegularRequestAccess
    }}>
      {children}
    </AuthContext.Provider>
  );
};
