// src/components/Profile/EditClientUserSites.js

import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  List,
  ListItem,
  ListItemIcon,
  Checkbox,
  ListItemText,
  Typography,
  TextField,
  Box,
  Chip,
  Fade,
  InputAdornment,
} from '@mui/material';
import { Search as SearchIcon } from '@mui/icons-material';
import { motion, AnimatePresence } from 'framer-motion';
import api from '../../authentication/axiosInstance';
import styles from './Profile.module.css';

const EditClientUserSites = ({ member, open, onClose, createNotification, onAccessibleSitesUpdate }) => {
  const [clientSites, setClientSites] = useState([]);
  const [accessibleSites, setAccessibleSites] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (open && member?.accessible_sites) {
      setAccessibleSites(member.accessible_sites || []);
    }
  }, [member, open]);

  useEffect(() => {
    if (open && member?.client?.client_id) {
      const fetchSites = async () => {
        setIsLoading(true);
        try {
          const response = await api.get(`/clients/${member.client.client_id}/sites/`);
          setClientSites(response.data);
        } catch (error) {
          console.error('Error fetching sites:', error);
          createNotification('Failed to fetch client sites', 'error');
        } finally {
          setIsLoading(false);
        }
      };
      fetchSites();
    }
  }, [member, open, createNotification]);

  const handleToggle = (siteId) => {
    setAccessibleSites((prevSelected) => {
      if (prevSelected.includes(siteId)) {
        return prevSelected.filter((id) => id !== siteId);
      } else {
        return [...prevSelected, siteId];
      }
    });
  };

  const handleSave = async () => {
    try {
      await api.patch(`/users/${member.id}/update/`, { accessible_sites: accessibleSites });
      createNotification('Accessible sites updated successfully', 'success');
      onAccessibleSitesUpdate(member.id, accessibleSites);
      onClose();
    } catch (error) {
      console.error('Error updating accessible sites:', error);
      createNotification('Failed to update accessible sites', 'error');
    }
  };

  const filteredSites = clientSites.filter((site) =>
    site.site_name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const getSelectedSiteNames = () => {
    return accessibleSites
      .map((siteId) => clientSites.find((site) => site.site_id === siteId)?.site_name)
      .filter(Boolean);
  };

  return (
    <Dialog 
      open={open} 
      onClose={onClose} 
      maxWidth="sm" 
      fullWidth
      TransitionComponent={Fade}
      transitionDuration={300}
      PaperProps={{
        style: {
          borderRadius: '12px',
          padding: '16px',
        },
      }}
    >
      <DialogTitle className={styles.dialogTitle}>
        <Typography variant="h5" component="div" style={{ 
          fontFamily: "'Plus Jakarta Sans', sans-serif",
          fontWeight: 600,
          fontSize: '24px',
          marginBottom: '8px'
        }}>
          Edit Accessible Sites
        </Typography>
        <Typography variant="subtitle1" style={{
          fontFamily: "'Plus Jakarta Sans', sans-serif",
          color: '#555555',
          fontSize: '14px'
        }}>
          Select the sites that {member?.first_name || 'this user'} can access
        </Typography>
      </DialogTitle>

      <DialogContent style={{ paddingTop: '16px' }}>
        <TextField
          fullWidth
          variant="outlined"
          placeholder="Search sites..."
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon style={{ color: '#555555' }} />
              </InputAdornment>
            ),
          }}
          style={{ marginBottom: '16px' }}
        />

        <AnimatePresence>
          {accessibleSites.length > 0 && (
            <motion.div
              initial={{ opacity: 0, y: -10 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -10 }}
              style={{ marginBottom: '16px' }}
            >
              <Box display="flex" flexWrap="wrap" gap={1}>
                {getSelectedSiteNames().map((siteName) => (
                  <Chip
                    key={siteName}
                    label={siteName}
                    color="primary"
                    variant="outlined"
                    style={{
                      borderRadius: '6px',
                      fontFamily: "'Plus Jakarta Sans', sans-serif",
                    }}
                  />
                ))}
              </Box>
            </motion.div>
          )}
        </AnimatePresence>

        <List style={{ 
          maxHeight: '300px', 
          overflowY: 'auto',
          border: '1px solid #eeeef2',
          borderRadius: '8px',
          padding: '8px'
        }}>
          {isLoading ? (
            <ListItem>
              <ListItemText primary="Loading sites..." />
            </ListItem>
          ) : filteredSites.length === 0 ? (
            <ListItem>
              <ListItemText primary="No sites found" />
            </ListItem>
          ) : (
            filteredSites.map((site) => (
              <motion.div
                key={site.site_id}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 0.2 }}
              >
                <ListItem
                  button
                  onClick={() => handleToggle(site.site_id)}
                  style={{
                    borderRadius: '6px',
                    marginBottom: '4px',
                    backgroundColor: accessibleSites.includes(site.site_id) ? '#f5f5f5' : 'transparent',
                  }}
                >
                  <ListItemIcon>
                    <Checkbox
                      edge="start"
                      checked={accessibleSites.includes(site.site_id)}
                      tabIndex={-1}
                      disableRipple
                      style={{
                        color: accessibleSites.includes(site.site_id) ? '#1976d2' : undefined,
                      }}
                    />
                  </ListItemIcon>
                  <ListItemText 
                    primary={
                      <Typography style={{
                        fontFamily: "'Plus Jakarta Sans', sans-serif",
                        fontWeight: 500,
                        fontSize: '14px'
                      }}>
                        {site.site_name}
                      </Typography>
                    }
                  />
                </ListItem>
              </motion.div>
            ))
          )}
        </List>
      </DialogContent>

      <DialogActions style={{ padding: '16px' }}>
        <Button 
          onClick={onClose}
          variant="outlined"
          style={{
            textTransform: 'none',
            fontFamily: "'Plus Jakarta Sans', sans-serif",
            fontWeight: 500,
            borderRadius: '6px'
          }}
        >
          Cancel
        </Button>
        <Button 
          onClick={handleSave}
          variant="contained"
          color="primary"
          style={{
            textTransform: 'none',
            fontFamily: "'Plus Jakarta Sans', sans-serif",
            fontWeight: 500,
            borderRadius: '6px'
          }}
        >
          Save Changes
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditClientUserSites;