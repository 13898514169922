// src/components/Profile/RoleSelect.js

import React, { useState } from 'react';
import { Menu, MenuItem, Box, Typography } from '@mui/material';
import styles from './Profile.module.css';

const RoleSelect = ({ member, onRoleChange }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  
  const handleOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChange = (newRole) => {
    onRoleChange(member.id, newRole);
    handleClose();
  };

  return (
    <Box>
      <Box
        className={styles.roleSelect}
        onClick={handleOpen}
      >
        {member.role.charAt(0).toUpperCase() + member.role.slice(1)}
      </Box>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        disableAutoFocusItem
        sx={{
          "& .MuiMenu-paper": {
            boxShadow:
              "rgba(145, 158, 171, 0.2) 0px 0px 2px 0px, rgba(145, 158, 171, 0.12) 0px 12px 24px -4px",
            borderRadius: "3px",
            fontFamily: "var(--font)",
            fontWeight: "600",
            fontSize: "16px",
            marginTop: "5px",
          },
        }}
      >
        {['standard', 'admin', 'client'].map((role) => (
          <MenuItem
            key={role}
            className={styles.menuItem}
            onClick={() => handleChange(role)}
          >
            <Typography className={styles.menuText}>
              {role.charAt(0).toUpperCase() + role.slice(1)}
            </Typography>
          </MenuItem>
        ))}
      </Menu>
    </Box>
  );
};

export default RoleSelect;