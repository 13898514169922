import React, { useState, useEffect, useMemo } from 'react';
import { Table, message, Input, Select, Button, Modal, Popconfirm } from 'antd';
import { DownloadOutlined, MailOutlined} from '@ant-design/icons';
import { Box } from '@mui/material';
import api from "../authentication/axiosInstance";
import moment from 'moment-timezone';
import { IconCheck, IconX } from '@tabler/icons-react';

const { Option } = Select;

const MessageStatusCounter = ({ counts }) => {
  return (
    <div style={{
      display: 'flex',
      gap: '12px',
      alignItems: 'center',
      justifyContent: 'flex-end',
      marginBottom: '16px',
      marginRight: '30px',
      fontFamily: 'Plus Jakarta Sans, sans-serif'
    }}>
      <div style={{
        display: 'flex',
        alignItems: 'center',
        padding: '6px 12px',
        borderRadius: '5px',
        backgroundColor: '#f0f0f0',
        gap: '6px'
      }}>
        <span style={{ color: '#555555', fontSize: '14px', fontWeight: '500' }}>Unread</span>
        <span style={{ 
          backgroundColor: '#034832',
          color: 'white',
          padding: '2px 8px',
          borderRadius: '4px',
          fontSize: '13px',
          fontWeight: '600'
        }}>{counts.unread || 0}</span>
      </div>
      
      <div style={{
        display: 'flex',
        alignItems: 'center',
        padding: '6px 12px',
        borderRadius: '5px',
        backgroundColor: '#E5F2EC',
        gap: '6px'
      }}>
        <span style={{ color: '#034832', fontSize: '14px', fontWeight: '500' }}>Confirmed</span>
        <span style={{ 
          backgroundColor: '#034832',
          color: 'white',
          padding: '2px 8px',
          borderRadius: '4px',
          fontSize: '13px',
          fontWeight: '600'
        }}>{counts.confirmed || 0}</span>
      </div>
      
      <div style={{
        display: 'flex',
        alignItems: 'center',
        padding: '6px 12px',
        borderRadius: '5px',
        backgroundColor: '#FDEAEA',
        gap: '6px'
      }}>
        <span style={{ color: '#FF4D4E', fontSize: '14px', fontWeight: '500' }}>Denied</span>
        <span style={{ 
          backgroundColor: '#FF4D4E',
          color: 'white',
          padding: '2px 8px',
          borderRadius: '4px',
          fontSize: '13px',
          fontWeight: '600'
        }}>{counts.denied || 0}</span>
      </div>
    </div>
  );
};

const MessageListView = () => {
  const [messages, setMessages] = useState([]);

  const fetchMessages = async () => {
    try {
      const response = await api.get('/dispatch/warning_messages/');
      setMessages(response.data);
    } catch (error) {
      console.error('Error fetching messages:', error);
      message.error('Failed to fetch messages');
    }
  };

  useEffect(() => {
    fetchMessages();
  }, []);

  // State variables for filters
  const [regionFilter, setRegionFilter] = useState('all');
  const [staffFilter, setStaffFilter] = useState('');
  const [confirmationStatusFilter, setConfirmationStatusFilter] = useState('all');
  const [stormIdFilter, setStormIdFilter] = useState('all');

  const handleResend = async (message_id) => {
    const response = await api.post("/dispatch/warning_messages/", {
      resend_warning_message: true,
      warning_message_id: message_id,
    });

    if (response.status === 200) {
      message.success('Message resent successfully!');
    } else {
      message.error('Failed to resend the message.');
    }
  };

  const handleConfirm = async (message_id) => {
    try {
      const response = await api.post("/dispatch/warning_messages/", {
        manual_confirm_message: true,
        warning_message_id: message_id,
      });
  
      if (response.status === 200) {
        message.success('Message confirmed successfully!');
      } else {
        message.error('Failed to confirm the message.');
      }
    } catch {
      message.error('Failed to confirm the message.');
      console.log('Failed to confirm the message.');
    } finally {
      fetchMessages();
    }
  };

  const handleDeny = async (message_id) => {
    try {
      const response = await api.post("/dispatch/warning_messages/", {
        manual_deny_message: true,
        warning_message_id: message_id,
      });
  
      if (response.status === 200) {
        message.success('Message confirmed successfully!');
      } else {
        message.error('Failed to confirm the message.');
      }
    } catch {
      message.error('Failed to confirm the message.');
      console.log('Failed to confirm the message.');
    } finally {
      fetchMessages();
    }
  };

  const handleGenerateReport = async () => {
    try {
      const region = regionFilter !== 'all' ? regionFilter : undefined;

      const response = await api.get('/dispatch/message-report/', {
          params: { region },
          responseType: 'blob', // Expect a file response
      });

      // Create a blob from the response data
      const blob = new Blob([response.data], { type: 'application/pdf' });

      // Create a temporary link to download the PDF
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = `message_report_${region || 'all'}.pdf`;
      link.click();

      message.success('Report downloaded successfully!');
    } catch (error) {
      console.error('Error generating report:', error);
      message.error('Failed to generate the report.');
    }
  };


  // Extract unique regions and statuses from messages
  const regions = useMemo(() => {
    const allRegions = messages.map(msg => msg.region).filter(region => region);
    return Array.from(new Set(allRegions));
  }, [messages]);

  const statuses = useMemo(() => {
    const allStatuses = messages.map(msg => msg.status).filter(status => status);
    return Array.from(new Set(allStatuses));
  }, [messages]);

  // Generate storm options sorted by 'storm_date'
  const stormOptions = useMemo(() => {
    const stormsMap = {};
    messages.forEach(msg => {
      if (msg.storm && !stormsMap[msg.storm]) {
        stormsMap[msg.storm] = {
          id: msg.storm,
          type: msg.storm_type,
          date: msg.storm_date,
          created: msg.storm_created, // Assuming 'storm_created' exists in the message
        };
      }
    });
    // Convert the map to an array and sort by 'storm_date' descending
    return Object.values(stormsMap).sort((a, b) => new Date(b.date) - new Date(a.date));
  }, [messages]);

  // Find the storm with the latest 'storm_created'
  const latestCreatedStorm = useMemo(() => {
    if (messages.length === 0) return null;

    // Filter messages that have a storm
    const stormsWithCreated = messages
      .filter(msg => msg.storm && msg.storm_created)
      .map(msg => ({
        id: msg.storm,
        created: msg.storm_created,
      }));

    if (stormsWithCreated.length === 0) return null;

    // Find the storm with the latest 'storm_created'
    const latestStorm = stormsWithCreated.reduce((latest, current) => {
      return new Date(current.created) > new Date(latest.created) ? current : latest;
    }, stormsWithCreated[0]);

    return latestStorm;
  }, [messages]);

  // Set the default storm filter to the latest storm based on 'storm_created'
  useEffect(() => {
    if (latestCreatedStorm) {
      setStormIdFilter(latestCreatedStorm.id);
    } else {
      setStormIdFilter('all');
    }
  }, [latestCreatedStorm]);

  const filteredMessages = useMemo(() => {
    return messages.filter(msg => {
      const matchesRegion = !regionFilter || regionFilter === 'all' || msg.region === regionFilter;
      const matchesStaff = !staffFilter || (msg.staff_name && msg.staff_name.toLowerCase().includes(staffFilter.toLowerCase()));
      const matchesStatus = !confirmationStatusFilter || confirmationStatusFilter === 'all' || msg.status === confirmationStatusFilter;
      const matchesStormId = !stormIdFilter || stormIdFilter === 'all' || msg.storm === stormIdFilter;
      return matchesRegion && matchesStaff && matchesStatus && matchesStormId;
    });
  }, [messages, regionFilter, staffFilter, confirmationStatusFilter, stormIdFilter]);

  const handleEmailTMWarnings = async () => {
    try {
      // Fetch preview data
      const previewResponse = await api.get('/dispatch/regional-email/', {
        params: { preview_only: true },
      });
  
      if (previewResponse.status === 200) {
        const { emailed_managers, failed_emails } = previewResponse.data;
  
        // Construct the list of emails and failures to show in the modal
        const emailList = emailed_managers && emailed_managers.length
          ? emailed_managers.join(', ')
          : "No managers available to email.";
  
        const failureList = failed_emails && failed_emails.length
          ? failed_emails.map((fail) => `${fail.manager} (${fail.reason})`).join(', ')
          : "No issues detected.";
  
        // Open the modal and display the preview
        Modal.confirm({
          title: "Are you sure?",
          content: (
            <div>
              <p>This action will email all Territory Managers with the latest reports. Do you want to proceed?</p>
              <p><b>Emails to be sent to:</b> {emailList}</p>
              {failed_emails.length > 0 && (
                <p style={{ color: 'red' }}><b>Potential Issues:</b> {failureList}</p>
              )}
            </div>
          ),
          okText: "Yes, Proceed",
          cancelText: "Cancel",
          onOk: async () => {
            try {
              // Trigger the actual email-sending process
              const finalResponse = await api.get('/dispatch/regional-email/');
  
              if (finalResponse.status === 200) {
                const { successfully_emailed, failed_emailed } = finalResponse.data;
  
                // Construct success and failure messages
                const successMessage = successfully_emailed && successfully_emailed.length
                  ? `Emails sent successfully to: ${successfully_emailed.join(', ')}`
                  : "No emails were sent successfully.";
  
                const failureMessage = failed_emailed && failed_emailed.length
                  ? `Failed to send emails to: ${failed_emailed
                      .map((fail) => `${fail.manager} (${fail.reason})`)
                      .join(', ')}` 
                  : "No email failures.";
  
                // Display notifications
                if (successfully_emailed && successfully_emailed.length) {
                  message.success(successMessage, 10); // Show for 10 seconds
                }
                if (failed_emailed && failed_emailed.length) {
                  message.error(failureMessage, 10); // Show for 10 seconds
                }
              } else {
                message.error("Failed to send emails to Territory Managers. Please try again.");
              }
            } catch (error) {
              console.error("Error sending emails to Territory Managers:", error);
              message.error("An error occurred while sending emails. Please try again.");
            }
          },
          onCancel: () => {
            message.info("Action cancelled.");
          },
        });
      } else {
        message.error("Failed to fetch preview data for Territory Managers. Please try again.");
      }
    } catch (error) {
      console.error("Error fetching preview data for Territory Managers:", error);
      message.error("An error occurred while fetching preview data. Please try again.");
    }
  };
  
  const columns = [
    {
      title: 'Staff',
      dataIndex: 'staff_name',
      key: 'staff_name',
      width: 125,
    },
    {
      title: 'Region',
      dataIndex: 'region',
      key: 'region',
      width: 90,
    },
    {
      title: 'Storm Info',
      key: 'storm_info',
      width: 125,
      render: (text, record) => (
        <div>
          <p style={{ margin: '4px 0' }}>
            <strong>Type:</strong> {record.storm_type}
          </p>
          <p style={{ margin: '4px 0' }}>
            <strong>Date:</strong>{' '}
            {record.storm_date
              ? moment.utc(record.storm_date).tz('America/New_York').format('MMMM Do, YYYY')
              : 'N/A'}
          </p>
          <p style={{ margin: '4px 0' }}>
            <strong>Severity:</strong> {record.storm_severity}
          </p>
          <p style={{ margin: '4px 0' }}>
            <strong>ID:</strong> {record.storm}
          </p>
        </div>
      ),
    },
    {
      title: 'Message Batch ID',
      dataIndex: 'batchID',
      key: 'batchID',
      width: 85,
    },
    {
      title: 'Custom Message',
      dataIndex: 'message',
      key: 'message',
      width: 400,
      render: (text) => (
        <div
          style={{
            backgroundColor: '#f5f5f5',
            borderRadius: '4px',
            padding: '8px',
            whiteSpace: 'pre-wrap', // Allows text to wrap onto multiple lines
            wordWrap: 'break-word', // Breaks long words to prevent overflow
            minHeight: '40px', // Ensures a minimum height for better readability
          }}
        >
          {text}
        </div>
      ),
    },
    {
      title: 'Date Created',
      dataIndex: 'date_created',
      key: 'date_created',
      width: 150,
      sorter: (a, b) => new Date(a.date_created) - new Date(b.date_created),
      render: text =>
        text
          ? moment.utc(text).tz('America/New_York').format('MMMM Do YYYY, h:mm a')
          : 'N/A',
    },
    {
      title: 'Confirmation',
      dataIndex: 'status',
      key: 'status',
      width: 80,
      sorter: (a, b) => {
        const statusOrder = {
          Confirmed: 1,
          Unread: 2,
          Denied: 3,
        };
        return statusOrder[a.status] - statusOrder[b.status];
      },
      render: (status, record) => (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
            width: '100%',
            height: '50px',
          }}
        >
          {status === 'Confirmed' && (
            <div
              style={{
                backgroundColor: '#E5F2EC',
                height: '30px',
                width: '30px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                border: '1px solid #034832',
                borderRadius: '4px',
                color: '#034832',
              }}
            >
              <IconCheck size={18} stroke={2} />
            </div>
          )}
          {status === 'Unread' && (
            <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
              <span>Unread</span>
            </div>
          )}
          {status === 'Denied' && (
            <div
              style={{
                backgroundColor: '#FDEAEA',
                height: '30px',
                width: '30px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                border: '1px solid #FF4D4E',
                borderRadius: '4px',
                color: '#FF4D4E',
              }}
            >
              <IconX size={18} stroke={2} />
            </div>
          )}
        </div>
      ),
    },
    {
      title: 'Actions',
      dataIndex: 'status',
      key: 'status',
      width: 100,
      render: (status, record) => (
        <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column',
          width: '100%',
          height: '50px',
        }}
      >
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '2px' }}>
          {status === 'Unread' && (
            <Button
              type="primary"
              onClick={() => handleResend(record.message_id)}
              style={{ backgroundColor: '#c3c4c2', borderColor: '#c3c4c2', color: 'white', width: '80px' }}
            >
              Resend
            </Button>
          )}
  
          <Popconfirm
            title="Are you sure you want to confirm this message?"
            onConfirm={() => handleConfirm(record.message_id)}
            okText="Yes"
            cancelText="No"
          >
            <Button
              type="primary"
              style={{ backgroundColor: '#034832', borderColor: '#034832', color: 'white', width: '80px' }}
            >
              Confirm
            </Button>
          </Popconfirm>
  
          <Popconfirm
            title="Are you sure you want to deny this message?"
            onConfirm={() => handleDeny(record.message_id)}
            okText="Yes"
            cancelText="No"
          >
            <Button
              type="primary"
              style={{ backgroundColor: '#FF4D4E', borderColor: '#FF4D4E', color: 'white', width: '80px' }}
            >
              Deny
            </Button>
          </Popconfirm>
        </div>
      </div>
      ),
    }
  ];

  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'end' }}>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          width: '100%',
        }}
      >
        <div style={{ marginLeft: 30 }}>
          <h2>Messages</h2>
        </div>
        <div style={{ marginRight: 30 }}>
          {/* Filters */}
          <div
            style={{
              display: 'flex',
              gap: '10px',
              flexWrap: 'wrap',
              marginBottom: 20,
              justifyContent: 'flex-end',
            }}
          >
            {/* Region Dropdown */}
            <Select
              placeholder="Filter by Region"
              style={{ width: 200 }}
              value={regionFilter}
              onChange={value => setRegionFilter(value)}
            >
              <Option value="all">All Regions</Option>
              {regions.map(region => (
                <Option key={region} value={region}>
                  {region}
                </Option>
              ))}
            </Select>

            {/* Storm ID Dropdown with Search */}
            <Select
              showSearch
              placeholder="Filter by Storm ID"
              style={{ width: 400 }}
              value={stormIdFilter}
              onChange={value => setStormIdFilter(value)}
              filterOption={(input, option) =>
                option.children.toLowerCase().includes(input.toLowerCase())
              }
            >
              <Option value="all">All Storms</Option>
              {stormOptions.map(storm => (
                <Option key={storm.id} value={storm.id}>
                  {`Type: ${storm.type} - ${storm.date ? moment.utc(storm.date).tz('America/New_York').format('MMMM Do, YYYY') : 'N/A'} - ID: ${storm.id}`}
                </Option>
              ))}
            </Select>

            {/* Staff Search */}
            <Input.Search
              placeholder="Search by Staff"
              value={staffFilter}
              onChange={e => setStaffFilter(e.target.value)}
              style={{ width: 200 }}
            />

            {/* Confirmation Status Dropdown */}
            <Select
              placeholder="Filter by Status"
              style={{ width: 200 }}
              value={confirmationStatusFilter}
              onChange={value => setConfirmationStatusFilter(value)}
            >
              <Option value="all">All Statuses</Option>
              {statuses.map(status => (
                <Option key={status} value={status}>
                  {status}
                </Option>
              ))}
            </Select>
          </div>
        </div>
      </div>
      <div style={{ display:'flex', width:'100%', justifyContent:'space-between'}}>
        <div>
          <Button
            type="primary"
            icon = {<DownloadOutlined />}
            onClick={handleGenerateReport}
            style={{ backgroundColor: '#034832', borderColor: '#034832', marginLeft:30 }}
          >
            Generate Regional Warning Report
          </Button>
          <Button
            onClick={handleEmailTMWarnings}
            icon = {<MailOutlined />}
            type="primary"
            style={{ backgroundColor: '#cdaf5e', borderColor: '#cdaf5e', marginLeft: 8 }}
          >
            Email Territory Managers
          </Button>
        </div>
        <div> 
          <MessageStatusCounter counts={{
            unread: messages.filter(m =>
              (!m.status || m.status === 'Unread') &&
              (stormIdFilter === 'all' || m.storm === stormIdFilter) &&
              (regionFilter === 'all' || m.region === regionFilter)
            ).length,
            confirmed: messages.filter(m =>
              m.status === 'Confirmed' &&
              (stormIdFilter === 'all' || m.storm === stormIdFilter) &&
              (regionFilter === 'all' || m.region === regionFilter)
            ).length,
            denied: messages.filter(m =>
              m.status === 'Denied' &&
              (stormIdFilter === 'all' || m.storm === stormIdFilter) &&
              (regionFilter === 'all' || m.region === regionFilter)
            ).length
          }} />
        </div>
      </div>
      <Box style={{ padding: '10px 30px 30px 30px' }}>
        <Table
          dataSource={filteredMessages}
          columns={columns}
          rowKey="id"
          pagination={false}
          scroll={{ y: 640 }}
        />
      </Box>
    </div>
  );
};

export default MessageListView;
