// src/pages/ProfilePage.js
import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import {
  Typography, Box, Snackbar, Alert,
} from '@mui/material';

import api from '../authentication/axiosInstance';
import FormTextField from '../components/FormTextField';
import InviteTab from '../components/Profile/InviteTab';
import TeamTab from '../components/Profile/TeamTab';
import styles from './ProfilePage.module.css';


const ProfilePage = () => {
  const [activeTab, setActiveTab] = useState('profile');
  const [profile, setProfile] = useState({
    email: '',
    first_name: '',
    last_name: '',
    phone_number: '',
    role: 'user',
    date_joined: '',
  });
  const [clients, setClients] = useState([]);
  const [teamMembers, setTeamMembers] = useState([]);
  const [notificationOpen, setNotificationOpen] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState('');
  const [notificationSeverity, setNotificationSeverity] = useState('success');
  const [refresh, setRefresh] = useState(null);

  useEffect(() => {
    const fetchProfile = async () => {
      try {
        const { data: profileData } = await api.get('/users/profile/');
        setProfile(profileData);
      } catch (error) {
        console.error('Error fetching profile:', error);
      }
    };

    const fetchClients = async () => {
      try {
        const { data: clientList } = await api.get('/users/clients/');
        setClients(clientList);
        console.log('clients: ', clientList);
      } catch (error) {
        console.error('Error fetching clients:', error);
      }
    };

    const fetchTeamMembers = async () => {
      try {
        const { data: users } = await api.get('/users/team/');
        const sortedUsers = users.sort((a, b) => {
          if (a.last_name < b.last_name) return -1;
          if (a.last_name > b.last_name) return 1;
          if (a.first_name < b.first_name) return -1;
          if (a.first_name > b.first_name) return 1;
          return 0;
        });
        console.log('Team members: ', sortedUsers);
        setTeamMembers(sortedUsers);
      } catch (error) {
        console.error('Error fetching team members:', error);
      }
    };

    fetchProfile();
    fetchClients();
    fetchTeamMembers();
    setRefresh(false);
  }, [refresh]);

  useEffect(() => {
    const fetchClients = async () => {
      try {
        const { data: clientList } = await api.get('/clients/');
        setClients(clientList);
      } catch (error) {
        console.error('Error fetching clients:', error);
      }
    };

    fetchClients();
  }, []);

  const createNotification = (message, severity) => {
    setNotificationMessage(message);
    setNotificationSeverity(severity);
    setNotificationOpen(true);
  };

  const handleNotificationClose = () => {
    setNotificationOpen(false);
  };

  const handleRoleChange = async (userId, newRole, userRole) => {
    if (userRole === 'admin' && profile.role !== 'admin') {
      setNotificationMessage('Only an admin can change the role of another admin.');
      setNotificationSeverity('error');
      setNotificationOpen(true);
      return;
    }
  
    if (profile.email === teamMembers.find(member => member.id === userId).email) {
      setNotificationMessage('You cannot change your own role.');
      setNotificationSeverity('error');
      setNotificationOpen(true);
      return;
    }
  
    const confirmed = window.confirm('Are you sure you want to change this user\'s role?');
    if (confirmed) {
      try {
        await api.patch(`/users/update-role/${userId}/`, { role: newRole });
        const updatedTeamMembers = teamMembers.map(member =>
          member.id === userId ? { ...member, role: newRole } : member
        );
        setTeamMembers(updatedTeamMembers);
        setNotificationMessage('User role updated successfully');
        setNotificationSeverity('success');
      } catch (error) {
        setNotificationMessage(error.response?.data?.message || 'Failed to update user role');
        setNotificationSeverity('error');
      } finally {
        setNotificationOpen(true);
      }
    }
  };

  const handleDeleteUser = async (userId, userRole) => {
    if (userRole === 'admin' && profile.role !== 'admin') {
      setNotificationMessage('Only an admin can delete another admin.');
      setNotificationSeverity('error');
      setNotificationOpen(true);
      return;
    }

    if (profile.email === teamMembers.find(member => member.id === userId).email) {
      setNotificationMessage('You cannot delete yourself.');
      setNotificationSeverity('error');
      setNotificationOpen(true);
      return;
    }

    const confirmed = window.confirm('Are you sure you want to delete this user?');
    if (confirmed) {
      try {
        await api.delete(`/users/delete-user/${userId}/`);
        const updatedTeamMembers = teamMembers.filter(member => member.id !== userId);
        setTeamMembers(updatedTeamMembers);
        setNotificationMessage('User deleted successfully');
        setNotificationSeverity('success');
      } catch (error) {
        setNotificationMessage(error.response?.data?.message || 'Failed to delete user');
        setNotificationSeverity('error');
      } finally {
        setNotificationOpen(true);
      }
    }
  };

  const handleProfileUpdate = async (event) => {
    event.preventDefault();
    const confirmed = window.confirm('Are you sure you want to update your profile?');
    if (confirmed) {
      try {
        await api.patch('/users/profile/', {
          first_name: profile.first_name,
          last_name: profile.last_name,
          phone_number: profile.phone_number
        });
        setNotificationMessage('Profile updated successfully');
        setNotificationSeverity('success');
      } catch (error) {
        setNotificationMessage(error.response?.data?.message || 'Failed to update profile');
        setNotificationSeverity('error');
      } finally {
        setNotificationOpen(true);
      }
    }
  };

  const handleRequestAccessChange = async (userId, accessType, enabled) => {
    if (profile.role !== 'admin') {
      setNotificationMessage('Only admins can modify request access');
      setNotificationSeverity('error');
      setNotificationOpen(true);
      return;
    }

    try {
      console.log('Sending request:', { userId, accessType, enabled });
      
      const response = await api.patch(`/users/${userId}/request-access/`, {
        access_type: accessType,
        enabled: Boolean(enabled)
      });

      console.log('Response:', response.data);

      if (response.data) {
        const updatedTeamMembers = teamMembers.map(member =>
          member.id === userId
            ? {
                ...member,
                has_regular_request_access:
                  accessType === 'regular'
                    ? enabled
                    : member.has_regular_request_access,
                has_snowedge_access:
                  accessType === 'snowedge'
                    ? enabled
                    : member.has_snowedge_access,
              }
            : member
        );
        setTeamMembers(updatedTeamMembers);
        setNotificationMessage('Request access updated successfully');
        setNotificationSeverity('success');
      }
    } catch (error) {
      console.error('Error updating request access:', error);
      console.error('Error response:', error.response?.data);
      setNotificationMessage(error.response?.data?.error || 'Failed to update request access');
      setNotificationSeverity('error');
    }
    setNotificationOpen(true);
  };

  return (
    <Box className={styles.main}>
      <motion.div 
        className={styles.navBar}
        initial={{ opacity: 0, translateY: 20 }}
        animate={{ opacity: 1, translateY: 0 }}
        transition={{
          type: "spring",
          stiffness: 150,
          damping: 30,
          delay: 0.2
        }}
      >
        <Box className={styles.tabs}>
          <Box className={activeTab === "profile" ? styles.tabActive : styles.tab} onClick={() => setActiveTab('profile')}>
            Profile
          </Box>
          {profile.role === 'admin' && (
            <>
              <Box className={activeTab === "invite" ? styles.tabActive : styles.tab} onClick={() => setActiveTab('invite')}>
                Invite User
              </Box>
              <Box className={activeTab === "team" ? styles.tabActive : styles.tab} onClick={() => setActiveTab('team')}>
                Team
              </Box>
            </>
          )}
          {profile.role === 'standard' && (
            <Box className={activeTab === "team" ? styles.tabActive : styles.tab} onClick={() => setActiveTab('team')}>
              Team
            </Box>
          )}
        </Box>
      </motion.div>

      <Box className={styles.tabContent}>
        {activeTab === "profile" && (
          <motion.div 
            className={styles.tabContentInner} 
            initial={{ opacity: 0, translateY: 20 }}
            animate={{ opacity: 1, translateY: 0 }}
            transition={{
              type: "spring",
              stiffness: 150,
              damping: 30,
              delay: 0.2
            }}>
            <Box className={styles.titleContainer}>
              <Typography className={styles.title}>
                Profile
              </Typography>
              <Typography className={styles.subtitle}>
                Manage your profile and account information.
              </Typography>
            </Box>
            <Box className={styles.lineBreak} />
            <Box className={styles.sectionContainer}>
              <Box className={styles.sectionContainerLeft}>
                <Typography className={styles.sectionTitle}>
                  Profile Picture
                </Typography>
                <Typography className={styles.sectionSubtitle}>
                  Update your profile picture.
                </Typography>
              </Box>
              <Box className={styles.sectionContainerRight}>
                <Box className={styles.pfpUploadContainer}>
                  <Box className={styles.buttonContainer}>
                    <Box className={styles.uploadButton}>
                      Upload New Picture
                    </Box>
                    <Box className={styles.removeButtonTwo}>
                      Clear
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
            <Box className={styles.lineBreak} />
            <Box className={styles.sectionContainer}>
              <Box className={styles.sectionContainerLeft}>
                <Typography className={styles.sectionTitle}>
                  Name
                </Typography>
                <Typography className={styles.sectionSubtitle}>
                  What should we call you?
                </Typography>
              </Box>
              <Box className={styles.sectionContainerRight}>
                <FormTextField
                  labelName=""
                  idName="firstName"
                  value={profile.first_name}
                  onChange={(e) => setProfile((prev) => ({ ...prev, first_name: e.target.value }))}
                  placeholder="First Name"
                />
                <FormTextField
                  labelName=""
                  idName="lastName"
                  value={profile.last_name}
                  onChange={(e) => setProfile((prev) => ({ ...prev, last_name: e.target.value }))}
                  placeholder="Last Name"
                />
              </Box>
            </Box>
            <Box className={styles.lineBreak} />
            <Box className={styles.sectionContainer}>
              <Box className={styles.sectionContainerLeft}>
                <Typography className={styles.sectionTitle}>
                  Contact Information
                </Typography>
                <Typography className={styles.sectionSubtitle}>
                  How can we get a hold of you?
                </Typography>
              </Box>
              <Box className={styles.sectionContainerRight}>
                <FormTextField
                  labelName=""
                  idName="email"
                  value={profile.email}
                  onChange={(e) => setProfile((prev) => ({ ...prev, email: e.target.value }))}
                  placeholder="Email Address"
                  disabled={true}
                />
                <FormTextField
                  labelName=""
                  idName="phoneNumber"
                  value={profile.phone_number}
                  onChange={(e) => setProfile((prev) => ({ ...prev, phone_number: e.target.value }))}
                  placeholder="Phone Number"
                  type="phone"
                />
              </Box>
            </Box>
            <Box className={styles.lineBreak} />
            <Box className={styles.sectionContainer}>
              <Box className={styles.sectionContainerLeft}>
                <Typography className={styles.sectionTitle}>
                  Permissions
                </Typography>
                <Typography className={styles.sectionSubtitle}>
                  What can you access?
                </Typography>
              </Box>
              <Box className={styles.sectionContainerRight}>
                <FormTextField
                  labelName=""
                  idName="role"
                  value={profile.role}
                  select
                  disabled
                  options={['standard', 'admin', 'client']}
                  onChange={(e) => setProfile((prev) => ({ ...prev, role: e.target.value }))}
                  placeholder="Standard"
                />
              </Box>
            </Box>
            <Box className={styles.lineBreak} />
            <Box className={styles.buttonContainer}>
              <Box className={styles.cancelButton} onClick={() => setRefresh(true)}>
                Reset
              </Box>
              <Box className={styles.submitButton} onClick={handleProfileUpdate}>
                Update Profile
              </Box>
            </Box>
          </motion.div>
        )}
        {activeTab === "invite" && (
          <InviteTab
            clients={clients}
            createNotification={createNotification}
          />
        )}
        {activeTab === "team" && (
          <TeamTab
            teamMembers={teamMembers}
            clients={clients}
            profile={profile}
            handleRoleChange={handleRoleChange}
            handleDeleteUser={handleDeleteUser}
            handleRequestAccessChange={handleRequestAccessChange}
            createNotification={createNotification}
          />
        )}
      </Box>

      <Snackbar
        open={notificationOpen}
        autoHideDuration={6000}
        onClose={handleNotificationClose}
      >
        <Alert onClose={handleNotificationClose} severity={notificationSeverity}>
          {notificationMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default ProfilePage; 