import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Button, Container, Typography, Box } from '@mui/material';
import companyLogo from '../assets/company-logo.png';
import DatabasesDropDown from '../components/DatabasesDropDown';
import AllocateDropDown from '../components/AllocateDropDown';
import styles from './HomePage.module.css';
import { AnimatePresence, motion } from 'framer-motion';
import { useNavigate } from 'react-router-dom';
import { 
  IconArrowNarrowRight, 
  IconTrendingDown, 
  IconPackageExport, 
  IconArrowRight, 
  IconFileTime, 
  IconChartAreaLine, 
  IconCalendarQuestion, 
  IconDatabase, 
  IconCar, 
  IconTractor, 
  IconUsers, 
  IconRepeat, 
  IconTrendingUp, 
  IconHistory
} from '@tabler/icons-react';
import api from '../authentication/axiosInstance';
import bgImage from '../assets/dashboard-page-4.jpg';

const HomePage = () => {
  const navigate = useNavigate();
  const [allocateExpanded, setAllocateExpanded] = useState(false);
  const [dispatchExpanded, setDispatchExpanded] = useState(false);
  const [databasesExpanded, setDatabasesExpanded] = useState(false);
  const [user, setUser] = useState({
    firstName: '',
    lastName: '',
  });

  function timeOfDayGreeting() {
    const currentTime = new Date().getHours();
    let greeting;
    if (currentTime < 12) {
      greeting = "Good Morning";
    } else if (currentTime < 18) {
      greeting = "Good Afternoon";
    } else {
      greeting = "Good Evening";
    }
    return greeting;
  }

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const { data } = await api.get('/users/profile/');
        setUser({
          firstName: data.first_name,
          lastName: data.last_name,
        });
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    };

    fetchUser();
  }, []);

  return (
    <Box 
      className={styles.main} 
      style={{ 
        backgroundImage: `linear-gradient(rgba(255,255,255,0.5), rgba(255,255,255,0.5)), url(${bgImage})`, 
        backgroundPosition: "center", 
        backgroundSize: "cover"  
      }}
    >
      <Box className={styles.topRow}>
        <motion.div
          className={styles.left}
          initial={{ opacity: 0, translateY: 550 }}
          animate={{ opacity: 1, translateY: 0 }}
          transition={{
            type: "spring",
            stiffness: 150,
            damping: 30,
          }}
        >
          <Typography className={styles.title}>
            {timeOfDayGreeting()}<br/>
            <span style={{ fontWeight: "700" }}>
              {user.firstName !== '' ? user.firstName : 'Pierre-Marc'}
            </span>
          </Typography>
          <Typography className={styles.subtitle}>
            Here's what's happening!
          </Typography>
        </motion.div>
        <AnimatePresence>
          <Box className={styles.right}>
            <motion.div
              className={styles.metricCard}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{
                delay: 1.0,
                duration: 0.3,
              }}
            >
              <Typography className={styles.metricCardTitle}>Total Parking Lot&apos;s SQFT</Typography>
              <Box className={styles.metricCardValueContainer}>
                <Typography className={styles.metricCardValue}>324,200</Typography>
                <Box className={styles.positiveChange}>
                  <IconTrendingUp size={14}/>25%
                </Box>
              </Box>
              <Typography className={styles.metricCardSubtitle}>
                vs last month: <b>314,950</b>
              </Typography>
            </motion.div>
            <motion.div
              className={styles.metricCard}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{
                delay: 1.2,
                duration: 0.3,
              }}
            >
              <Typography className={styles.metricCardTitle}>Total Wheel Loaders</Typography>
              <Box className={styles.metricCardValueContainer}>
                <Typography className={styles.metricCardValue}>100</Typography>
                <Box className={styles.neutralChange}>0%</Box>
              </Box>
              <Typography className={styles.metricCardSubtitle}>
                vs last month: <b>100</b>
              </Typography>
            </motion.div>
            <motion.div
              className={styles.metricCard}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{
                delay: 1.4,
                duration: 0.3,
              }}
            >
              <Typography className={styles.metricCardTitle}>Total Assigned Loader Capacity</Typography>
              <Box className={styles.metricCardValueContainer}>
                <Typography className={styles.metricCardValue}>89%</Typography>
                <Box className={styles.negativeChange}>
                  <IconTrendingDown size={14}/>3%
                </Box>
              </Box>
              <Typography className={styles.metricCardSubtitle}>
                vs last month: <b>92%</b>
              </Typography>
            </motion.div>
          </Box>
        </AnimatePresence>
      </Box>
      <motion.div
        className={styles.actionsContainer}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{
          delay: 1.5,
          duration: 0.5,
        }}
      >
        {/* Allocate Section */}
        <Box 
          className={allocateExpanded ? styles.actionCardActive : styles.actionCard} 
          onClick={() => setAllocateExpanded(!allocateExpanded)}
        >
          <Box className={styles.actionCardTopRow}>
            <Box className={allocateExpanded ? styles.actionCardTopRowLeftActive : styles.actionCardTopRowLeft}>
              <IconPackageExport size={24}/>
              <Typography className={allocateExpanded ? styles.actionCardTitleActive : styles.actionCardTitle}>
                Allocate
              </Typography>
            </Box>
            <Box className={styles.actionCardTopRowRight}>
              <IconArrowRight />
            </Box>
          </Box>
          <Box className={allocateExpanded ? styles.actionCardBodyActive : styles.actionCardBody}>
            Assign your resources to a specific task.
          </Box>
        </Box>
        {allocateExpanded && (
          <>
          <motion.div
            className={styles.actionCardExpanded}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{
              duration: 0,
            }}
          >
            <motion.div
              className={styles.actionCardExpandedItem}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{
                delay: 0.1,
                duration: 0.3,
              }}
              onClick={() => navigate("/allocation")}
            >
              <Box className={styles.actionCardExpandedItemLeft}>
                <IconCar size={16}/>
                <Typography className={styles.actionCardExpandedTitle}>Loader</Typography>
              </Box>
              <Box className={styles.actionCardExpandedItemRight}>
                <IconArrowRight size={16}/>
              </Box>
            </motion.div>
            <motion.div
              className={styles.actionCardExpandedItem}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{
                delay: 0.2,
                duration: 0.3,
              }}
              onClick={() => navigate("/WalkwayCrew")}
            >
              <Box className={styles.actionCardExpandedItemLeft}>
                <IconUsers size={16}/>
                <Typography className={styles.actionCardExpandedTitle}>Walkway Crew</Typography>
              </Box>
              <Box className={styles.actionCardExpandedItemRight}>
                <IconArrowRight size={16}/>
              </Box>
            </motion.div>
            <motion.div
              className={styles.actionCardExpandedItem}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{
                delay: 0.3,
                duration: 0.3,
              }}
              onClick={() => navigate("/SaltingRoute")}
            >
              <Box className={styles.actionCardExpandedItemLeft}>
                <IconTrendingUp size={16}/>
                <Typography className={styles.actionCardExpandedTitle}>Salting Route</Typography>
              </Box>
              <Box className={styles.actionCardExpandedItemRight}>
                <IconArrowRight size={16}/>
              </Box>
            </motion.div>
          </motion.div>
          </>
        )}

        {/* Dispatch Section */}
        <Box 
          className={dispatchExpanded ? styles.actionCardActive : styles.actionCard} 
          onClick={() => setDispatchExpanded(!dispatchExpanded)}
        >
          <Box className={styles.actionCardTopRow}>
            <Box className={dispatchExpanded ? styles.actionCardTopRowLeftActive : styles.actionCardTopRowLeft}>
              <IconPackageExport size={24}/>
              <Typography className={dispatchExpanded ? styles.actionCardTitleActive : styles.actionCardTitle}>
                Dispatch
              </Typography>
            </Box>
            <Box className={styles.actionCardTopRowRight}>
              <IconArrowRight />
            </Box>
          </Box>
          <Box className={dispatchExpanded ? styles.actionCardBodyActive : styles.actionCardBody}>
            Dispatch your resources
          </Box>
        </Box>
        {dispatchExpanded && (
          <>
          <motion.div
            className={styles.actionCardExpanded}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{
              duration: 0,
            }}
          >
            <motion.div
              className={styles.actionCardExpandedItem}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{
                delay: 0.1,
                duration: 0.3,
              }}
              onClick={() => navigate("/walkwayCrewDispatch")}
            >
              <Box className={styles.actionCardExpandedItemLeft}>
                <IconUsers size={16}/>
                <Typography className={styles.actionCardExpandedTitle}>Walkway Crews</Typography>
              </Box>
              <Box className={styles.actionCardExpandedItemRight}>
                <IconArrowRight size={16}/>
              </Box>
            </motion.div>
            <motion.div
              className={styles.actionCardExpandedItem}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{
                delay: 0.2,
                duration: 0.3,
              }}
              onClick={() => navigate("/saltingRouteDispatch")}
            >
              <Box className={styles.actionCardExpandedItemLeft}>
                <IconTractor size={16}/>
                <Typography className={styles.actionCardExpandedTitle}>Salting Routes</Typography>
              </Box>
              <Box className={styles.actionCardExpandedItemRight}>
                <IconArrowRight size={16}/>
              </Box>
            </motion.div>
            <motion.div
              className={styles.actionCardExpandedItem}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{
                delay: 0.3,
                duration: 0.3,
              }}
              onClick={() => navigate("/loaderDispatch")}
            >
              <Box className={styles.actionCardExpandedItemLeft}>
                <IconCar size={16}/>
                <Typography className={styles.actionCardExpandedTitle}>Loaders</Typography>
              </Box>
              <Box className={styles.actionCardExpandedItemRight}>
                <IconArrowRight size={16}/>
              </Box>
            </motion.div>
          </motion.div>
            
          <motion.div
              className={styles.actionCardExpandedLessItems}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{
                duration: 0,
              }}
            >
              <motion.div
                className={styles.actionCardExpandedItem}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{
                  delay: 0.4,
                  duration: 0.3,
                }}
                onClick={() => navigate("/dispatchHistory")}
              >
                <Box className={styles.actionCardExpandedItemLeft}>
                  <IconHistory size={16}/>
                  <Typography className={styles.actionCardExpandedTitle}>Dispatch History</Typography>
                </Box>
                <Box className={styles.actionCardExpandedItemRight}>
                  <IconArrowRight size={16}/>
                </Box>
              </motion.div>
              <motion.div
                className={styles.actionCardExpandedItem}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{
                  delay: 0.5,
                  duration: 0.3,
                }}
                onClick={() => navigate("/messages")}
              >
                <Box className={styles.actionCardExpandedItemLeft}>
                  <IconHistory size={16}/>
                  <Typography className={styles.actionCardExpandedTitle}>Messages</Typography>
                </Box>
                <Box className={styles.actionCardExpandedItemRight}>
                  <IconArrowRight size={16}/>
                </Box>
              </motion.div>
            </motion.div>
          </>
        )}

        {/* Additional Action Cards */}
        <Box className={styles.actionCard} onClick={() => navigate("/estimatepage")}>
          <Box className={styles.actionCardTopRow}>
            <Box className={styles.actionCardTopRowLeft}>
              <IconFileTime size={24}/>
              <Typography className={styles.actionCardTitle}>Estimates</Typography>
            </Box>
            <Box className={styles.actionCardTopRowRight}>
              <IconArrowRight />
            </Box>
          </Box>
          <Box className={styles.actionCardBody}>
            Create and manage project estimates.
          </Box>
        </Box>
        <Box className={styles.actionCard} onClick={() => navigate("/projection")}>
          <Box className={styles.actionCardTopRow}>
            <Box className={styles.actionCardTopRowLeft}>
              <IconChartAreaLine size={24}/>
              <Typography className={styles.actionCardTitle}>Projections</Typography>
            </Box>
            <Box className={styles.actionCardTopRowRight}>
              <IconArrowRight />
            </Box>
          </Box>
          <Box className={styles.actionCardBody}>
            View and analyze future projections.
          </Box>
        </Box>
        <Box className={styles.actionCard} onClick={() => navigate("/requests")}>
          <Box className={styles.actionCardTopRow}>
            <Box className={styles.actionCardTopRowLeft}>
              <IconRepeat size={24}/>
              <Typography className={styles.actionCardTitle}>Requests</Typography>
            </Box>
            <Box className={styles.actionCardTopRowRight}>
              <IconArrowRight />
            </Box>
          </Box>
          <Box className={styles.actionCardBody}>
            View client service requests and take action.
          </Box>
        </Box>
        {/* Databases Section */}
        <Box className={databasesExpanded ? styles.actionCardActive : styles.actionCard} onClick={() => setDatabasesExpanded(!databasesExpanded)}>
          <Box className={styles.actionCardTopRow}>
            <Box className={databasesExpanded ? styles.actionCardTopRowLeftActive : styles.actionCardTopRowLeft}>
              <IconDatabase size={24}/>
              <Typography className={databasesExpanded ? styles.actionCardTitleActive : styles.actionCardTitle}>Databases</Typography>
            </Box>
            <Box className={styles.actionCardTopRowRight}>
              <IconArrowRight />
            </Box>
          </Box>
          <Box className={databasesExpanded ? styles.actionCardBodyActive : styles.actionCardBody}>
            See all the databases available to you.
          </Box>
        </Box>
        {databasesExpanded && (
          <>
             <motion.div
              className={styles.actionCardExpanded}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{
                duration: 0,
              }}
            >
              <motion.div
                className={styles.actionCardExpandedItem}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{
                  delay: 0.1,
                  duration: 0.3,
                }}
                onClick={() => navigate("/sites")}
              >
                <Box className={styles.actionCardExpandedItemLeft}>
                  <IconDatabase size={16}/>
                  <Typography className={styles.actionCardExpandedTitle}>Sites</Typography>
                </Box>
                <Box className={styles.actionCardExpandedItemRight}>
                  <IconArrowRight size={16}/>
                </Box>
              </motion.div>
              <motion.div
                className={styles.actionCardExpandedItem}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{
                  delay: 0.2,
                  duration: 0.3,
                }}
                onClick={() => navigate("/vehicles")}
              >
                <Box className={styles.actionCardExpandedItemLeft}>
                  <IconDatabase size={16}/>
                  <Typography className={styles.actionCardExpandedTitle}>Vehicles</Typography>
                </Box>
                <Box className={styles.actionCardExpandedItemRight}>
                  <IconArrowRight size={16}/>
                </Box>
              </motion.div>
              <motion.div
                className={styles.actionCardExpandedItem}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{
                  delay: 0.3,
                  duration: 0.3,
                }}
                onClick={() => navigate("/staff")}
              >
                <Box className={styles.actionCardExpandedItemLeft}>
                  <IconDatabase size={16}/>
                  <Typography className={styles.actionCardExpandedTitle}>Staff</Typography>
                </Box>
                <Box className={styles.actionCardExpandedItemRight}>
                  <IconArrowRight size={16}/>
                </Box>
              </motion.div>
            </motion.div>
            <motion.div
              className={styles.actionCardExpandedLessItems}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{
                duration: 0,
              }}
            >
              <motion.div
                className={styles.actionCardExpandedItem}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{
                  delay: 0.4,
                  duration: 0.3,
                }}
                onClick={() => navigate("/subtypes")}
              >
                <Box className={styles.actionCardExpandedItemLeft}>
                  <IconDatabase size={16}/>
                  <Typography className={styles.actionCardExpandedTitle}>Vehicle Subtypes</Typography>
                </Box>
                <Box className={styles.actionCardExpandedItemRight}>
                  <IconArrowRight size={16}/>
                </Box>
              </motion.div>
              <motion.div
                className={styles.actionCardExpandedItem}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{
                  delay: 0.5,
                  duration: 0.3,
                }}
                onClick={() => navigate("/clients")}
              >
                <Box className={styles.actionCardExpandedItemLeft}>
                  <IconDatabase size={16}/>
                  <Typography className={styles.actionCardExpandedTitle}>Clients</Typography>
                </Box>
                <Box className={styles.actionCardExpandedItemRight}>
                  <IconArrowRight size={16}/>
                </Box>
              </motion.div>
              <motion.div
                className={styles.actionCardExpandedItem}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{
                  delay: 0.5,
                  duration: 0.3,
                }}
                onClick={() => navigate("/equipments")}
              >
                <Box className={styles.actionCardExpandedItemLeft}>
                  <IconDatabase size={16}/>
                  <Typography className={styles.actionCardExpandedTitle}>Equipments</Typography>
                </Box>
                <Box className={styles.actionCardExpandedItemRight}>
                  <IconArrowRight size={16}/>
                </Box>
              </motion.div>
              <motion.div
                className={styles.actionCardExpandedItem}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{
                  delay: 0.5,
                  duration: 0.3,
                }}
                onClick={() => navigate("/storms")}
              >
                <Box className={styles.actionCardExpandedItemLeft}>
                  <IconDatabase size={16}/>
                  <Typography className={styles.actionCardExpandedTitle}>Storms</Typography>
                </Box>
                <Box className={styles.actionCardExpandedItemRight}>
                  <IconArrowRight size={16}/>
                </Box>
              </motion.div>
            </motion.div>
          </>
        )}
      </motion.div>
    </Box>
  );
}

export default HomePage;
