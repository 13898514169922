import React, { useState, useEffect, useRef } from 'react';
import { MapContainer, TileLayer, Marker, Tooltip, useMap } from 'react-leaflet';
import { Button, Input, message, DatePicker, Form, ConfigProvider, Tooltip as AntdTooltip, Modal } from 'antd';
import { Box, Slider } from '@mui/material';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import api from "../authentication/axiosInstance";
import styled from 'styled-components';
import RedMarker from '../assets/RedMarker.png';
import BlueMarker from '../assets/BlueMarker.png';
import MarkerShadow from '../assets/MarkerShadow.png';
import { StandaloneSearchBox, } from "@react-google-maps/api";
import { IconBuildingFactory2, IconX } from '@tabler/icons-react';
import { AnimatePresence, motion } from 'framer-motion';
import dayjs from 'dayjs';


const customBlueMarkerIcon = L.icon({
  iconUrl: BlueMarker,
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  shadowUrl: MarkerShadow,
  shadowSize: [41, 41],
});

const customRedMarkerIcon = L.icon({
  iconUrl: RedMarker,
  iconSize: [25, 40],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  shadowUrl: MarkerShadow,
  shadowSize: [41, 41],
});

const LeftPanel = styled.div`
  width: 300px;
  min-width: 300px;
  padding: 10px;
  overflow-y: auto;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
`;

const FormSection = styled.div`
  flex: 1;
  padding: 10px;
  background-color: #ffffff;
  border-bottom: 1px solid #d9d9d9;
`;

const CalculationSection = styled.div`
  flex: 1;
  padding: 10px;
`;

const RightPanel = styled.div`
  position: relative;
  width: 100%;
  border-radius: 10px;
  overflow: hidden;
`;

function SetViewOnClick({ coords }) {
  const map = useMap();

  useEffect(() => {
    if (coords) {
      map.setView(coords, 13);
    }
  }, [coords, map]);
  return null;
}

const EstimatePage = () => {
  const [sites, setSites] = useState([]);
  const [estimates, setEstimates] = useState([]);
  const [selectedEstimate, setSelectedEstimate] = useState(null);
  const [isCreatingEstimate, setIsCreatingEstimate] = useState(false);
  const [isEditingEstimate, setIsEditingEstimate] = useState(false);
  const [form] = Form.useForm();
  const [mapPosition, setMapPosition] = useState(null);
  const [newEstimatePosition, setNewEstimatePosition] = useState(null);
  const [estimateLatLong, setEstimateLatLong] = useState([0, 0]);
  const [estimateDate, setEstimateDate] = useState(null);
  const [addressInput, setAddressInput] = useState('');
  const [estimateAddress, setEstimateAddress] = useState('');
  const [saltPrice, setSaltPrice] = useState(0);
  const [serviceLevel, setServiceLevel] = useState(1);
  const [estimateParameters, setEstimateParameters] = useState(null);
  const [validAddress, setValidAddress] = useState(false);

  const searchBoxRef = useRef(null);

  const fetchEstimates = async () => {
    try {
      const response = await api.get('/estimate/estimates/');
      setEstimates(response.data);
    } catch (error) {
      console.error('Failed to fetch estimates:', error);
      message.error('Failed to fetch estimates');
    }
  }

  useEffect(() => {
    fetchSites();
    fetchEstimates();
  }, []);

  const fetchSites = async () => {
    try {
      const response = await api.get('/sites/');
      setSites(response.data);
    } catch (error) {
      console.error('Failed to fetch sites:', error);
      message.error('Failed to fetch sites');
    }
  };

  const handleDateChange = (date) => {
    setEstimateDate(date); // store the dayjs object in state
  };

  const handleCreateEstimate = () => {
    setIsCreatingEstimate(true);
    setAddressInput("");
    setSelectedEstimate(null);
    form.resetFields();
  };

  const handleGenerateEstimate = () => {
    const values = form.getFieldsValue();
    setEstimateParameters({
      address: estimateAddress,
      date: estimateDate.format('YYYY-MM-DD'),
      latitude: estimateLatLong.lat,
      longitude: estimateLatLong.lng,
      lots_sqft: values.lots_sqft, 
      walkway_sqft: values.walkway_sqft, 
      service_level: values.service_level, 
      salt_price: values.salt_price
    });
  };

  useEffect(() => {
    if (estimateParameters) {
      const generateEstimate = async () => {
        if (!validAddress) {
          message.error('Invalid address. Please enter a valid address.');
          return;
        }
        try {
          await api.post('/estimate/estimates/generate_estimate/', estimateParameters)
          .then((response) => {
            message.success(response.data.message + ".")
          })
          setIsCreatingEstimate(false);
          form.resetFields();
          fetchEstimates();
          setSelectedEstimate(null);
        } catch (error) {
          message.error('Failed to generate estimate')
        }
      };
      
      generateEstimate();
    }
  }, [estimateParameters]);
  

  const handleDeleteEstimate = async (estimate) => {
    try {
      await api.delete(`/estimate/estimates/${estimate.id}/`);
      fetchEstimates();
      if (estimates && estimates.length > 0) {
        setMapPosition([estimates[0].latitude, estimates[0].longitude]);
      }
      message.success('Estimate deleted successfully');
      setSelectedEstimate(null);
    } catch (error) {
      console.error('Failed to delete estimate:', error);
      message.error('Failed to delete estimate');
    }
  };

  const handleEditEstimate = (estimate) => {
    setIsEditingEstimate(true);
    setSelectedEstimate(estimate);
    setIsCreatingEstimate(false);
    form.setFieldsValue({
      address: estimate.address,
      client: estimate.client,
      date: estimate.date ? dayjs(estimate.date) : null,
      lots_sqft: estimate.lots_sqft,
      walkway_sqft: estimate.walkway_sqft,
      service_level: estimate.service_level,
      salt_price: estimate.salt_price
    });
    setServiceLevel(estimate.service_level);
    setSaltPrice(estimate.salt_price);
    setMapPosition([estimate.latitude, estimate.longitude]);
  };

  const handleUpdateEstimate = async () => {
    const values = form.getFieldsValue();
    const updatedEstimate = {
      ...selectedEstimate,
      ...values,
    };

    try {
      await api.put(`/estimate/estimates/${selectedEstimate.id}/`, updatedEstimate);
      message.success('Estimate updated successfully');
      fetchEstimates();
      setIsEditingEstimate(false);
      setSelectedEstimate(null);
    } catch (error) {
      console.error('Failed to update estimate:', error);
      message.error('Failed to update estimate');
    }
  };

  const serviceLevels = [
    {
      value: 1,
      label: '1',
    },
    {
      value: 2,
      label: '2',
    },
    {
      value: 3,
      label: '3',
    },
    {
      value: 4,
      label: '4',
    },
    {
      value: 5,
      label: '5',
    },
  ];
  const saltPriceLevels = [
    {
      value: 0,
      label: '0',
    },
    {
      value: 25,
      label: '25',
    },
    {
      value: 50,
      label: '50',
    },
    {
      value: 75,
      label: '75',
    },
    {
      value: 100,
      label: '100',
    },
    {
      value: 125,
      label: '125',
    },
    {
      value: 150,
      label: '150',
    },
    {
      value: 175,
      label: '175',
    },
    {
      value: 200,
      label: '200',
    }
  ];

  
  useEffect(() => {
    if (selectedEstimate) {
      setMapPosition([selectedEstimate.latitude, selectedEstimate.longitude])
    }
  },[selectedEstimate])

  const onSearchBoxLoaded = (ref) => {
    searchBoxRef.current = ref;
  };

  const onPlacesChanged = () => {
    const places = searchBoxRef.current.getPlaces();

    if (places && places.length > 0) {
      const place = places[0];
      const lat = place.geometry.location.lat();
      const lng = place.geometry.location.lng();
      setValidAddress(true);
      setEstimateLatLong({lat: lat, lng: lng});
      setAddressInput(place.formatted_address);
      setEstimateAddress(place.formatted_address);
      setNewEstimatePosition({ lat, lng });
      setMapPosition([lat, lng]);
    }
  };

  useEffect(() => { //set valid address to false when the input is cleared
    if (addressInput === "") {
      setValidAddress(false);
      setEstimateAddress("");
      setNewEstimatePosition(null);
      setEstimateLatLong([0, 0]);
      setEstimateParameters(null);
    }

  }, [addressInput])

  return (
    <ConfigProvider theme={{ token: { colorLinkHover: "#034832", colorPrimary: "#034832" } }}>
      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'stretch', justifyContent: 'center', padding: '20px', height: 'calc(100vh - 130px)'}}>
          <LeftPanel>
          <span style={{textAlign: "center", fontFamily: "'Plus Jakarta Sans', sans-serif", fontWeight: '600', fontSize: '28px', marginBottom: '20px' }}>
                Estimates
              </span>
              <Button type="primary" onClick={handleCreateEstimate} style={{ marginBottom: '15px', width: "100%", boxShadow: 'none' }}>
              New Estimate
            </Button>
            <div style={{ width: '100%', display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start', flexDirection: 'column' }}>
              {estimates.map((estimate) => (
                <AntdTooltip key={estimate.id} title="View Estimate" placement="right">
                  <div 
                    onClick={() => {
                      setAddressInput("");
                      setSelectedEstimate(estimate);
                      setNewEstimatePosition(null);
                      setIsCreatingEstimate(false);
                      setIsEditingEstimate(false);
                      form.setFieldsValue({
                        address: estimate.address,
                        client: estimate.client,
                        date: estimate.date ? dayjs(estimate.date) : null,
                        lots_sqft: estimate.lots_sqft,
                        walkway_sqft: estimate.walkway_sqft,
                        service_level: estimate.service_level,
                        salt_price: estimate.salt_price
                      });
                    }}
                    style={{ 
                      display: 'flex', 
                      flexDirection: 'column', 
                      marginBottom: '10px', 
                      padding: '15px', 
                      borderRadius: '5px', 
                      backgroundColor: "#f0f0f0", 
                      width: 'calc(100% - 30px)', 
                      gap: '5px', 
                      cursor: 'pointer' 
                    }}
                  >
                    <span style={{ fontSize: '16px', fontWeight: '600' }}><IconBuildingFactory2 size={14} stroke={2} style={{ marginRight: '3px' }} />{estimate.address}</span>
                    <span style={{ fontSize: '14px' }}>Date: {estimate.date}</span>
                    <span style={{ fontSize: '14px' }}>Total Price: <b>${parseFloat(estimate.total_price).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</b></span>
                  </div>
                </AntdTooltip>
              ))}
            </div>
          </LeftPanel>
          <motion.div 
            initial={{ width: "0px", marginLeft: "0px" }} // Initial state
            animate={{ 
                width: selectedEstimate ? "380px" : "0px", 
                minWidth: selectedEstimate ? "380px" : "0px",
                marginLeft: selectedEstimate ? "20px" : "0px" 
            }} // Target state
            transition={{ 
                duration: 0.5, // Duration of the animation in seconds
                ease: "easeInOut" // Easing function
            }}
            style={{ overflow: 'hidden' }} // Ensure overflow is hidden for smooth animation
          >
            <AnimatePresence mode="wait">
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.2, delay: 0 }} // Delay to match the expand animation
              key={selectedEstimate ? selectedEstimate.address : null} // Use a unique key for each estimate
            >
            {isCreatingEstimate ? (
              <>
                <FormSection style={{display:"flex", flexDirection:"column", }}>
                  <h3>Enter Estimate Information</h3>
                  <Form layout="vertical" form={form}>
                    <Form.Item label="Address" name="address">
                      <Box>
                        <StandaloneSearchBox onLoad={onSearchBoxLoaded} onPlacesChanged={onPlacesChanged}>
                          <Input
                            value={addressInput}
                            label="Search Address"
                            variant="outlined"
                            placeholder="Search For The Address"
                            onChange={(e) => {
                              setAddressInput(e.target.value);
                            }}
                          />
                        </StandaloneSearchBox>
                      </Box>
                    </Form.Item>
                    <Form.Item label="Client" name="client">
                      <Input />
                    </Form.Item>
                    <Form.Item label="Date" name="date">
                      <DatePicker onChange={handleDateChange} />
                    </Form.Item>
                    <Form.Item label="Parking Lot Sqft" name="lots_sqft">
                      <Box style={{display:"flex", justifyContent:"space-between"}}>
                        <Input style={{flexGrow:"4"}}/>
                        <Button variant="contained" style={{flexGrow:"1"}}>Auto Compute</Button>
                      </Box>
                    </Form.Item>
                    <Form.Item label="Walkway Sqft" name="walkway_sqft">
                    <Box style={{display:"flex", justifyContent:"space-between"}}>
                        <Input style={{flexGrow:"4"}}/>
                        <Button variant="contained" style={{flexGrow:"1"}}>Auto Compute</Button>
                      </Box>
                    </Form.Item>
                    <Form.Item initialValue={serviceLevel} label="Service Level" name="service_level" style={{marginRight:"5px", marginLeft:"5px"}}>
                      <Slider
                        value={serviceLevel}
                        onChange={(value) => setServiceLevel(Number(value))}
                        aria-labelledby="discrete-slider"
                        valueLabelDisplay="auto"
                        step={1}
                        marks={serviceLevels}
                        min={1}
                        max={5}
                      />
                    </Form.Item>
                    <Form.Item initialValue={saltPrice} label="Salt Price" name="salt_price" style={{marginRight:"5px", marginLeft:"5px"}}>
                    <Slider
                        value={saltPrice}
                        onChange={(value) => setSaltPrice(Number(value))}
                        valueLabelDisplay="auto"
                        step={1}
                        marks={saltPriceLevels}
                        min={0}
                        max={200}
                      />
                    </Form.Item>
                  </Form>
                  <Box display="flex" justifyContent="flex-end">
                    <Button onClick={handleGenerateEstimate} variant="contained" color="primary">Generate Estimate</Button>
                  </Box>
                </FormSection>
                <CalculationSection>
                </CalculationSection>
              </>
            ) : (
              selectedEstimate && (
                <div style={{ backgroundColor: "#f0f0f0", borderRadius: '10px', padding: '15px', marginRight: '20px', position: 'relative' }}>
                  <div style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'space-between', width: '100%', marginTop: '10px' }}>
                    <span style={{ fontSize: '18px', fontWeight: '700' }}><IconBuildingFactory2 size={18} stroke={2} style={{ marginRight: '3px'}}/> {selectedEstimate.address}</span>
                    <AntdTooltip title="Close Estimate" placement="top">
                    <IconX size={18} onClick={() => {setSelectedEstimate(null)}} style={{cursor: "pointer", position: 'absolute', top: '0', right: '0', marginTop: '15px', marginRight: '15px'}}/>
                    </AntdTooltip>
                  </div>
                  <div className='estimate_basic_info' style={{ display: 'flex', alignItems: 'flex-start', flexDirection: 'column', justifyContent: 'flex-start', gap: '10px', marginTop: '10px' }}>
                    <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                      <span>Client:</span>
                      <span style={{fontWeight: "bold"}}>{selectedEstimate.client ? selectedEstimate.client : "N/A"}</span>
                    </div>
                    <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                      <span>Date:</span>
                      <span style={{fontWeight: "bold"}}>{selectedEstimate.date}</span>
                    </div>
                    <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                      <span>Parking Lot Sqft:</span> 
                      <span style={{fontWeight: "bold"}}>{selectedEstimate.lots_sqft.toLocaleString()} sq. ft</span>
                    </div>
                    <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                      <span>Walkway Sqft:</span> 
                      <span style={{fontWeight: "bold"}}>{selectedEstimate.walkway_sqft.toLocaleString()} sq. ft</span>
                    </div>
                    <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                      <span>Service Level:</span>
                      <span style={{fontWeight: "bold"}}>{selectedEstimate.service_level}</span>
                    </div>
                    <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                      <span>Salt Level:</span>
                      <span style={{fontWeight: "bold"}}>${parseFloat(selectedEstimate.salt_price).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</span>
                    </div>
                  </div>

                  <h4>
                    Prices:
                  </h4>
                  <div className='estimate_basic_info' style={{ display: 'flex', alignItems: 'flex-start', flexDirection: 'column', justifyContent: 'flex-start', gap: '10px', marginTop: '10px' }}>
                    <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                      <span>Plowing Price:</span>
                      <span style={{fontWeight: "bold"}}>${parseFloat(selectedEstimate.plowing_price).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</span>
                    </div>
                    <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                      <span>Salting Price:</span>
                      <span style={{fontWeight: "bold"}}>${parseFloat(selectedEstimate.salting_price).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</span>
                    </div>
                    <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                      <span>Walkway Price:</span>
                      <span style={{fontWeight: "bold"}}>${parseFloat(selectedEstimate.walkway_price).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</span>
                    </div>
                  </div>

                  <h3 className='underline_item' style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <span >Total Price:</span>
                    <span> ${parseFloat(selectedEstimate.total_price).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</span>
                  </h3>
                  <Button
                    type="primary"
                    onClick={() => handleEditEstimate(selectedEstimate)}
                    style={{ boxShadow: 'none', marginBottom: '5px' }}
                    block
                  >
                    Edit Estimate
                  </Button>
                  <Button
                    type="primary"
                    danger
                    onClick={() => handleDeleteEstimate(selectedEstimate)}
                    block
                  >
                    Delete Estimate
                  </Button>
                </div>
              )
            )}
            </motion.div>
            </AnimatePresence>
          </motion.div>
          <RightPanel>
            <MapContainer center={mapPosition || [43.7, -79.4]} zoom={10} style={{ height: '100%'}}>
              <TileLayer
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
              />
              {estimates.map((estimate) => (
                <Marker
                  key={estimate.id}
                  position={[estimate.latitude, estimate.longitude]}
                  icon={customBlueMarkerIcon}
                >
                  <Tooltip>
                    <div>
                      <h4>{estimate.address}</h4>
                      <p>Date: {estimate.date}</p>
                      <h4>Total Price: ${parseFloat(estimate.total_price).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</h4>
                    </div>
                  </Tooltip>
                </Marker>
              ))}
              {
                newEstimatePosition && (
                  <Marker position={newEstimatePosition} icon={customRedMarkerIcon}></Marker>
                )
              }
              <SetViewOnClick coords={mapPosition} />
            </MapContainer>
          </RightPanel>
      </div>
      <Modal
        title="Edit Estimate"
        open={isEditingEstimate}
        onClose={() => setIsEditingEstimate(false)}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        centered
        width={600}
        onCancel={() => setIsEditingEstimate(false)}
        footer={[ <Button onClick={() => setIsEditingEstimate(false)} type='default' style={{boxShadow: 'none'}}>Cancel</Button>, <Button onClick={handleUpdateEstimate} type='primary' style={{boxShadow: 'none'}}>Update Estimate</Button>]}
      >
        <div style={{display:"flex", flexDirection:"column"}}>
          <Form layout="vertical" form={form}>
            <Form.Item label="Address" name="address" style={{ margin: '0', marginBottom: '5px' }}>
              <Input disabled />
            </Form.Item>
            <Form.Item label="Client" name="client" style={{ margin: '0', marginBottom: '5px' }}>
              <Input disabled />
            </Form.Item>
            <Form.Item label="Date" name="date" style={{ margin: '0', marginBottom: '5px' }}>
              <Input disabled />
            </Form.Item>
            <Form.Item label="Parking Lot Sqft" name="lots_sqft" style={{ margin: '0', marginBottom: '5px' }}>
              <Input />
            </Form.Item>
            <Form.Item label="Walkway Sqft" name="walkway_sqft" style={{ margin: '0', marginBottom: '5px' }}>
              <Input />
            </Form.Item>
            <Form.Item label="Service Level" name="service_level" style={{margin: '0', marginBottom: '5px'}}>
              <Slider
                value={serviceLevel}
                onChange={(value) => setServiceLevel(Number(value))}
                aria-labelledby="discrete-slider"
                valueLabelDisplay="auto"
                step={1}
                marks={serviceLevels}
                min={1}
                max={5}
              />
            </Form.Item>
            <Form.Item label="Salt Price" name="salt_price" style={{margin: '0', marginBottom: '5px'}}>
              <Slider
                value={saltPrice}
                onChange={(value) => setSaltPrice(Number(value))}
                valueLabelDisplay="auto"
                step={1}
                marks={saltPriceLevels}
                min={0}
                max={200}
              />
            </Form.Item>
          </Form>
        </div>
      </Modal>
      <Modal
        open={isCreatingEstimate}
        title="Create Estimate"
        onClose={() => setIsCreatingEstimate(false)}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        centered
        width={600}
        onCancel={() => setIsCreatingEstimate(false)}
        footer={[ <Button onClick={() => setIsCreatingEstimate(false)} type='default' style={{boxShadow: 'none'}}>Cancel</Button>, <Button onClick={handleGenerateEstimate} type='primary' style={{boxShadow: 'none'}}>Generate Estimate</Button>]}
      >
        <div style={{display:"flex", flexDirection:"column"}}>
          <Form layout="vertical" form={form}>
            <Form.Item label="Address" name="address" style={{margin: '0', marginBottom: '5px'}}>
              <Box>
                <StandaloneSearchBox onLoad={onSearchBoxLoaded} onPlacesChanged={onPlacesChanged}>
                  <Input
                    value={addressInput}
                    label="Search Address"
                    variant="outlined"
                    placeholder="Search For The Address"
                    onChange={(e) => {
                      setAddressInput(e.target.value);
                    }}
                  />
                </StandaloneSearchBox>
              </Box>
            </Form.Item>
            <Form.Item label="Client" name="client" style={{margin: '0', marginBottom: '5px'}}>
              <Input />
            </Form.Item>
            <Form.Item label="Date" name="date" style={{margin: '0', marginBottom: '5px'}}>
              <DatePicker onChange={handleDateChange} style={{ width: '100%' }} />
            </Form.Item>
            <Form.Item label="Parking Lot Sqft" name="lots_sqft" style={{margin: '0', marginBottom: '5px'}}>
              <Box style={{display:"flex", justifyContent:"space-between"}}>
                <Input style={{flexGrow:"4"}}/>
                <Button variant="contained" style={{flexGrow:"1", marginLeft: '5px'}}>Auto Compute</Button>
              </Box>
            </Form.Item>
            <Form.Item label="Walkway Sqft" name="walkway_sqft" style={{margin: '0', marginBottom: '5px'}}>
            <Box style={{display:"flex", justifyContent:"space-between"}}>
                <Input style={{flexGrow:"4"}}/>
                <Button variant="contained" style={{flexGrow:"1", marginLeft: '5px'}}>Auto Compute</Button>
              </Box>
            </Form.Item>
            <Form.Item initialValue={serviceLevel} label="Service Level" name="service_level" style={{margin: '0', marginBottom: '5px'}}>
              <Slider
                value={serviceLevel}
                onChange={(value) => setServiceLevel(Number(value))}
                aria-labelledby="discrete-slider"
                valueLabelDisplay="auto"
                step={1}
                marks={serviceLevels}
                min={1}
                max={5}
              />
            </Form.Item>
            <Form.Item initialValue={saltPrice} label="Salt Price" name="salt_price" style={{margin: '0', marginBottom: '5px'}}>
            <Slider
                value={saltPrice}
                onChange={(value) => setSaltPrice(Number(value))}
                valueLabelDisplay="auto"
                step={1}
                marks={saltPriceLevels}
                min={0}
                max={200}
              />
            </Form.Item>
          </Form>
          <Box display="flex" justifyContent="flex-end">
            
          </Box>
        </div>
        <CalculationSection>
        </CalculationSection>
      </Modal>
      </ConfigProvider>
  );
};

export default EstimatePage;