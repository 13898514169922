// src/components/Profile/TeamTab.js

import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Collapse,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Tooltip,
  Switch,
} from '@mui/material';
import { Table } from 'antd';
import { motion } from 'framer-motion';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { IconDoorExit } from '@tabler/icons-react';
import { BsBuildingAdd } from 'react-icons/bs';
import RoleSelect from './RoleSelect';
import EditClientUserSites from './EditClientUserSites';
import styles from './Profile.module.css';

const TeamTab = ({ 
  teamMembers, 
  clients, 
  profile, 
  handleRoleChange, 
  handleDeleteUser, 
  handleRequestAccessChange,
  createNotification 
}) => {
  const [expandedGroups, setExpandedGroups] = useState({
    admin: true,
    standard: true,
    client: true,
  });
  const [selectedClientFilter, setSelectedClientFilter] = useState('all');
  const [editUserSitesOpen, setEditUserSitesOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [members, setMembers] = useState(teamMembers);

  // Update members when teamMembers prop changes
  useEffect(() => {
    setMembers(teamMembers);
  }, [teamMembers]);

  const groupMembersByRole = (members) => {
    const groups = members.reduce((acc, member) => {
      const role = member.role;
      if (!acc[role]) acc[role] = [];
      acc[role].push(member);
      return acc;
    }, {});

    const roleOrder = ['admin', 'standard', 'client'];

    return roleOrder
      .filter((role) => groups[role]?.length > 0)
      .map((role) => [role, groups[role]]);
  };

  const handleGroupToggle = (role) => {
    setExpandedGroups(prev => ({
      ...prev,
      [role]: !prev[role]
    }));
  };

  const getFilteredMembers = (members, roleGroup) => {
    if (roleGroup !== 'client' || selectedClientFilter === 'all') return members;
    return members.filter((member) => member.client_id === selectedClientFilter);
  };

  const onAccessibleSitesUpdate = (userId, updatedAccessibleSites) => {
    setMembers((prevMembers) =>
      prevMembers.map((member) =>
        member.id === userId ? { ...member, accessible_sites: updatedAccessibleSites } : member
      )
    );
  };

  const handleEditUserSites = (user) => {
    setSelectedUser(user);
    setEditUserSitesOpen(true);
  };

  const handleEditUserSitesClose = () => {
    setSelectedUser(null);
    setEditUserSitesOpen(false);
  };

  const teamColumns = (roleGroup) => {
    const columns = [
      {
        title: '',
        dataIndex: 'avatar',
        key: 'avatar',
        render: (_, record) => (
          <img
            src="https://i.pinimg.com/474x/d6/cd/f2/d6cdf2a5daaf96462127cc31fb621851.jpg"
            alt="Avatar"
            style={{
              width: '30px',
              height: '30px',
              borderRadius: '50%',
              border: '1px solid #004630',
            }}
          />
        ),
        width: 50,
      },
      {
        title: 'First Name',
        dataIndex: 'first_name',
        key: 'first_name',
        className: styles.tableRowItem,
      },
      {
        title: 'Last Name',
        dataIndex: 'last_name',
        key: 'last_name',
        className: styles.tableRowItem,
      },
      {
        title: 'Email',
        dataIndex: 'email',
        key: 'email',
        className: styles.tableRowItem,
      },
      {
        title: 'Role',
        dataIndex: 'role',
        key: 'role',
        render: (_, member) =>
          profile.role === 'admin' ? (
            <RoleSelect
              member={member}
              onRoleChange={handleRoleChange}
            />
          ) : (
            <span>{member.role.charAt(0).toUpperCase() + member.role.slice(1)}</span>
          ),
      },
    ];
  
    // Append the Client column if the roleGroup is 'client'
    if (roleGroup === 'client') {
      columns.push({
        title: 'Client',
        dataIndex: 'client_name',
        key: 'client_name',
        className: styles.tableRowItem,
        render: (_, member) =>
          member.client?.client_name || 'N/A',
      });

      // Add columns for request access types
      if (profile.role === 'admin') {
        columns.push({
          title: 'Regular Requests',
          key: 'regular_requests',
          render: (_, member) => (
            <Tooltip title={member.has_regular_request_access ? "Disable regular requests" : "Enable regular requests"}>
              <Switch
                checked={member.has_regular_request_access}
                onChange={(event) => handleRequestAccessChange(member.id, 'regular', event.target.checked)}
                size="small"
              />
            </Tooltip>
          ),
        });

        columns.push({
          title: 'SnowEdge Requests',
          key: 'snowedge_requests',
          render: (_, member) => (
            <Tooltip title={member.has_snowedge_access ? "Disable SnowEdge requests" : "Enable SnowEdge requests"}>
              <Switch
                checked={member.has_snowedge_access}
                onChange={(event) => handleRequestAccessChange(member.id, 'snowedge', event.target.checked)}
                size="small"
              />
            </Tooltip>
          ),
        });
      }
    }
  
    // Append the Actions column if the profile role is 'admin'
    if (profile.role === 'admin') {
      columns.push({
        title: 'Actions',
        key: 'actions',
        render: (_, member) => (
          <Box className={styles.tableRowContainer}>
            <Tooltip title="Remove User">
              <Box className={styles.removeButton}>
                <IconDoorExit size={18} onClick={() => handleDeleteUser(member.id, member.role)}/>
              </Box>
            </Tooltip>
            {roleGroup === 'client' && (
              <Tooltip title="Edit Available Sites for Requests">
                <Box className={styles.editButton}>
                  <BsBuildingAdd size={18} onClick={() => handleEditUserSites(member)}/>
                </Box>
              </Tooltip>
            )}
          </Box>
        ),
      });
    }
  
    return columns;
  };

  return (
    <>
    <motion.div 
      className={styles.tabContentInner} 
      initial={{ opacity: 0, translateY: 20 }}
      animate={{ opacity: 1, translateY: 0 }}
      transition={{
        type: "spring",
        stiffness: 150,
        damping: 30,
        delay: 0.2
      }}
    >
      <Box className={styles.titleContainer}>
        <Typography className={styles.title}>
          Team
        </Typography>
        <Typography className={styles.subtitle}>
          Manage your team members and their roles.
        </Typography>
      </Box>

      <Box className={styles.lineBreak} />

      {members.length > 0 ? (
        groupMembersByRole(members).map(([roleGroup, members]) => (
          <React.Fragment key={roleGroup}>
            <Box className={styles.roleGroupHeader} onClick={() => handleGroupToggle(roleGroup)} sx={{ cursor: 'pointer' }}>
              <Box className={styles.roleGroupTitleContainer}>
                <Typography className={styles.roleGroupTitle}>
                  {roleGroup.charAt(0).toUpperCase() + roleGroup.slice(1)}s
                  <Typography component="span" className={styles.memberCount}>
                    ({getFilteredMembers(members, roleGroup).length})
                  </Typography>
                </Typography>
                <ExpandMoreIcon 
                  className={`${styles.expandIcon} ${expandedGroups[roleGroup] ? styles.expanded : ''}`}
                />
              </Box>
            </Box>
            
            <Collapse in={expandedGroups[roleGroup]}>
              {roleGroup === 'client' && (
                <Box className={styles.filterContainer}>
                  <FormControl 
                    variant="outlined" 
                    className={styles.clientFilter}
                    size="small"
                  >
                    <InputLabel>Filter by Client</InputLabel>
                    <Select
                      value={selectedClientFilter}
                      onChange={(e) => setSelectedClientFilter(e.target.value)}
                      label="Filter by Client"
                    >
                      <MenuItem value="all">All Clients</MenuItem>
                      {clients.map((client) => (
                        <MenuItem key={client.client_id} value={client.client_id}>
                          {client.client_name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box>
              )}

              <Table
                columns={teamColumns(roleGroup, profile.role, handleRoleChange, handleDeleteUser)}
                dataSource={
                  getFilteredMembers(members, roleGroup).map((member) => ({
                    key: member.id,
                    ...member
                  }))
                }
                pagination={false}
                size='small'
              />
            </Collapse>
            <Box className={styles.roleGroupDivider} />
          </React.Fragment>
        ))
      ) : (
        <Typography>No team members available.</Typography>
      )}
    </motion.div>

    <EditClientUserSites
      member={selectedUser}
      open={editUserSitesOpen}
      onClose={handleEditUserSitesClose}
      createNotification={createNotification}
      onAccessibleSitesUpdate={onAccessibleSitesUpdate}
    />
    </>
  );
};

export default TeamTab;