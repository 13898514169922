// PasswordResetPage.js
import React, { useState, useRef, useEffect } from 'react';
import Typography from '@mui/material/Typography';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import { useNavigate, useParams } from 'react-router-dom';
import api from '../authentication/axiosInstance';
import companyLogo from '../assets/company-logo.png';
import styles from './LoginPage.module.css';
import FormTextField from '../components/FormTextField';
import { motion } from 'framer-motion';
import image1 from '../assets/login-page.jpg';

const ResetPasswordPage = () => {
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [notificationOpen, setNotificationOpen] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState('');
  const [notificationSeverity, setNotificationSeverity] = useState('success');
  const navigate = useNavigate();
  const { token } = useParams();

  const dummyRef = useRef(null);
  useEffect(() => {
    // Focus on the dummy element to prevent input focus
    if (dummyRef.current) {
      dummyRef.current.focus();
    }
  }, []);

  const handleNotificationClose = () => {
    setNotificationOpen(false);
  };

  const handlePasswordReset = async (event) => {
    event.preventDefault();

    if (!password || !confirmPassword) {
      setNotificationMessage('Both password fields are required.');
      setNotificationSeverity('error');
      setNotificationOpen(true);
      return;
    }

    if (password !== confirmPassword) {
      setNotificationMessage('Passwords do not match.');
      setNotificationSeverity('error');
      setNotificationOpen(true);
      return;
    }

    try {
      const response = await api.post(`/users/reset-password/${token}/`, { password });
      setNotificationMessage('Password reset successful! You can now log in.');
      setNotificationSeverity('success');
      setNotificationOpen(true);

      setTimeout(() => navigate('/login'), 2000);
    } catch (error) {
      setNotificationMessage('Failed to reset password. The link may have expired.');
      setNotificationSeverity('error');
      setNotificationOpen(true);
      console.error('Password reset failed:', error);
    }
  };

  return (
    <Box className={styles.main}>
      <Box className={styles.left}>
        <img src={companyLogo} alt="Company Logo" className={styles.logo} />
        <motion.div
          initial={{ opacity: 0, translateY: 200 }}
          animate={{ opacity: 1, translateY: 0 }}
          transition={{
            type: 'spring',
            stiffness: 150,
            damping: 30,
          }}
          className={styles.formContainer}
        >
          {/* Hidden focusable element */}
          <div
            ref={dummyRef}
            tabIndex={-1}
            style={{ position: 'absolute', opacity: 0, height: 0, width: 0 }}
          ></div>
          <Box className={styles.textContainer}>
            <Typography className={styles.title}>Reset Your Password</Typography>
            <Typography className={styles.subtitle}>
              Enter a new password below.
            </Typography>
          </Box>
          <Box className={styles.form}>
            <FormTextField
              labelName="New Password"
              idName="new-password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder="Enter your new password..."
              type="password"
            />
            <FormTextField
              labelName="Confirm Password"
              idName="confirm-password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              placeholder="Confirm your new password..."
              type="password"
            />
            <Box onClick={handlePasswordReset} className={styles.submitButton}>
              Reset Password
            </Box>
          </Box>
        </motion.div>
        <Snackbar
          open={notificationOpen}
          autoHideDuration={6000}
          onClose={handleNotificationClose}
        >
          <Alert
            onClose={handleNotificationClose}
            severity={notificationSeverity}
            className={styles.alert}
          >
            {notificationMessage}
          </Alert>
        </Snackbar>
        <Typography className={styles.copyright}>
          &copy; {new Date().getFullYear()} Facilitate
        </Typography>
      </Box>
      <Box className={styles.right}>
        <Box className={styles.rightImage} component="img" src={image1} />
      </Box>
    </Box>
  );
};

export default ResetPasswordPage;