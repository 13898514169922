// ClientRequestsPortal.js
import React, { useState, useEffect, useContext } from 'react';
import { 
  List, 
  Typography, 
  message, 
  Modal, 
  Card, 
  Row, 
  Col, 
  Statistic, 
  Alert, 
  Empty, 
  Button, 
  Select, 
  Spin, 
  Divider, 
  Tooltip, 
  Badge,
  Tag,
  Space,
  Tabs,
  Image
} from 'antd';
import { 
  MapContainer, 
  TileLayer, 
  Marker, 
  Popup 
} from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import api from '../authentication/axiosInstance';
import './ClientRequestsPortal.css';
import { 
  CalendarOutlined, 
  EnvironmentOutlined, 
  InfoCircleOutlined,
  PictureOutlined
} from '@ant-design/icons';
import Joyride, { STATUS } from 'react-joyride';
import { AuthContext } from '../context/AuthContext';

const { Text, Title } = Typography;
const { Option } = Select;
const { TabPane } = Tabs;

const scrollToTarget = (target) => {
  const element = document.querySelector(target);
  if (element) {
    element.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
  }
};

const ClientRequestsPortal = ({ clientEmail, loading }) => {
  const { hasSnowEdgeAccess, hasRegularRequestAccess } = useContext(AuthContext);
  console.log('ClientRequestsPortal - Received clientEmail:', clientEmail); // Debugging log
  const [requests, setRequests] = useState([]);
  const [map, setMap] = useState(null);
  const [selectedRequest, setSelectedRequest] = useState(null);
  const [modalVisible, setModalVisible] = useState(false);
  const [stats, setStats] = useState({ pending: 0, claimed: 0, completed: 0 });
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [userPermissions, setUserPermissions] = useState({
    regular_requests: false,
    snow_edge_requests: false
  });

  const [statusFilter, setStatusFilter] = useState('all');
  const [siteFilter, setSiteFilter] = useState('all');
  const [sites, setSites] = useState([]);

  const [snowEdgeRequests, setSnowEdgeRequests] = useState([]);
  const [activeTab, setActiveTab] = useState(() => {
    // Set initial tab based on permissions
    if (hasRegularRequestAccess) return 'regular';
    if (hasSnowEdgeAccess) return 'snowedge';
    return '';
  });

  const [runTutorial, setRunTutorial] = useState(false);

  const [snowEdgeModalVisible, setSnowEdgeModalVisible] = useState(false);
  const [selectedSnowEdgeRequest, setSelectedSnowEdgeRequest] = useState(null);

  const [openRequest, setOpenRequest] = useState(null);

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  const tutorialSteps = [
    {
      target: '.client-requests-portal',
      content: (
        <div style={{ padding: '10px' }}>
          <h2 style={{ margin: '0 0 10px', color: '#034832' }}>👋 Welcome!</h2>
          <p style={{ fontSize: '15px', lineHeight: '1.5' }}>
            Welcome to your Service Requests Portal! Let's take a quick tour to help you get started.
          </p>
        </div>
      ),
      placement: 'center',
      disableBeacon: true,
    },
    {
      target: '.filters',
      content: (
        <div style={{ padding: '10px' }}>
          <h3 style={{ color: '#034832', marginBottom: '8px' }}>🔍 Filter Your Requests</h3>
          <p>Easily find specific requests by filtering them based on their status or site location.</p>
        </div>
      ),
      placement: 'bottom',
    },
    {
      target: '.request-list',
      content: (
        <div style={{ padding: '10px' }}>
          <h3 style={{ color: '#034832', marginBottom: '8px' }}>📋 Request List</h3>
          <p>View all your service requests here. Click on any request to see its complete details.</p>
        </div>
      ),
      placement: 'left',
      floaterProps: {
        disableAnimation: true
      },
      spotlightPadding: 0,
      disableScrolling: true,
    },
    {
      target: '.right-panel',
      content: (
        <div style={{ padding: '10px' }}>
          <h3 style={{ color: '#034832', marginBottom: '8px' }}>🗺️ Interactive Map</h3>
          <p>See where your service requests are located. Click on any marker for quick details.</p>
        </div>
      ),
      placement: 'left',
    }
  ];

  const handleTutorialCallback = (data) => {
    const { status } = data;
    if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
      setRunTutorial(false);
      // Optionally save to localStorage to prevent showing on future visits
      localStorage.setItem('tutorialCompleted', 'true');
    }
  };

  const customMarkerIcon = L.icon({
    iconUrl: 'https://unpkg.com/leaflet@1.7.1/dist/images/marker-icon.png',
    iconSize: [25, 41],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
    shadowUrl: 'https://unpkg.com/leaflet@1.7.1/dist/images/marker-shadow.png',
    shadowSize: [41, 41],
  });

  const fetchRequests = async () => {
    if (!clientEmail) {
      console.error('ClientRequestsPortal - clientEmail is undefined');
      setError('Unable to fetch requests: Client email is missing');
      setRequests([]);
      updateStats([]);
      setSites([]);
      return;
    }

    let isMounted = true;
    setIsLoading(true);
    setError(null);

    try {
      console.log('Fetching requests for email:', clientEmail);
      const response = await api.get(`/request-manager/requests/?client_email=${clientEmail}`);
      console.log('API Response:', response);
      
      if (isMounted) {
        setRequests(response.data);
        updateStats(response.data);

        const uniqueSites = [...new Set(response.data
          .map(req => req.site && req.site.site_name)
          .filter(site => site != null)
        )];
        setSites(uniqueSites);
      }
    } catch (error) {
      if (isMounted) {
        console.error('ClientRequestsPortal - Failed to fetch requests:', error);
        setError(`Failed to fetch requests: ${error.message}`);
        if (error.response) {
          console.log('ClientRequestsPortal - Error response:', error.response);
          setError(`Server error: ${error.response.status} ${error.response.statusText}`);
        }
        setRequests([]);
        updateStats([]);
        setSites([]);
      }
    } finally {
      if (isMounted) {
        setIsLoading(false);
      }
    }

    return () => {
      isMounted = false;
    };
  };

  const fetchSnowEdgeRequests = async () => {
    if (!clientEmail) {
      console.error('ClientRequestsPortal - clientEmail is undefined');
      setError('Unable to fetch SnowEdge requests: Client email is missing');
      setSnowEdgeRequests([]);
      return;
    }

    setIsLoading(true);
    setError(null);

    try {
      const response = await api.get(`/request-manager/snow-edge-requests/?client_email=${clientEmail}`);
      setSnowEdgeRequests(response.data);
    } catch (error) {
      console.error('ClientRequestsPortal - Failed to fetch SnowEdge requests:', error);
      setError(`Failed to fetch SnowEdge requests: ${error.message}`);
      setSnowEdgeRequests([]);
    } finally {
      setIsLoading(false);
    }
  };

  const updateStats = (requestsData) => {
    const newStats = { pending: 0, claimed: 0, completed: 0 };
    requestsData.forEach(request => {
      if (request.status === 'pending') newStats.pending++;
      else if (request.status === 'claimed') newStats.claimed++;
      else if (request.status === 'completed') newStats.completed++;
    });
    setStats(newStats);
  };

 
  useEffect(() => {
    console.log('ClientRequestsPortal - clientEmail:', clientEmail);
    if (clientEmail) {
      fetchRequests();
      fetchSnowEdgeRequests();
    }
  }, [clientEmail]);

  const handleRequestClick = async (requestId) => {
    const request = activeTab === 'regular'
      ? requests.find(r => r.id === requestId)
      : snowEdgeRequests.find(r => r.id === requestId);
    setSelectedRequest(request);
    setModalVisible(true);
    
    // Fetch the full request details including images
    try {
      const response = await api.get(`/request-manager/requests/${requestId}/`);
      setOpenRequest(response.data);
    } catch (error) {
      console.error('Error fetching request details:', error);
      message.error('Failed to fetch request details');
    }

    if (request && map) {
      map.flyTo([request.latitude, request.longitude], 14);
    }
  };

  const closeModal = () => {
    setModalVisible(false);
    setSelectedRequest(null);
    setOpenRequest(null);
  };

  const handleStatusFilterChange = (value) => {
    setStatusFilter(value || 'all');
  };

  const handleSiteFilterChange = (value) => {
    setSiteFilter(value || 'all');
  };

  const filteredRequests = requests.filter(request => {
    const statusMatch = statusFilter === 'all' || request.status === statusFilter;
    const siteMatch = siteFilter === 'all' || (request.site && request.site.site_name === siteFilter);
    return statusMatch && siteMatch;
  });

  const handleSnowEdgeRequestClick = (request) => {
    setSelectedSnowEdgeRequest(request);
    setSnowEdgeModalVisible(true);
  };

  const closeSnowEdgeModal = () => {
    setSnowEdgeModalVisible(false);
    setSelectedSnowEdgeRequest(null);
  };

  const renderSnowEdgeRequests = () => (
    <List
      className="snow-edge-request-list"
      itemLayout="horizontal"
      dataSource={snowEdgeRequests}
      renderItem={(request) => (
        <List.Item 
          key={request.id}
          onClick={() => handleSnowEdgeRequestClick(request)}
          style={{ 
            cursor: 'pointer',
            borderLeft: selectedSnowEdgeRequest && selectedSnowEdgeRequest.id === request.id ? '4px solid #1890ff' : '4px solid transparent',
            paddingLeft: '16px'
          }}
          className={selectedSnowEdgeRequest && selectedSnowEdgeRequest.id === request.id ? 'active-request' : ''}
        >
          <List.Item.Meta
            title={
              <div className="request-header" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginLeft: '8px' }}>
                <span><strong>{request.site && request.site.site_name ? request.site.site_name : 'Unassigned'}</strong></span>
                <span className="request-date" style={{ color: '#888' }}>
                  {request.created_at ? new Date(request.created_at).toLocaleDateString() : 'No Date'}
                </span>
              </div>
            }
            description={
              <div style={{ marginLeft: '8px' }}>
                <div style={{ marginBottom: '5px' }}>
                  <Badge 
                    status={request.status === 'submitted' ? 'warning' : 'success'} 
                    text={request.status.charAt(0).toUpperCase() + request.status.slice(1)}
                  />
                </div>
                <div style={{ fontSize: '0.9em', color: '#666' }}>
                  <CalendarOutlined style={{ marginRight: '5px' }} /> 
                  Requested: {request.requested_date ? new Date(request.requested_date).toLocaleDateString() : 'Not specified'}
                </div>
              </div>
            }
          />
        </List.Item>
      )}
    />
  );

  const renderRequestList = () => (
    <List
      className="request-list"
      itemLayout="horizontal"
      dataSource={filteredRequests}
      renderItem={(request) => (
        <List.Item 
          key={request.id} 
          onClick={() => handleRequestClick(request.id)}
          className={selectedRequest && selectedRequest.id === request.id ? 'active-request' : ''}
          style={{ 
            cursor: 'pointer', 
            borderLeft: selectedRequest && selectedRequest.id === request.id ? '4px solid #1890ff' : '4px solid transparent',
            paddingLeft: '16px'
          }}
        >
          <List.Item.Meta
            title={
              <div className="request-header" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginLeft: '8px' }}>
                <span><strong>{request.site && request.site.site_name ? request.site.site_name : 'Unassigned'}</strong></span>
                <span className="request-date" style={{ color: '#888' }}>
                  {request.created_at ? new Date(request.created_at).toLocaleDateString() : 'No Date'}
                </span>
              </div>
            }
            description={
              <div style={{ marginLeft: '8px' }}>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '5px' }}>
                  <span>{request.request_type || 'No Type'}</span>
                  <span>
                    <Badge 
                      status={
                        request.status === 'pending' ? 'warning' : 
                        request.status === 'claimed' ? 'processing' : 
                        'success'
                      } 
                      text={request.status.charAt(0).toUpperCase() + request.status.slice(1)}
                    />
                  </span>
                </div>
              </div>
            }
          />
        </List.Item>
      )}
    />
  );

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <Spin spinning={loading || isLoading}>
      <div className="client-requests-portal">
        <div style={{ display: 'flex', justifyContent: isMobile ? 'flex-start' : 'space-between', alignItems: isMobile ? 'flex-start' : 'center', marginBottom: '20px', flexDirection: isMobile ? 'column' : 'row' }}>
          <Title level={2}>My Service Requests</Title>
          {activeTab === 'regular' && (
            <Button 
              type="primary"
              icon={<InfoCircleOutlined />}
              onClick={() => setRunTutorial(true)}
              style={{
                borderRadius: '8px',
                display: 'flex',
                alignItems: 'center',
                gap: '8px',
                background: 'linear-gradient(45deg, #034832, #0a6245)',
                border: 'none',
                boxShadow: '0 2px 8px rgba(3, 72, 50, 0.35)',
                padding: '8px 16px',
                height: 'auto',
              }}
            >
              Start Tutorial
            </Button>
          )}
        </div>

        <Joyride
          steps={tutorialSteps}
          run={runTutorial}
          continuous
          showProgress
          showSkipButton
          callback={handleTutorialCallback}
          scrollToFirstStep={false} // Prevents automatic scrolling to first step
          scrollOffset={0} // Removes default scroll offset
          disableScrollParentFix={true} // Disables default scroll behavior
          styles={{
            options: {
              primaryColor: '#034832',
              zIndex: 1000,
            },
            tooltip: {
              backgroundColor: '#ffffff',
              borderRadius: '12px',
              boxShadow: '0 4px 12px rgba(0,0,0,0.15)',
              padding: '20px',
            },
            tooltipContainer: {
              textAlign: 'left',
            },
            buttonNext: {
              backgroundColor: '#034832',
              borderRadius: '6px',
              color: '#fff',
              padding: '8px 16px',
              fontSize: '14px',
              fontWeight: '500',
              transition: 'all 0.3s',
              '&:hover': {
                backgroundColor: '#023623',
                transform: 'translateY(-1px)',
              }
            },
            buttonBack: {
              marginRight: 10,
              color: '#666',
              padding: '8px 16px',
              fontSize: '14px',
              fontWeight: '500',
            },
            buttonSkip: {
              color: '#666',
              fontSize: '14px',
            },
            buttonClose: {
              color: '#666',
              fontSize: '14px',
            },
            spotlight: {
              backgroundColor: 'rgba(255, 255, 255, 0.45)',
              borderRadius: 0, // Removes spotlight border radius
            },
            overlay: {
              backgroundColor: 'rgba(0, 0, 0, 0.5)', // Darker overlay for better contrast
            }
          }}
        />

        <Divider />
        {error && <Alert message={error} type="error" style={{ marginBottom: '20px' }} />}
        <Tabs activeKey={activeTab} onChange={setActiveTab}>
          {hasRegularRequestAccess && (
            <TabPane tab="Regular Requests" key="regular">
              <Button 
                onClick={fetchRequests} 
                disabled={isLoading} 
                type="primary" 
                style={{ marginBottom: '20px' }}
                icon={<Tooltip title="Refresh the list of service requests"><i className="fas fa-sync-alt"></i></Tooltip>}
              >
                {isLoading ? 'Refreshing...' : 'Refresh Requests'}
              </Button>
              <Row gutter={[16, 16]} style={{ marginBottom: '20px' }}>
                <Col xs={24} sm={12} md={8}>
                  <Card>
                    <Statistic 
                      title="Pending Requests" 
                      value={stats.pending} 
                      valueStyle={{ color: '#faad14' }}
                      prefix={<i className="fas fa-hourglass-half"></i>}
                    />
                  </Card>
                </Col>
                <Col xs={24} sm={12} md={8}>
                  <Card>
                    <Statistic 
                      title="Claimed Requests" 
                      value={stats.claimed} 
                      valueStyle={{ color: '#1890ff' }}
                      prefix={<i className="fas fa-tasks"></i>}
                    />
                  </Card>
                </Col>
                <Col xs={24} sm={12} md={8}>
                  <Card>
                    <Statistic 
                      title="Completed Requests" 
                      value={stats.completed} 
                      valueStyle={{ color: '#52c41a' }}
                      prefix={<i className="fas fa-check-circle"></i>}
                    />
                  </Card>
                </Col>
              </Row>
              
              {/* Filters */}
              <div className="filters" style={{ marginBottom: '20px' }}>
                <Row gutter={[16, 16]}>
                  <Col xs={24} sm={12} md={8}>
                    <Select
                      value={statusFilter}
                      onChange={handleStatusFilterChange}
                      style={{ width: '100%' }}
                      placeholder="Filter by Status"
                      allowClear
                    >
                      <Option value="all">All Statuses</Option>
                      <Option value="pending">Pending</Option>
                      <Option value="claimed">Claimed</Option>
                      <Option value="completed">Completed</Option>
                    </Select>
                  </Col>
                  <Col xs={24} sm={12} md={8}>
                    <Select
                      value={siteFilter}
                      onChange={handleSiteFilterChange}
                      style={{ width: '100%' }}
                      placeholder="Filter by Site"
                      allowClear
                      showSearch
                      filterOption={(input, option) =>
                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }
                      optionFilterProp="children"
                    >
                      <Option value="all">All Sites</Option>
                      {sites.map(site => (
                        <Option key={site} value={site}>{site || 'Unassigned'}</Option>
                      ))}
                    </Select>
                  </Col>
                </Row>
              </div>
              
              <Row gutter={[16, 16]}>
                <Col xs={24} lg={16}>
                  <div 
                    className="left-panel" 
                    style={{ 
                      maxHeight: '600px', 
                      overflowY: 'auto',
                      position: 'relative',
                      willChange: 'transform',
                    }}
                  >
                    {isLoading ? (
                      <div style={{ textAlign: 'center', padding: '20px' }}>
                        <Spin tip="Loading requests..." />
                      </div>
                    ) : filteredRequests.length > 0 ? (
                      renderRequestList()
                    ) : (
                      <Empty description={error ? "No requests available" : "No matching requests found"} />
                    )}
                  </div>
                </Col>

                <Col xs={24} lg={8}>
                  <div className="right-panel" style={{ height: '600px' }}>
                    <MapContainer 
                      center={[43.6532, -79.3832]} 
                      zoom={10} 
                      whenCreated={setMap} 
                      style={{ height: '100%', width: '100%', borderRadius: '8px' }}
                    >
                      <TileLayer
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                        maxZoom={19}
                      />
                      {filteredRequests.map((request) => (
                        request.site && request.site.latitude && request.site.longitude ? (
                          <Marker 
                            key={request.id} 
                            position={[request.site.latitude, request.site.longitude]} 
                            icon={customMarkerIcon} 
                            eventHandlers={{ click: () => handleRequestClick(request.id) }}
                          >
                            <Popup>
                              <div>
                                <strong>Request #{request.id}</strong><br />
                                {request.site.address || 'No address specified'}
                              </div>
                            </Popup>
                          </Marker>
                        ) : null
                      ))}
                    </MapContainer>
                  </div>
                </Col>
              </Row>
            </TabPane>
          )}
          {hasSnowEdgeAccess && (
            <TabPane tab="SnowEdge Requests" key="snowedge">
              <Button 
                onClick={fetchSnowEdgeRequests} 
                disabled={isLoading} 
                type="primary" 
                style={{ marginBottom: '20px' }}
                icon={<Tooltip title="Refresh the list of SnowEdge requests"><i className="fas fa-sync-alt"></i></Tooltip>}
              >
                {isLoading ? 'Refreshing...' : 'Refresh Requests'}
              </Button>
              {renderSnowEdgeRequests()}
            </TabPane>
          )}
        </Tabs>

        <Modal
          title={selectedRequest ? <Title level={4}>Request #{selectedRequest.id}</Title> : <Title level={4}>Request Details</Title>}
          visible={modalVisible}
          onCancel={closeModal}
          footer={null}
          centered
          destroyOnClose
          width={800}
        >
          {selectedRequest && (
            <Row gutter={[16, 16]}>
              <Col span={12}>
                <Space direction="vertical" size="middle" style={{ width: '100%' }}>
                  <Card size="small">
                    <Row gutter={[16, 16]}>
                      <Col span={12}>
                        <Statistic 
                          title="Status" 
                          value={selectedRequest.status.charAt(0).toUpperCase() + selectedRequest.status.slice(1)}
                          valueStyle={{ 
                            color: selectedRequest.status === 'pending' ? '#faad14' : 
                                    selectedRequest.status === 'claimed' ? '#1890ff' : '#52c41a' 
                          }}
                        />
                      </Col>
                      <Col span={12}>
                        <Statistic 
                          title="Type" 
                          value={selectedRequest.request_type} 
                        />
                      </Col>
                    </Row>
                  </Card>

                  <Card size="small">
                    <Space direction="vertical" size="small">
                      <div>
                        <CalendarOutlined style={{ marginRight: 8 }} />
                        <Text strong>Created:</Text> {selectedRequest.created_at ? new Date(selectedRequest.created_at).toLocaleDateString() : 'No Date'}
                      </div>
                      {selectedRequest.claimed_at && (
                        <div>
                          <CalendarOutlined style={{ marginRight: 8 }} />
                          <Text strong>Claimed:</Text> {new Date(selectedRequest.claimed_at).toLocaleString()}
                        </div>
                      )}
                      {selectedRequest.completed_at && (
                        <div>
                          <CalendarOutlined style={{ marginRight: 8 }} />
                          <Text strong>Completed:</Text> {new Date(selectedRequest.completed_at).toLocaleString()}
                        </div>
                      )}
                    </Space>
                  </Card>

                  <Card size="small">
                    <Space direction="vertical" size="small">
                      <div>
                        <EnvironmentOutlined style={{ marginRight: 8 }} />
                        <Text strong>Site:</Text> {selectedRequest.site && selectedRequest.site.site_name ? selectedRequest.site.site_name : 'Unassigned'}
                      </div>
                      {selectedRequest.site && (
                        <Text type="secondary" style={{ marginLeft: 24 }}>
                          {selectedRequest.site.address || 'No address specified'}
                        </Text>
                      )}
                    </Space>
                  </Card>

                  <Card size="small">
                    <div>
                      <InfoCircleOutlined style={{ marginRight: 8 }} />
                      <Text strong>Details:</Text>
                      <div style={{ marginTop: 8, whiteSpace: 'pre-wrap' }}>
                        {selectedRequest.details || 'No details provided'}
                      </div>
                    </div>
                  </Card>
                </Space>
              </Col>

              <Col span={12}>
                <Card 
                  size="small" 
                  title={<Text strong><PictureOutlined style={{ marginRight: 8 }} />Photos</Text>}
                  style={{ height: '100%' }}
                >
                  {openRequest && openRequest.image_urls && openRequest.image_urls.length > 0 ? (
                    <Space direction="vertical" style={{ width: '100%' }}>
                      {openRequest.image_urls.map((imageUrl, index) => (
                        <Image
                          key={index}
                          src={imageUrl}
                          alt={`Status Picture ${index + 1}`}
                          style={{ 
                            width: '100%',
                            borderRadius: 4
                          }}
                        />
                      ))}
                    </Space>
                  ) : (
                    <Empty description="No photos available" />
                  )}
                </Card>
              </Col>
            </Row>
          )}
        </Modal>

        <Modal
          title={selectedSnowEdgeRequest ? <Title level={4}>SnowEdge Request #{selectedSnowEdgeRequest.id}</Title> : <Title level={4}>Request Details</Title>}
          visible={snowEdgeModalVisible}
          onCancel={closeSnowEdgeModal}
          footer={null}
          centered
          destroyOnClose
          width={400}
        >
          {selectedSnowEdgeRequest && (
            <div className="request-details">
              <Space direction="vertical" size="middle" style={{ width: '100%' }}>
                <Row gutter={[16, 16]}>
                  <Col span={12}>
                    <Statistic 
                      title="Status" 
                      value={selectedSnowEdgeRequest.status.charAt(0).toUpperCase() + selectedSnowEdgeRequest.status.slice(1)}
                      valueStyle={{ 
                        color: selectedSnowEdgeRequest.status === 'submitted' ? '#faad14' : '#52c41a'
                      }}
                    />
                  </Col>
                </Row>

                <Divider style={{ margin: '12px 0' }} />

                <Space direction="vertical" size="small">
                  <div>
                    <CalendarOutlined style={{ marginRight: 8 }} />
                    <Text strong>Created:</Text> {selectedSnowEdgeRequest.created_at ? new Date(selectedSnowEdgeRequest.created_at).toLocaleDateString() : 'No Date'}
                  </div>
                  <div>
                    <CalendarOutlined style={{ marginRight: 8 }} />
                    <Text strong>Requested Date:</Text> {selectedSnowEdgeRequest.requested_date ? new Date(selectedSnowEdgeRequest.requested_date).toLocaleDateString() : 'Not specified'}
                  </div>
                </Space>

                <Divider style={{ margin: '12px 0' }} />

                <Space direction="vertical" size="small">
                  <div>
                    <EnvironmentOutlined style={{ marginRight: 8 }} />
                    <Text strong>Site:</Text> {selectedSnowEdgeRequest.site && selectedSnowEdgeRequest.site.site_name ? selectedSnowEdgeRequest.site.site_name : 'Unassigned'}
                  </div>
                  {selectedSnowEdgeRequest.site && (
                    <div>
                      <Text type="secondary" style={{ marginLeft: 24 }}>
                        {selectedSnowEdgeRequest.site.address || 'No address specified'}
                      </Text>
                    </div>
                  )}
                </Space>

                {selectedSnowEdgeRequest.notes && (
                  <>
                    <Divider style={{ margin: '12px 0' }} />
                    <Card 
                      size="small" 
                      title={<><InfoCircleOutlined /> Notes</>} 
                      style={{ marginTop: '8px' }}
                    >
                      <p style={{ margin: 0 }}>{selectedSnowEdgeRequest.notes}</p>
                    </Card>
                  </>
                )}
              </Space>
            </div>
          )}
        </Modal>
      </div>
    </Spin>
  );
};

export default ClientRequestsPortal;
