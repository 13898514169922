// src/components/Profile/InviteLink.js

import { Box, Typography, IconButton, Button, Tooltip } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { styled } from '@mui/material/styles';

const StyledDialog = styled(Dialog)({
    '& .MuiDialog-paper': {
      borderRadius: '12px',
      boxShadow: 'rgba(145, 158, 171, 0.24) 0px 0px 2px 0px, rgba(145, 158, 171, 0.24) -20px 20px 40px -4px',
      maxWidth: '600px',
      width: '100%',
    },
  });
  
  const StyledDialogTitle = styled(DialogTitle)({
    padding: '24px',
    paddingBottom: '0',
    '& .MuiTypography-root': {
      fontSize: '1.5rem',
      fontWeight: 700,
      color: '#212B36',
    },
  });
  
  const StyledDialogContent = styled(DialogContent)({
    padding: '24px',
    paddingTop: '16px',
  });
  
  const LinkContainer = styled(Box)({
    display: 'flex',
    alignItems: 'center',
    gap: '12px',
    backgroundColor: '#F4F6F8',
    padding: '16px',
    borderRadius: '8px',
    border: '1px solid #E0E3E7',
    marginTop: '16px',
  });
  
  const LinkText = styled(Typography)({
    flexGrow: 1,
    wordBreak: 'break-all',
    fontFamily: 'monospace',
    fontSize: '0.875rem',
    color: '#212B36',
  });
  
  const CopyButton = styled(IconButton)({
    backgroundColor: '#fff',
    border: '1px solid #E0E3E7',
    '&:hover': {
      backgroundColor: '#F4F6F8',
    },
  });
  
  const DialogDescription = styled(Typography)({
    color: '#637381',
    fontSize: '1rem',
    marginTop: '8px',
  });
  
  const StyledDialogActions = styled(DialogActions)({
    padding: '24px',
    paddingTop: '0',
  });
  
  const CloseButton = styled(Button)({
    backgroundColor: '#004630',
    color: '#fff',
    padding: '8px 22px',
    '&:hover': {
      backgroundColor: '#1e926d',
    },
  });

const InviteLink = ({ inviteLink, open, onClose, createNotification }) => {
  const handleCopyLink = async () => {
    try {
        await navigator.clipboard.writeText(inviteLink);
        createNotification('Link copied to clipboard', 'success');
    } catch (err) {
        createNotification('Failed to copy link', 'error');
    }
  };

  return (
    <StyledDialog 
      open={open} 
      onClose={onClose}
    >
      <StyledDialogTitle>
        Invitation Link
      </StyledDialogTitle>
      <StyledDialogContent>
        <DialogDescription>
          Share this link with the invited user if they don't receive the email:
        </DialogDescription>
        <LinkContainer>
          <LinkText>
            {inviteLink}
          </LinkText>
          <Tooltip 
            title="Copy link" 
            placement="top"
            arrow
          >
            <CopyButton onClick={handleCopyLink}>
              <ContentCopyIcon sx={{ fontSize: 20 }} />
            </CopyButton>
          </Tooltip>
        </LinkContainer>
      </StyledDialogContent>
      <StyledDialogActions>
        <CloseButton 
          onClick={onClose}
          variant="contained"
        >
          Close
        </CloseButton>
      </StyledDialogActions>
    </StyledDialog>
  );
};

export default InviteLink;