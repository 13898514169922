import React, { useState, useEffect } from 'react';
import { Table, Button, Input, Select, message, Modal, Form, AutoComplete, InputNumber, Typography, Switch, DatePicker, Menu, Dropdown, Checkbox } from 'antd';
import { Box, Paper } from '@mui/material';
import { DownloadOutlined, PlusOutlined, SearchOutlined, FileTextOutlined, EditOutlined, InfoCircleOutlined, DownOutlined, MailOutlined} from '@ant-design/icons';
import api from "../authentication/axiosInstance";
import debounce from 'lodash/debounce';
import moment from 'moment';
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from 'react-places-autocomplete';
import { saveAs } from 'file-saver';
import { generateSiteDetailsPDF } from '../utils/pdfUtils';
import { IconFile} from '@tabler/icons-react';
import styles from './SitesListView.module.css';

const { Option } = Select;
const { Title } = Typography;

const formatNumber = (num) => {
  return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

const SitesListView = () => {
  const [sites, setSites] = useState([]);
  const [filteredSites, setFilteredSites] = useState([]);
  const [clients, setClients] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [form] = Form.useForm();
  const [addressOptions, setAddressOptions] = useState([]);
  const [viewMode, setViewMode] = useState('default');
  const [isEditModalVisible, setIsEditModalVisible] = useState(false);
  const [editingSite, setEditingSite] = useState(null);
  const [editForm] = Form.useForm();
  const [isClientDetailsModalVisible, setIsClientDetailsModalVisible] = useState(false);
  const [selectedSite, setSelectedSite] = useState(null);

  const [searchRegionFilter, setSearchRegionFilter] = useState(null);
  const [searchStatusFilter, setSearchStatusFilter] = useState(null);
  const [searchClientFilter, setSearchClientFilter] = useState('');

  const [staffList, setStaffList] = useState([]);

  const fetchStaff = async () => {
    try {
      const response = await api.get('/staff/');
      setStaffList(response.data);
    } catch (error) {
      console.error('Failed to fetch staff:', error);
      message.error('Failed to fetch staff');
    }
  };

  const fetchSites = async () => {
    try {
      const response = await api.get('/sites/');
      setSites(response.data);
      setFilteredSites(response.data);
    } catch (error) {
      console.error('Failed to fetch sites:', error);
      message.error('Failed to fetch sites');
    }
  };

  const fetchClients = async () => {
    try {
      const response = await api.get('/clients/');
      setClients(response.data);
      console.log(clients);
    } catch (error) {
      console.error('Failed to fetch clients:', error);
      message.error('Failed to fetch clients');
    }
  };

  useEffect(() => {
    fetchSites();
    fetchClients();
    fetchStaff();
  }, []);

  useEffect(() => {
    applyFilters();
  }, [searchText, searchRegionFilter, searchStatusFilter, searchClientFilter]);


  
  const applyFilters = () => {
    const filtered = sites.filter((site) => {
      const filterText = site.site_name.toLowerCase().includes(searchText.toLowerCase());
      const filterSiteRegion = searchRegionFilter ? site.region === searchRegionFilter : true;
      const filterSiteStatus = searchStatusFilter ? site.contract_status === searchStatusFilter : true;
      const filterTextClient = site.client_name.toLowerCase().includes(searchClientFilter.toLowerCase());
      return filterText && filterSiteRegion && filterSiteStatus && filterTextClient;
    });
    setFilteredSites(filtered);
  };

  const handleCheckboxChange = (record, field, newValue) => {
    Modal.confirm({
      title: `Are you sure you want to make this change?`,
      onOk: async () => {
        try {
          const updatedData = { [field]: newValue };
  
          const response = await api.patch(`/sites/${record.site_id}/`, updatedData);
          fetchSites();
          if (response.status === 200 || response.status === 204) {
            message.success(`Field updated successfully`);
  
            setSites((prevSites) =>
              prevSites.map((site) =>
                site.site_id === record.site_id ? { ...site, [field]: newValue } : site
              )
            );
          } else {
            console.error('Unexpected response:', response);
            message.error(`Failed to update ${field.replace('_', ' ')}`);
          }
        } catch (error) {
          console.error('Update error:', error);
          message.error(`Failed to update ${field.replace('_', ' ')}`);
        }
      },
      onCancel() {
        message.info('No changes were made');
      },
    });
  };

  const defaultColumns = [
    {
      render: (text, record) => (
        <span>
          {text}
          <Button
            type="text"
            icon={<InfoCircleOutlined />}
            onClick={() => showClientDetailsModal(record)}
            style={{ marginLeft: 8, color: '#cdaf5e' }}
          />
        </span>
      ),
      title: 'Client Name',
      dataIndex: 'client_name',
      key: 'client_name',
      width: 200,
      sorter: (a, b) => a.client_name.localeCompare(b.client_name),
    },
    {
      title: 'Site Name',
      dataIndex: 'site_name',
      key: 'site_name',
      width: 200,
      sorter: (a, b) => a.site_name.localeCompare(b.site_name),
    },
    {
      title: 'Contract Status',
      dataIndex: 'contract_status',
      key: 'contract_status',
      width: 200,
      render: (status, record) => (
        <Select
          value={status}
          style={{ width: 140 }}
          onChange={(value) => handleContractStatusChange(record.site_id, value)}
        >
          <Option value="Estimate">Estimate</Option>
          <Option value="Unconfirmed">Unconfirmed</Option>
          <Option value="Confirmed">Confirmed</Option>
        </Select>
      ),
    },
    {
      title: 'Territory Manager',
      dataIndex: 'assigned_territory_manager',
      key: 'assigned_territory_manager',
      width:200,
    },
    {
      title: 'Site Supervisor',
      dataIndex: 'assigned_site_supervisor',
      key: 'assigned_site_supervisor',
      width:200,
    },
    {
      title: 'Snow Edge',
      dataIndex: 'is_snow_edge',
      key: 'is_snow_edge',
      render: (isSnowEdge, record) => (
        <Checkbox
          checked={isSnowEdge}
          onChange={() => handleCheckboxChange(record, 'is_snow_edge', !isSnowEdge)}
        />
      ),
    },
    {
      title: 'Generate Invoice',
      dataIndex: 'invoices_generated',
      key: 'invoices_generated',
      width:300,
      render: (generated, record) => (
        <Button
          onClick={() => handleGenerateInvoices(record.site_id)}
          type="primary"
          icon={<FileTextOutlined />}
          style={{ 
            backgroundColor: generated ? '#d9d9d9' : '#cdaf5e', 
            borderColor: generated ? '#d9d9d9' : '#cdaf5e',
            color: generated ? 'rgba(0, 0, 0, 0.25)' : '#ffffff'
          }}
          disabled={generated}
        >
          Generate Invoice
        </Button>
      ),
    },
    {
      title: 'Actions',
      key: 'actions',
      width: 300,
      render: (site) => (
        <>
          <Button
            onClick={() => generateSiteDetailsPDF(site)}
            type="primary"
            icon={<DownloadOutlined />}
            style={{ backgroundColor: '#cdaf5e', borderColor: '#cdaf5e', marginRight: 8 }}
          >
            Download PDF
          </Button>
          <Button
            onClick={() => showEditModal(site)}
            type="primary"
            icon={<EditOutlined />}
            style={{ backgroundColor: '#cdaf5e', borderColor: '#cdaf5e' }}
            // disabled={isAllocationPresent(site)}
            // title={isAllocationPresent(site) ? "Deallocate vehicles and from walkway crews/salting routes before editing" : ""}
          >
            Edit
          </Button>
        </>
      ),
    },
  ];

  const contractDetailsColumns = [
    {
      title: 'Client Name',
      dataIndex: 'client_name',
      key: 'client_name',
      sorter: (a, b) => a.client_name.localeCompare(b.client_name),
    },
    {
      title: 'Site Name',
      dataIndex: 'site_name',
      key: 'site_name',
      sorter: (a, b) => a.site_name.localeCompare(b.site_name),
    },
    {
      title: 'Contract Status',
      dataIndex: 'contract_status',
      key: 'contract_status',
      render: (status, record) => (
        <Select
          value={status}
          style={{ width: 140 }}
          onChange={(value) => handleContractStatusChange(record.site_id, value)}
        >
          <Option value="Estimate">Estimate</Option>
          <Option value="Unconfirmed">Unconfirmed</Option>
          <Option value="Confirmed">Confirmed</Option>
        </Select>
      ),
    },
    {
      title: 'Base Revenue',
      dataIndex: 'base_revenue',
      key: 'base_revenue',
      render: (value) => `$${formatNumber(value)}`,
    },
    {
      title: 'Winter Start Date',
      dataIndex: 'winter_start_date',
      key: 'winter_start_date',
      render: (date) => date ? moment(date).format('YYYY-MM-DD') : 'N/A',
    },
    {
      title: 'Winter End Date',
      dataIndex: 'winter_end_date',
      key: 'winter_end_date',
      render: (date) => date ? moment(date).format('YYYY-MM-DD') : 'N/A',
    },
    {
      title: 'Contract Expiration',
      dataIndex: 'contract_expiration_date',
      key: 'contract_expiration_date',
      render: (date) => date ? moment(date).format('YYYY-MM-DD') : 'N/A',
    },
    {
      title: 'Monthly Rate (No HST)',
      dataIndex: 'monthly_rate',
      key: 'monthly_rate',
      render: (value) => `$${formatNumber(value)}`,
    },
    {
      title: 'Additional Revenue Type',
      dataIndex: 'additional_revenue_type',
      key: 'additional_revenue_type',
    },
    {
      title: 'Additional Revenue Amount',
      dataIndex: 'additional_revenue_amount',
      key: 'additional_revenue_amount',
      render: (value) => `$${formatNumber(value)}`,
    },
  ];

  const debouncedSearchSite = debounce((value) => {
    setSearchText(value);
  }, 300);

  const debouncedSearchClient = debounce((value) => {
    setSearchClientFilter(value);
  }, 300);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    form.resetFields();
  };

  const handleSubmit = async (values) => {
    try {
      const formattedValues = {
        ...values,
        client_name: clients.find((client) => client.client_id === values.client)?.client_name || null,
        parking_lots_sqft: parseFloat(values.parking_lots_sqft),
        walkway_sqft: parseFloat(values.walkway_sqft),
        latitude: parseFloat(parseFloat(values.latitude).toFixed(6)),
        longitude: parseFloat(parseFloat(values.longitude).toFixed(6)),
        contract_status: values.contract_status,
        base_revenue: parseFloat(values.base_revenue),
        winter_start_date: values.winter_start_date ? values.winter_start_date.format('YYYY-MM-DD') : null,
        winter_end_date: values.winter_end_date ? values.winter_end_date.format('YYYY-MM-DD') : null,
        contract_expiration_date: values.contract_expiration_date ? values.contract_expiration_date.format('YYYY-MM-DD') : null,
        monthly_rate: parseFloat(values.monthly_rate),
        additional_revenue_type: values.additional_revenue_type,
        additional_revenue_amount: parseFloat(values.additional_revenue_amount),
      };
      console.log('Formatted form values before submission:', formattedValues);
      const response = await api.post('/sites/', formattedValues);
      console.log('API response:', response.data);
      message.success('Site added successfully');
      setIsModalVisible(false);
      form.resetFields();
      fetchSites();
    } catch (error) {
      console.error('Failed to add site:', error);
      if (error.response) {
        console.error('Error response:', error.response.data);
        if (typeof error.response.data === 'object' && error.response.data !== null) {
          const errorMessages = Object.entries(error.response.data)
            .map(([key, value]) => `${key}: ${value}`)
            .join(', ');
          message.error(`Failed to add site: ${errorMessages}`);
        } else {
          message.error('Failed to add site. Please check your input and try again.');
        }
      } else if (error.request) {
        console.error('Error request:', error.request);
        message.error('Network error. Please check your connection and try again.');
      } else {
        message.error('An unexpected error occurred. Please try again.');
      }
    }
  };

/*   const handleAddressSearch = async (value) => {
    if (value) {
      try {
        const response = await fetch(`https://nominatim.openstreetmap.org/search?format=json&q=${encodeURIComponent(value)}`);
        const data = await response.json();
        const options = data.map(item => ({
          value: item.display_name,
          label: item.display_name,
          latitude: item.lat,
          longitude: item.lon,
        }));
        setAddressOptions(options);
      } catch (error) {
        console.error('Error fetching address suggestions:', error);
      }
    } else {
      setAddressOptions([]);
    }
  };

  const onAddressSelect = (value, option) => {
    form.setFieldsValue({
      address: value,
      latitude: option.latitude,
      longitude: option.longitude,
    });
  }; */

  
  const extractStreetAddress = (fullAddress) => {
    // Split the address by commas
    const parts = fullAddress.split(',');
    // Return the first part (usually the street address)
    return parts[0].trim();
  };

  const handleAddressSelect = async (selectedAddress) => {
    const streetAddress = extractStreetAddress(selectedAddress);
    form.setFieldsValue({ address: streetAddress });
    try {
      const geocode = await geocodeByAddress(selectedAddress);
      const { lat, lng } = await getLatLng(geocode[0]);
      form.setFieldsValue({
        latitude: lat.toFixed(6),
        longitude: lng.toFixed(6),
      });
    } catch (error) {
      console.error('Error', error);
      message.error('Failed to get latitude and longitude');
    }
  };

  const handleEditAddressSelect = async (selectedAddress) => {
    const streetAddress = extractStreetAddress(selectedAddress);
    editForm.setFieldsValue({ address: streetAddress });
    try {
      const geocode = await geocodeByAddress(selectedAddress);
      const { lat, lng } = await getLatLng(geocode[0]);
      editForm.setFieldsValue({
        latitude: lat.toFixed(6),
        longitude: lng.toFixed(6),
      });
    } catch (error) {
      console.error('Error', error);
      message.error('Failed to get latitude and longitude');
    }
  };

  const validateNumber = (rule, value) => {
    if (value === '' || value === undefined) {
      return Promise.reject('Please enter a value');
    }
    const num = parseFloat(value);
    if (isNaN(num)) {
      return Promise.reject('Please enter a valid number');
    }
    if (num < 0) {
      return Promise.reject('Please enter a positive number');
    }
    return Promise.resolve();
  };

  const handleContractStatusChange = async (siteId, newStatus) => {
    try {
      await api.patch(`/sites/${siteId}/`, { contract_status: newStatus });
      message.success('Contract status updated successfully');
      fetchSites(); 
    } catch (error) {
      console.error('Failed to update contract status:', error);
      message.error('Failed to update contract status');
    }
  };

  const handleGenerateInvoices = async (siteId) => {
    try {
      await api.post(`/sites/${siteId}/generate_invoice/`);
      message.success('Invoice generated successfully');
      fetchSites(); // Refresh the sites list to update the invoice status
    } catch (error) {
      console.error('Failed to generate invoice:', error);
      message.error('Failed to generate invoice');
    }
  };

  const generateManagementPDF = async () => {
    try {
      const response = await api.get('/sites/site_supervision_pdf/', { responseType: 'blob' });
      const blob = new Blob([response.data], { type: 'application/pdf' });
      saveAs(blob, 'site_supervision_report.pdf');
    } catch (error) {
      console.error('Failed to generate PDF:', error);
      message.error('Failed to generate PDF report');
    }
  };

  const generateManagerPDF = async (territoryManager) => {
    if (!territoryManager) {
      console.error('Territory manager is required');
      message.error('Please select a territory manager');
      return;
    }
  
    try {
      const response = await api.get(`/sites/site_supervision_pdf_for_manager/`, {
        params: { territory_manager: territoryManager },
        responseType: 'blob',
      });
  
      const blob = new Blob([response.data], { type: 'application/pdf' });
      saveAs(blob, `${territoryManager}_site_supervision_report.pdf`);
    } catch (error) {
      console.error('Failed to generate PDF:', error);
      message.error('Failed to generate PDF report for the selected territory manager');
    }
  };

  const showEditModal = (site) => {
    setEditingSite(site);
    editForm.setFieldsValue({
      ...site,
      winter_start_date: site.winter_start_date ? moment(site.winter_start_date) : null,
      winter_end_date: site.winter_end_date ? moment(site.winter_end_date) : null,
      contract_expiration_date: site.contract_expiration_date ? moment(site.contract_expiration_date) : null,
    });
    setIsEditModalVisible(true);
  };

  const handleEditCancel = () => {
    setIsEditModalVisible(false);
    setEditingSite(null);
    editForm.resetFields();
  };

  const handleEditSubmit = async (values) => {
    try {
      const formattedValues = {
        ...values,
        // client_name: clients.find((client) => client.client_id === values.client)?.client_name || null,
        parking_lots_sqft: parseFloat(values.parking_lots_sqft),
        walkway_sqft: parseFloat(values.walkway_sqft),
        base_revenue: parseFloat(values.base_revenue),
        winter_start_date: values.winter_start_date ? values.winter_start_date.format('YYYY-MM-DD') : null,
        winter_end_date: values.winter_end_date ? values.winter_end_date.format('YYYY-MM-DD') : null,
        contract_expiration_date: values.contract_expiration_date ? values.contract_expiration_date.format('YYYY-MM-DD') : null,
        monthly_rate: parseFloat(values.monthly_rate),
        additional_revenue_amount: parseFloat(values.additional_revenue_amount),
      };
      // console.log(formattedValues)
      await api.patch(`/sites/${editingSite.site_id}/`, formattedValues);
      message.success('Site updated successfully');
      setIsEditModalVisible(false);
      editForm.resetFields();
      fetchSites();
    } catch (error) {
      console.error('Failed to update site:', error);
      message.error('Failed to update site');
    }
  };

  const isAllocationPresent = (site) => {
    return site && (site.parking_lots_sqft_allocated > 0 || site.walkway_sqft_allocated > 0 || site.assigned_walkway_crew_bool || site.assigned_salting_route_bool);
  };

  const showClientDetailsModal = (site) => {
    setSelectedSite(site);
    setIsClientDetailsModalVisible(true);
  };

  const handleClientDetailsModalCancel = () => {
    setIsClientDetailsModalVisible(false);
    setSelectedSite(null);
  };

  const menu = (
    <Menu>
      <Menu.Item key="1" onClick={generateManagementPDF}>
        <IconFile size={16} style={{ marginRight: 8 }} />
        Generate Site Management PDF
      </Menu.Item>
      <Menu.Divider />
        <Menu.SubMenu key="2"       
          title={
            <span>
              <IconFile size={16} style={{ marginRight: 8 }} />
              Generate Territory Manager PDF
            </span>
          }>
          {Array.from(new Set(sites.map((site) => site.assigned_territory_manager)))
            .filter((manager) => manager) // Exclude null or undefined values
            .map((manager) => (
              <Menu.Item
                key={manager}
                onClick={() => {
                  generateManagerPDF(manager); // Call the PDF generation function
                }}
              >
                {manager}
              </Menu.Item>
            ))}
        </Menu.SubMenu>
      {/* Add more reports here when needed */}
    </Menu>
  );

  const handleExportCSV = async () => {
    try {
      const response = await api.get('/sites/export_csv/', {
        responseType: 'blob'
      });
      
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'sites_export.csv');
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (error) {
      message.error('Failed to export sites data');
      console.error('Export error:', error);
    }
  };

  const handleEmailTM = async () => {
    try {
      // Fetch preview data
      const previewResponse = await api.get('/sites/email_territory_managers/', {
        params: { preview_only: true },
      });
  
      if (previewResponse.status === 200) {
        const { emailed_managers, failed_emails } = previewResponse.data;
  
        // Construct the list of emails to show in the modal
        const emailList = emailed_managers && emailed_managers.length
          ? emailed_managers.join(', ')
          : "No managers available to email.";
  
        const failureList = failed_emails && failed_emails.length
          ? failed_emails.map((fail) => `${fail.manager} (${fail.reason})`).join(', ')
          : "No issues detected.";
  
        // Open the modal and display the preview
        Modal.confirm({
          title: "Are you sure?",
          content: (
            <div>
              <p>This action will email all Territory Managers with the latest reports. Do you want to proceed?</p>
              <p><b>Emails to be sent to:</b> {emailList}</p>
            </div>
          ),
          okText: "Yes, Proceed",
          cancelText: "Cancel",
          onOk: async () => {
            try {
              const finalResponse = await api.get('/sites/email_territory_managers/');
              if (finalResponse.status === 200) {
                const { emailed_managers } = finalResponse.data;
  
                const successMessage = emailed_managers.length
                  ? `Emails sent successfully to: ${emailed_managers.join(', ')}`
                  : "No emails were sent successfully.";
  
                if (emailed_managers.length) message.success(successMessage, 10);
              } else {
                message.error("Failed to send emails to Territory Managers.");
              }
            } catch (error) {
              console.error("Error sending emails to Territory Managers:", error);
              message.error("Failed to send emails. Please try again.");
            }
          },
          onCancel: () => {
            message.info("Action cancelled.");
          },
        });
      } else {
        message.error("Failed to fetch preview data. Please try again.");
      }
    } catch (error) {
      console.error("Error fetching preview data for Territory Managers:", error);
      message.error("An error occurred while fetching preview data. Please try again.");
    }
  };
  

  return (
    <Box sx={{ padding: 3 }}>
      <Paper elevation={3} sx={{ padding: 3, marginBottom: 3 }}>
        <Title level={2} style={{ marginBottom: 24 }}>Sites List</Title>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: 16 }}>
          <div style={{ display: 'flex' }}>
            <Button
              onClick={showModal}
              type="primary"
              icon={<PlusOutlined />}
              style={{ backgroundColor: '#cdaf5e', borderColor: '#cdaf5e' }}
            >
              Add New Site
            </Button>
            <Dropdown overlay={menu} trigger={['click']}>
              <Button className={styles.tableButton} style={{ fontSize: '14px', padding: '8px 15px', marginLeft: 8 }}>
                Download Reports <DownOutlined />
              </Button>
            </Dropdown>
            <Button
              onClick={handleExportCSV}
              type="primary"
              icon={<DownloadOutlined />}
              style={{ backgroundColor: '#cdaf5e', borderColor: '#cdaf5e', marginLeft: 8 }}
            >
              Export CSV
            </Button>
            <Button
              onClick={handleEmailTM}
              type="primary"
              icon={<MailOutlined />}
              style={{ backgroundColor: '#cdaf5e', borderColor: '#cdaf5e', marginLeft: 8 }}
            >
              Email Territory Managers
            </Button>
          </div>
          <div>
            <Switch
              checkedChildren="Contract Details"
              unCheckedChildren="Default View"
              onChange={(checked) => setViewMode(checked ? 'contract' : 'default')}
              style={{ 
                marginRight: 16,
                backgroundColor: '#cdaf5e',
                '&.ant-switch-checked': {
                  backgroundColor: '#cdaf5e',
                }
              }}
            />
            <Input.Search
              placeholder="Search site name"
              onSearch={setSearchText}
              onChange={(e) => debouncedSearchSite(e.target.value)}
              style={{ width: 200, marginRight: 8 }}
              prefix={<SearchOutlined />}
            />
            <Input.Search
              placeholder="Search client name"
              onSearch={setSearchClientFilter}
              onChange={(e) => debouncedSearchClient(e.target.value)}
              style={{ width: 200, marginRight: 8 }}
              prefix={<SearchOutlined />}
            />
            <Select
              placeholder="Region"
              value={searchRegionFilter}
              onChange={(value) => setSearchRegionFilter(value)}
              style={{ width: 200, marginRight: 8 }}
              allowClear
            >
              {Array.from(new Set(sites.map((site) => site.region)))
                .map((type) => (
                  <Option key={type} value={type}>
                    {type}
                  </Option>
              ))}
            </Select>
            <Select
              placeholder="Contract Status"
              value={searchStatusFilter}
              onChange={(value) => setSearchStatusFilter(value)}
              style={{ width: 200, marginRight: 8 }}
              allowClear
            >
              {Array.from(new Set(sites.map((site) => site.contract_status)))
                .map((type) => (
                  <Option key={type} value={type}>
                    {type}
                  </Option>
              ))}
            </Select>
          </div>
        </div>
      </Paper>
      <Paper elevation={3} sx={{ padding: 3 }}>
        <Table
          dataSource={filteredSites}
          columns={viewMode === 'default' ? defaultColumns : contractDetailsColumns}
          rowKey="site_id"
          pagination={false} // Disable pagination
          scroll={{ y: 640 }} 
          style={{ marginTop: 16 }}
        />
      </Paper>

      <Modal
        title="Add New Site"
        open={isModalVisible}
        onCancel={handleCancel}
        footer={null}
        width={800} // Increased width for two-column layout
      >
        <Form form={form} onFinish={handleSubmit} layout="vertical">
          <div style={{ display: 'flex', flexWrap: 'wrap', gap: '16px' }}>
            <Form.Item name="site_name" label="Site Name" rules={[{ required: true, message: 'Please enter the site name' }]} style={{ flex: '1 1 calc(50% - 8px)' }}>
              <Input />
            </Form.Item>
            <Form.Item
              name="client"
              label="Client"
              rules={[{ required: true, message: 'Please select a client' }]}
              style={{ flex: '1 1 calc(50% - 8px)' }}
            >
              <Select
                showSearch
                allowClear
                placeholder="Select a client"
                options={clients.map(({ client_id, client_name }) => ({
                  value: client_id,
                  label: client_name,
                }))}
                filterOption={(input, option) =>
                  option.label.toLowerCase().includes(input.toLowerCase())
                }
              />
            </Form.Item>
            <Form.Item
            name="address"
            label="Address"
            rules={[{ required: true, message: 'Please enter the address' }]}
            style={{ flex: '1 1 calc(50% - 8px)' }}
          >
            <PlacesAutocomplete
              value={form.getFieldValue('address')}
              onChange={(value) => form.setFieldsValue({ address: value })}
              onSelect={handleAddressSelect}
            >
              {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                <div>
                  <Input
                    {...getInputProps({
                      placeholder: 'Enter address',
                    })}
                  />
                  <div className="autocomplete-dropdown-container">
                    {loading && <div>Loading...</div>}
                    {suggestions.map((suggestion) => {
                      const className = suggestion.active
                        ? 'suggestion-item--active'
                        : 'suggestion-item';
                      const style = suggestion.active
                        ? { backgroundColor: '#fafafa', cursor: 'pointer', padding: '10px' }
                        : { backgroundColor: '#ffffff', cursor: 'pointer', padding: '10px' };
                      return (
                        <div
                          {...getSuggestionItemProps(suggestion, {
                            className,
                            style,
                          })}
                          key={suggestion.placeId}
                        >
                          <span>{suggestion.description}</span>
                        </div>
                      );
                    })}
                  </div>
                </div>
              )}
              </PlacesAutocomplete>
            </Form.Item>
            <Form.Item name="region" label="Region" rules={[{ required: true, message: 'Please enter the region' }]} style={{ flex: '1 1 calc(50% - 8px)' }}>
              <Input />
            </Form.Item>
            <Form.Item 
              name="parking_lots_sqft" 
              label="Parking Lots Sqft" 
              rules={[
                { required: true, message: 'Please enter parking lots square footage' },
                { validator: validateNumber }
              ]}
              style={{ flex: '1 1 calc(50% - 8px)' }}
            >
              <InputNumber style={{ width: '100%' }} min={0} step={1} />
            </Form.Item>
            <Form.Item 
              name="walkway_sqft" 
              label="Walkway Sqft" 
              rules={[
                { required: true, message: 'Please enter walkway square footage' },
                { validator: validateNumber }
              ]}
              style={{ flex: '1 1 calc(50% - 8px)' }}
            >
              <InputNumber style={{ width: '100%' }} min={0} step={1} />
            </Form.Item>
            <Form.Item name="contract_status" label="Contract Status" rules={[{ required: true, message: 'Please select the contract status' }]} style={{ flex: '1 1 calc(50% - 8px)' }}>
              <Select placeholder="Select contract status">
                <Option value="Estimate">Estimate</Option>
                <Option value="Unconfirmed">Unconfirmed</Option>
                <Option value="Confirmed">Confirmed</Option>
              </Select>
            </Form.Item>
            <Form.Item name="base_revenue" label="Base Revenue" style={{ flex: '1 1 calc(50% - 8px)' }}>
              <InputNumber style={{ width: '100%' }} min={0} step={0.01} formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')} parser={value => value.replace(/\$\s?|(,*)/g, '')} />
            </Form.Item>
            <Form.Item name="winter_start_date" label="Winter Start Date" style={{ flex: '1 1 calc(50% - 8px)' }}>
              <DatePicker style={{ width: '100%' }} />
            </Form.Item>
            <Form.Item name="winter_end_date" label="Winter End Date" style={{ flex: '1 1 calc(50% - 8px)' }}>
              <DatePicker style={{ width: '100%' }} />
            </Form.Item>
            <Form.Item name="contract_expiration_date" label="Contract Expiration Date" style={{ flex: '1 1 calc(50% - 8px)' }}>
              <DatePicker style={{ width: '100%' }} />
            </Form.Item>
            <Form.Item name="monthly_rate" label="Monthly Rate (No HST)" style={{ flex: '1 1 calc(50% - 8px)' }}>
              <InputNumber style={{ width: '100%' }} min={0} step={0.01} formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')} parser={value => value.replace(/\$\s?|(,*)/g, '')} />
            </Form.Item>
            <Form.Item name="additional_revenue_type" label="Additional Revenue Type" style={{ flex: '1 1 calc(50% - 8px)' }}>
              <Input />
            </Form.Item>
            <Form.Item name="additional_revenue_amount" label="Additional Revenue Amount" style={{ flex: '1 1 calc(50% - 8px)' }}>
              <InputNumber style={{ width: '100%' }} min={0} step={0.01} formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')} parser={value => value.replace(/\$\s?|(,*)/g, '')} />
            </Form.Item>
          </div>
          <Form.Item name="latitude" hidden={true}>
            <Input />
          </Form.Item>
          <Form.Item name="longitude" hidden={true}>
            <Input />
          </Form.Item>
          <Form.Item name="assigned_territory_manager" label="Territory Manager" style={{ flex: '1 1 calc(50% - 8px)' }}>
            <Select
              showSearch
              allowClear
              onChange={(value) => editForm.setFieldsValue({ assigned_territory_manager: value || null })} // Set to null when cleared
              options={staffList.map((staff) => ({ value: staff.name }))}
              placeholder="Select Territory Manager"
              filterOption={(inputValue, option) =>
                option.value.toLowerCase().includes(inputValue.toLowerCase())
              }
            />
          </Form.Item>
          <Form.Item name="assigned_site_supervisor" label="Site Supervisor" style={{ flex: '1 1 calc(50% - 8px)' }}>
            <Select
              showSearch
              allowClear
              onChange={(value) => editForm.setFieldsValue({ assigned_site_supervisor: value || null })} // Set to null when cleared
              options={staffList.map((staff) => ({ value: staff.name }))}
              placeholder="Select Site Supervisor"
              filterOption={(inputValue, option) =>
                option.value.toLowerCase().includes(inputValue.toLowerCase())
              }
            />
          </Form.Item>
          <Form.Item name="dispatch_notes" label="Dispatch Notes" style={{ flex: '1 1 calc(50% - 8px)' }}>
            <Input.TextArea rows={4} />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit" style={{ backgroundColor: '#cdaf5e', borderColor: '#cdaf5e' }}>
              Add Site
            </Button>
          </Form.Item>
        </Form>
      </Modal>

      {/* Edit Site Modal */}
      <Modal
        title="Edit Site"
        open={isEditModalVisible}
        onCancel={handleEditCancel}
        footer={null}
        width={800}
      >
        <Form form={editForm} onFinish={handleEditSubmit} layout="vertical">
          <div style={{ display: 'flex', flexWrap: 'wrap', gap: '16px' }}>
            <Form.Item name="site_name" label="Site Name" rules={[{ required: true, message: 'Please enter the site name' }]} style={{ flex: '1 1 calc(50% - 8px)' }}>
              <Input />
            </Form.Item>
            <Form.Item
              name="client"
              label="Client"
              rules={[{ required: false, message: 'Please select a client' }]}
              style={{ flex: '1 1 calc(50% - 8px)' }}
            >
              <Select
                disabled
                placeholder="Select a client"
                options={clients.map(({ client_id, client_name }) => ({
                  value: client_id,
                  label: client_name,
                }))}
                filterOption={(input, option) =>
                  option.label.toLowerCase().includes(input.toLowerCase())
                }
              />
            </Form.Item>
            <Form.Item
            name="address"
            label="Address"
            rules={[{ required: true, message: 'Please enter the address' }]}
            style={{ flex: '1 1 calc(50% - 8px)' }}
          >
            <PlacesAutocomplete
              value={editForm.getFieldValue('address')}
              onChange={(value) => editForm.setFieldsValue({ address: value })}
              onSelect={handleEditAddressSelect}
            >
              {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                <div>
                  <Input
                    {...getInputProps({
                      placeholder: 'Enter address',
                    })}
                  />
                  <div className="autocomplete-dropdown-container">
                    {loading && <div>Loading...</div>}
                    {suggestions.map((suggestion) => {
                      const className = suggestion.active
                        ? 'suggestion-item--active'
                        : 'suggestion-item';
                      const style = suggestion.active
                        ? { backgroundColor: '#fafafa', cursor: 'pointer', padding: '10px' }
                        : { backgroundColor: '#ffffff', cursor: 'pointer', padding: '10px' };
                      return (
                        <div
                          {...getSuggestionItemProps(suggestion, {
                            className,
                            style,
                          })}
                          key={suggestion.placeId}
                        >
                          <span>{suggestion.description}</span>
                        </div>
                      );
                    })}
                  </div>
                </div>
              )}
            </PlacesAutocomplete>
          </Form.Item>
            <Form.Item name="region" label="Region" rules={[{ required: true, message: 'Please enter the region' }]} style={{ flex: '1 1 calc(50% - 8px)' }}>
              <Input />
            </Form.Item>
            <Form.Item 
              name="parking_lots_sqft" 
              label="Parking Lots Sqft" 
              rules={[
                { required: true, message: 'Please enter parking lots square footage' },
                { validator: validateNumber }
              ]}
              style={{ flex: '1 1 calc(50% - 8px)' }}
            >
              <InputNumber style={{ width: '100%' }} min={0} step={1} disabled={isAllocationPresent(editingSite)} 
              title={isAllocationPresent(editingSite) ? "Ensure site deallocations before editing" : ""}/>
            </Form.Item>
            <Form.Item 
              name="walkway_sqft" 
              label="Walkway Sqft" 
              rules={[
                { required: true, message: 'Please enter walkway square footage' },
                { validator: validateNumber }
              ]}
              style={{ flex: '1 1 calc(50% - 8px)' }}
            >
              <InputNumber style={{ width: '100%' }} min={0} step={1} disabled={isAllocationPresent(editingSite)} 
              title={isAllocationPresent(editingSite) ? "Ensure site deallocations before editing" : ""}/>
            </Form.Item>
            <Form.Item name="contract_status" label="Contract Status" rules={[{ required: true, message: 'Please select the contract status' }]} style={{ flex: '1 1 calc(50% - 8px)' }}>
              <Select placeholder="Select contract status">
                <Option value="Estimate">Estimate</Option>
                <Option value="Unconfirmed">Unconfirmed</Option>
                <Option value="Confirmed">Confirmed</Option>
              </Select>
            </Form.Item>
            <Form.Item name="base_revenue" label="Base Revenue" style={{ flex: '1 1 calc(50% - 8px)' }}>
              <InputNumber style={{ width: '100%' }} min={0} step={0.01} formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')} parser={value => value.replace(/\$\s?|(,*)/g, '')} />
            </Form.Item>
            <Form.Item name="winter_start_date" label="Winter Start Date" style={{ flex: '1 1 calc(50% - 8px)' }}>
              <DatePicker style={{ width: '100%' }} />
            </Form.Item>
            <Form.Item name="winter_end_date" label="Winter End Date" style={{ flex: '1 1 calc(50% - 8px)' }}>
              <DatePicker style={{ width: '100%' }} />
            </Form.Item>
            <Form.Item name="contract_expiration_date" label="Contract Expiration Date" style={{ flex: '1 1 calc(50% - 8px)' }}>
              <DatePicker style={{ width: '100%' }} />
            </Form.Item>
            <Form.Item name="monthly_rate" label="Monthly Rate (No HST)" style={{ flex: '1 1 calc(50% - 8px)' }}>
              <InputNumber style={{ width: '100%' }} min={0} step={0.01} formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')} parser={value => value.replace(/\$\s?|(,*)/g, '')} />
            </Form.Item>
            <Form.Item name="additional_revenue_type" label="Additional Revenue Type" style={{ flex: '1 1 calc(50% - 8px)' }}>
              <Input />
            </Form.Item>
            <Form.Item name="additional_revenue_amount" label="Additional Revenue Amount" style={{ flex: '1 1 calc(50% - 8px)' }}>
              <InputNumber style={{ width: '100%' }} min={0} step={0.01} formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')} parser={value => value.replace(/\$\s?|(,*)/g, '')} />
            </Form.Item>
            <Form.Item name="assigned_territory_manager" label="Territory Manager" style={{ flex: '1 1 calc(50% - 8px)' }}>
              <Select
                showSearch
                allowClear
                onChange={(value) => editForm.setFieldsValue({ assigned_territory_manager: value || null })} // Set to null when cleared
                options={staffList.map((staff) => ({ value: staff.name }))}
                placeholder="Select Territory Manager"
                filterOption={(inputValue, option) =>
                  option.value.toLowerCase().includes(inputValue.toLowerCase())
                }
              />
            </Form.Item>
            <Form.Item name="assigned_site_supervisor" label="Site Supervisor" style={{ flex: '1 1 calc(50% - 8px)' }}>
              <Select
                showSearch
                allowClear
                onChange={(value) => editForm.setFieldsValue({ assigned_site_supervisor: value || null })} // Set to null when cleared
                options={staffList.map((staff) => ({ value: staff.name }))}
                placeholder="Select Site Supervisor"
                filterOption={(inputValue, option) =>
                  option.value.toLowerCase().includes(inputValue.toLowerCase())
                }
              />
            </Form.Item>
            <Form.Item name="dispatch_notes" label="Dispatch Notes" style={{ flex: '1 1 calc(50% - 8px)' }}>
              <Input.TextArea rows={4} />
            </Form.Item>
          </div>
          <Form.Item>
            <Button type="primary" htmlType="submit" style={{ backgroundColor: '#cdaf5e', borderColor: '#cdaf5e' }}>
              Update Site
            </Button>
          </Form.Item>
        </Form>
      </Modal>

      {/* Client Details Modal */}
      <Modal
        title="Client Details"
        visible={isClientDetailsModalVisible}
        onCancel={handleClientDetailsModalCancel}
        footer={null}
        width={600}
      >
        {selectedSite && (
          <div>
            <p><strong>Client Name:</strong> {selectedSite.client_name}</p>
            <p><strong>Site Name:</strong> {selectedSite.site_name}</p>
            <p><strong>Address:</strong> {selectedSite.address}</p>
            <p><strong>Region:</strong> {selectedSite.region}</p>
            <p><strong>Contract Status:</strong> {selectedSite.contract_status}</p>
            <p><strong>Base Revenue:</strong> ${formatNumber(selectedSite.base_revenue)}</p>
            <p><strong>Winter Start Date:</strong> {selectedSite.winter_start_date ? moment(selectedSite.winter_start_date).format('YYYY-MM-DD') : 'N/A'}</p>
            <p><strong>Winter End Date:</strong> {selectedSite.winter_end_date ? moment(selectedSite.winter_end_date).format('YYYY-MM-DD') : 'N/A'}</p>
            <p><strong>Contract Expiration:</strong> {selectedSite.contract_expiration_date ? moment(selectedSite.contract_expiration_date).format('YYYY-MM-DD') : 'N/A'}</p>
            <p><strong>Monthly Rate (No HST):</strong> ${formatNumber(selectedSite.monthly_rate)}</p>
            <p><strong>Additional Revenue Type:</strong> {selectedSite.additional_revenue_type || 'N/A'}</p>
            <p><strong>Additional Revenue Amount:</strong> ${formatNumber(selectedSite.additional_revenue_amount)}</p>
          </div>
        )}
      </Modal>
    </Box>
  );
};

export default SitesListView;
