// src/components/Profile/InviteTab.js

import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import {
  Box,
  Typography,
  TextField,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Checkbox,
  ListItemText,
  Switch,
  Button,
} from '@mui/material';

import styles from './Profile.module.css';
import api from '../../authentication/axiosInstance';

import InviteLink from './InviteLink';

const InviteTab = ({ clients, createNotification }) => {
  const [inviteEmail, setInviteEmail] = useState('');
  const [inviteRole, setInviteRole] = useState('standard');
  const [selectedClient, setSelectedClient] = useState(null);
  const [clientSites, setClientSites] = useState([]);
  const [accessibleSites, setAccessibleSites] = useState([]);
  const [hasSnowEdgeAccess, setHasSnowEdgeAccess] = useState(false);
  const [hasRegularRequestAccess, setHasRegularRequestAccess] = useState(true);
  const [inviteLink, setInviteLink] = useState('');
  const [inviteLinkModalOpen, setInviteLinkModalOpen] = useState(false);

  useEffect(() => {
    const fetchSites = async () => {
      if (selectedClient) {
        try {
          const response = await api.get(`/clients/${selectedClient}/sites/`);
          setClientSites(response.data);
        } catch (error) {
          console.error('Error fetching sites:', error);
        }
      } else {
        setClientSites([]);
      }
    };

    fetchSites();
  }, [selectedClient]);

  const handleInvite = async (event) => {
    event.preventDefault();
    const inviteData = { 
      email: inviteEmail, 
      role: inviteRole,
      has_snowedge_access: hasSnowEdgeAccess,
      has_regular_request_access: hasRegularRequestAccess,
    };
    console.log('Sending invite data:', inviteData);
    if (inviteRole === 'client') {
      inviteData.client = selectedClient;
      inviteData.accessible_sites = accessibleSites;
    }

    console.log("Sending invitation data:", inviteData);

    try {
      const response = await api.post('/users/invite/', inviteData);
      setInviteLink(response.data.invite_url);
      setInviteLinkModalOpen(true);
      createNotification('Invitation sent successfully', 'success');
    } catch (error) {
      console.error('Invitation error:', error.response?.data || error.message);
      createNotification(error.response?.data?.error || 'Failed to send invitation', 'error');
    } finally {
      setInviteEmail('');
      setInviteRole('user');
      setSelectedClient(null);
      setAccessibleSites([]);
    }
  };

  const handleCloseInviteLinkModal = () => {
    setInviteLinkModalOpen(false);
    setInviteLink('');
  };

  return (
    <>
      <motion.div 
        className={styles.tabContentInner} 
        initial={{ opacity: 0, translateY: 20 }}
        animate={{ opacity: 1, translateY: 0 }}
        transition={{
          type: "spring",
          stiffness: 150,
          damping: 30,
          delay: 0.2
        }}>
        <Box className={styles.titleContainer}>
          <Typography className={styles.title}>
            Invite User
          </Typography>
          <Typography className={styles.subtitle}>
            Send an invitation to join your organization.
          </Typography>
        </Box>
        <Box className={styles.lineBreak} />
        <Box className={styles.sectionContainer}>
          <Box className={styles.sectionContainerLeft}>
            <Typography className={styles.sectionTitle}>
              Email Address
            </Typography>
            <Typography className={styles.sectionSubtitle}>
              Where should we send the invitation?
            </Typography>
          </Box>
          <Box className={styles.sectionContainerRight}>
            <TextField
              label="Email Address"
              id="inviteEmail"
              value={inviteEmail}
              onChange={(e) => setInviteEmail(e.target.value)}
              placeholder="Email Address"
              fullWidth
            />
          </Box>
        </Box>
        <Box className={styles.lineBreak} />
        <Box className={styles.sectionContainer}>
          <Box className={styles.sectionContainerLeft}>
            <Typography className={styles.sectionTitle}>
              User Role
            </Typography>
            <Typography className={styles.sectionSubtitle}>
              What permissions should this user have?
            </Typography>
          </Box>
          <Box className={styles.sectionContainerRight}>
            <FormControl fullWidth>
              <InputLabel id="inviteRoleLabel">User Role</InputLabel>
              <Select
                labelId="inviteRoleLabel"
                id="inviteRole"
                value={inviteRole}
                onChange={(e) => setInviteRole(e.target.value)}
                variant="outlined"
                style={{ marginTop: '10px' }}
              >
                <MenuItem value="standard">Standard</MenuItem>
                <MenuItem value="admin">Admin</MenuItem>
                <MenuItem value="client">Client</MenuItem>
              </Select>
            </FormControl>
          </Box>
        </Box>

        {inviteRole === 'client' && (
          <>
            <Box className={styles.lineBreak} />
            <Box className={styles.sectionContainer}>
              <Box className={styles.sectionContainerLeft}>
                <Typography className={styles.sectionTitle}>
                  Select Client
                </Typography>
                <Typography className={styles.sectionSubtitle}>
                  Which client is this user associated with?
                </Typography>
              </Box>
              <Box className={styles.sectionContainerRight}>
                <FormControl fullWidth>
                  <InputLabel id="selectClientLabel">Client</InputLabel>
                  <Select
                    labelId="selectClientLabel"
                    id="selectClient"
                    value={selectedClient}
                    onChange={(e) => setSelectedClient(e.target.value)}
                    variant="outlined"
                    style={{ marginTop: '10px' }}
                  >
                    {clients.map((client) => (
                      <MenuItem key={client.client_id} value={client.client_id}>
                        {client.client_name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
            </Box>

            {selectedClient && (
              <>
                <Box className={styles.lineBreak} />
                <Box className={styles.sectionContainer}>
                  <Box className={styles.sectionContainerLeft}>
                    <Typography className={styles.sectionTitle}>
                      Select Accessible Sites
                    </Typography>
                    <Typography className={styles.sectionSubtitle}>
                      Assign specific sites this client user can access.
                    </Typography>
                  </Box>
                  <Box className={styles.sectionContainerRight}>
                    <FormControl fullWidth variant="outlined" style={{ marginTop: '10px' }}>
                      <InputLabel id="accessibleSitesLabel">Accessible Sites</InputLabel>
                      <Select
                        labelId="accessibleSitesLabel"
                        id="accessibleSites"
                        multiple
                        value={accessibleSites}
                        onChange={(e) => setAccessibleSites(e.target.value)}
                        renderValue={(selected) => selected.map(id => {
                          const site = clientSites.find(site => site.site_id === id);
                          return site ? site.site_name : id;
                        }).join(', ')}
                        label="Accessible Sites"
                      >
                        {clientSites.map((site) => (
                          <MenuItem key={site.site_id} value={site.site_id}>
                            <Checkbox checked={accessibleSites.indexOf(site.site_id) > -1} />
                            <ListItemText primary={site.site_name} />
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Box>
                </Box>
              </>
            )}
            <Box className={styles.lineBreak} />
            <Box className={styles.sectionContainer}>
              <Box className={styles.sectionContainerLeft}>
                <Typography className={styles.sectionTitle}>
                  SnowEdge Access
                </Typography>
                <Typography className={styles.sectionSubtitle}>
                  Allow this client to submit SnowEdge requests?
                </Typography>
              </Box>
              <Box className={styles.sectionContainerRight}>
                <FormControl>
                  <Switch
                    checked={hasSnowEdgeAccess}
                    onChange={(e) => setHasSnowEdgeAccess(e.target.checked)}
                  />
                </FormControl>
              </Box>
            </Box>
            
            <Box className={styles.lineBreak} />
            <Box className={styles.sectionContainer}>
              <Box className={styles.sectionContainerLeft}>
                <Typography className={styles.sectionTitle}>
                  Regular Request Access
                </Typography>
                <Typography className={styles.sectionSubtitle}>
                  Allow this client to submit regular equipment requests?
                </Typography>
              </Box>
              <Box className={styles.sectionContainerRight}>
                <FormControl>
                  <Switch
                    checked={hasRegularRequestAccess}
                    onChange={(e) => setHasRegularRequestAccess(e.target.checked)}
                  />
                </FormControl>
              </Box>
            </Box>
          </>
        )}

        <Box className={styles.lineBreak} />
        <Box className={styles.buttonContainer}>
          <Button variant="contained" color="primary" onClick={handleInvite}>
            Send Invitation
          </Button>
        </Box>
      </motion.div>

      <InviteLink
        inviteLink={inviteLink}
        open={inviteLinkModalOpen}
        onClose={handleCloseInviteLinkModal}
        createNotification={createNotification}
      />
    </>
  );
};

export default InviteTab;