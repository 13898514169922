// NotificationListView.js
import React, { useState, useEffect } from 'react';
import { Table, message, Input, Select, Form, Button, Modal, DatePicker, Checkbox, Menu, Dropdown} from 'antd';
import { Box } from '@mui/material';
import api from "../authentication/axiosInstance";
import { EditOutlined, DownOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { IconFile } from '@tabler/icons-react';
import timezone from 'dayjs/plugin/timezone';
import styles from './NotificationPage.module.css'

dayjs.extend(utc);
dayjs.extend(timezone);
const { Option } = Select;

const NotificationListView = () => {
  const [notifications, setNotifications] = useState([]);

  const [filteredNotifications, setFilteredNotifications] = useState([]);
  const [searchNotificationType, setSearchNotificationType] = useState(undefined);
  const [searchSite, setSearchSite] = useState(undefined);
  const [searchStormType, setSearchStormType] = useState(undefined);
  const [searchStormSeverity, setSearchStormSeverity] = useState(undefined);
  const [searchClientName, setSearchClientName] = useState(undefined);

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [includeAllDenied, setIncludeAllDenied] = useState(false);

  const fetchNotifications = async () => {
    try {
      const response = await api.get('/notifications/notifications/');
      setNotifications(response.data);
      setFilteredNotifications(response.data);
    } catch (error) {
      console.error('Failed to fetch notifications:', error);
      message.error('Failed to fetch notifications');
    }
  };

  useEffect(() => {
    fetchNotifications();
  }, []);

  const capitalize = (text) => {
    return text
      .replace(/_/g, ' ') 
      .replace(/\b\w/g, (char) => char.toUpperCase());
  };

  const applyFilters = () => {
    const filtered = notifications.filter((notification) => {
      const filterType = searchNotificationType ? notification.type === searchNotificationType : true;
      const filterSite = searchSite ? notification.site_details?.site_name?.toLowerCase().includes(searchSite.toLowerCase()) : true;
      const filterStormType = searchStormType ? notification.storm_details?.storm_type === searchStormType : true;
      const filterStormSeverity = searchStormSeverity ? notification.storm_details?.storm_severity === searchStormSeverity : true;
      const filterClient = searchClientName ? notification.client?.client_name === searchClientName : true;
  
      return filterType && filterSite && filterStormType && filterStormSeverity && filterClient;
    });
    setFilteredNotifications(filtered);
  };

  useEffect(() => {
    applyFilters();
  }, [searchNotificationType, searchSite, searchStormType, searchStormSeverity, searchClientName]);

  const generateDenialReport = async () => {
    if (!includeAllDenied && (!startDate || !endDate)) {
        message.error("Please select both a start and end date or choose 'All Denied'.");
        return;
    }

    try {
        message.loading("Generating denial report...", 0);

        const params = includeAllDenied
            ? {}
            : {
                  start_date: dayjs(startDate).tz("America/New_York").utc().toISOString(),
                  end_date: dayjs(endDate).tz("America/New_York").utc().toISOString(),
              };

        const response = await api.get("/notifications/generate_denial_pdf/", {
            params,
            responseType: "blob",
        });

        const blob = new Blob([response.data], { type: "application/pdf" });
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = "denial_report.pdf";

        document.body.appendChild(link);
        link.click();
        link.remove();

        message.success("Denial report downloaded successfully");
        setIsModalVisible(false);
    } catch (error) {
        console.error("Failed to generate denial report:", error);
        message.error("Failed to generate denial report");
    } finally {
        message.destroy();
    }
  };

  const columns = [
    {
      title: 'Notification Type',
      dataIndex: 'type',
      key: 'type',
      sorter: (a, b) => a.type.localeCompare(b.type),
    },
    {
      title: 'Site',
      render: (text, record) => record.site_details?.site_name || 'No Site',
      sorter: (a, b) => {
        const siteA = a.site_details?.site_name || '';
        const siteB = b.site_details?.site_name || '';
        return siteA.localeCompare(siteB);
      },
    },
    {
      title: 'Storm Type',
      width: 150,
      render: (text, record) => 
        record.storm_details?.storm_type 
          ? capitalize(record.storm_details.storm_type) 
          : 'No Storm'
    },    
    {
      title: 'Storm Severity',
      width: 150,
      render: (text, record) => 
        record.storm_details?.storm_severity 
          ? capitalize(record.storm_details.storm_severity) 
          : 'No Storm',
    },
    {
      title: 'Date of Storm (YYYY/MM/DD)',
      width: 300,
      render: (text, record) =>
        record.storm_details?.date_of_storm
          ? dayjs(record.storm_details.date_of_storm)
              .tz('America/New_York')
              .format('YYYY-MM-DD hh:mm A z')
          : 'No Storm',
      sorter: (a, b) => {
        const dateA = a.storm_details?.date_of_storm ? new Date(a.storm_details.date_of_storm) : null;
        const dateB = b.storm_details?.date_of_storm ? new Date(b.storm_details.date_of_storm) : null;
        if (!dateA) return 1;
        if (!dateB) return -1;
        return dateA - dateB;
      },
    },
    {
      title: 'Staff',
      dataIndex: 'staff',
      key: 'staff',
      render: (text, record) => record.staff_details?.staff_name || 'No Staff',
    },
    {
      title: 'Dispatch Notes',
      dataIndex: 'dispatch_message',
      key: 'dispatch_message',
      render: (text, record) => record.dispatch_log_details?.message || 'No notes',
    },
    {
      title: 'Dispatched For',
      dataIndex: 'dispatch_time',
      key: 'dispatch_time',
      render: (text, record) =>
        record.dispatch_log_details?.dispatched_for
          ? dayjs(record.dispatch_log_details.dispatched_for)
              .tz('America/New_York')
              .format('YYYY-MM-DD hh:mm A z')
          : 'Not Found',
    },
    // {
    //   title: 'Request Dispatch Log',
    //   dataIndex: 'request_dispatch_log',
    //   key: 'request_dispatch_log',
    // },
    {
      title: 'Read',
      dataIndex: 'read',
      key: 'read',
      width: 100,
      render: (read) => (
        <Checkbox type="checkbox" checked={read} />
      ),
      sorter: (a, b) => {
        return a.read === b.read ? 0 : a.read ? 1 : -1;
      },
    },
    {
      title: 'Created At',
      dataIndex: 'created_at',
      key: 'created_at',
      render: (utcDate) => {
        return utcDate
          ? dayjs(utcDate).tz('America/New_York').format('YYYY-MM-DD hh:mm A z')
          : 'N/A';
      },
    },
    {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        render: (status) => {
          let color = 'inherit';
      
          if (status === 'Denied') {
            color = '#ff4d4f'; 
          } else if (status === 'Confirmed') {
            color = '#52c41a';
          }
      
          return <span style={{ color }}>{status}</span>;
        },
      }
  ];

  const items = [
    {
      key: '1',
      label: 'Generate Denial Report',
      icon: <IconFile size={16} />,
      onClick: () => setIsModalVisible(true),
    },
  ];
  
  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'end' }}>
      <Modal
        title="Select Date Range for Denial Report"
        visible={isModalVisible}
        onOk={generateDenialReport}
        onCancel={() => setIsModalVisible(false)}
        okText="Generate Report"
        cancelText="Cancel"
      >
        <Form layout="vertical">
            <Form.Item>
                <Checkbox
                    checked={includeAllDenied}
                    onChange={(e) => {
                        setIncludeAllDenied(e.target.checked);
                        if (e.target.checked) {
                            setStartDate(null);
                            setEndDate(null);
                        }
                    }}
                >
                    All Denied - No Time Range
                </Checkbox>
            </Form.Item>
            {!includeAllDenied && (
                <>
                    <Form.Item label="Start Date">
                        <DatePicker
                            showTime={{ format: "HH:mm", secondStep: 60 }}
                            format="YYYY-MM-DD HH:mm"
                            onChange={(date) => setStartDate(date)}
                            value={startDate}
                            allowClear
                        />
                    </Form.Item>
                    <Form.Item label="End Date">
                        <DatePicker
                            showTime={{ format: "HH:mm", secondStep: 60 }}
                            format="YYYY-MM-DD HH:mm"
                            onChange={(date) => setEndDate(date)}
                            value={endDate}
                            allowClear
                        />
                    </Form.Item>
                </>
            )}
        </Form>
      </Modal>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
        <div style={{ marginLeft: 30 }}>
          <h2>Notifications</h2>
        </div>
        <div style={{ marginRight: 30, display: 'flex', gap: '10px' }}>
          {/* Filters */}
          <Dropdown style={{paddingLeft:30, marginLeft:30}} menu={{items}}>
          <Button className={styles.tableButtonGreen} style={{ fontSize: '14px', padding: '8px 15px', marginLeft: 8 }}>
              Download Reports <DownOutlined />
          </Button>
          </Dropdown>
          <Select
            placeholder="Notification Type"
            value={searchNotificationType}
            onChange={(value) => setSearchNotificationType(value)}
            style={{ width: 200 }}
            allowClear
          >
            {Array.from(new Set(notifications.map((n) => n.type))).map((type) => (
              <Option key={type} value={type}>
                {capitalize(type)}
              </Option>
            ))}
          </Select>
          <Input.Search
            placeholder="Search Site"
            value={searchSite}
            onChange={(e) => setSearchSite(e.target.value)}
            onSearch={() => applyFilters()}
            style={{ width: 200 }}
            allowClear
          />
          <Select
            placeholder="Storm Type"
            value={searchStormType}
            onChange={(value) => setSearchStormType(value)}
            style={{ width: 200 }}
            allowClear
          >
            {Array.from(new Set(notifications.map((n) => n.storm_details?.storm_type).filter(Boolean))).map((type) => (
              <Option key={type} value={type}>
                {capitalize(type)}
              </Option>
            ))}
          </Select>
          <Select
            placeholder="Storm Severity"
            value={searchStormSeverity}
            onChange={(value) => setSearchStormSeverity(value)}
            style={{ width: 200 }}
            allowClear
          >
            {Array.from(new Set(notifications.map((n) => n.storm_details?.storm_severity).filter(Boolean))).map((severity) => (
              <Option key={severity} value={severity}>
                {capitalize(severity)}
              </Option>
            ))}
          </Select>
          <Select
            placeholder="Client Name"
            value={searchClientName}
            onChange={(value) => setSearchClientName(value)}
            style={{ width: 200 }}
            allowClear
          >
            {Array.from(new Set(notifications.map((n) => n.client?.client_name).filter(Boolean))).map((name) => (
              <Option key={name} value={name}>
                {name}
              </Option>
            ))}
          </Select>
        </div>
      </div>
      <Box style={{ padding: '10px 30px 30px 30px' }}>
        <Table dataSource={filteredNotifications} columns={columns} rowKey="notification_id" pagination={false} scroll={{ y: 640 }} />
      </Box>
    </div>
  );
};

export default NotificationListView;