import React, { useEffect, useState, useMemo } from 'react';
import { ConfigProvider, Table, message, Tag, Tooltip, Spin, Select, Input, Button, Dropdown, Popconfirm } from 'antd';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { IconBuildingFactory2, IconCheck, IconCloudRain, IconGrain, IconMessage, IconSnowflake, IconTool, IconX, IconDotsVertical, IconRefresh, IconFile } from '@tabler/icons-react';
import { SearchOutlined, DownOutlined} from '@ant-design/icons';
import styled from 'styled-components';
import moment from "moment";
import { IconTractor, IconUser } from '@tabler/icons-react';
import api from "../authentication/axiosInstance";
import { useNavigate } from 'react-router-dom';
import styles from './WalkwayCrewAllocationPage.module.css';

import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
dayjs.extend(utc);
dayjs.extend(timezone);

const { Option } = Select;


const getStaffCardBackgroundColor = (status) => {
    switch (status) {
      case "Confirmed":
        return "#E5F2EC"; // Light Green
      case "Denied":
        return "#FDEAEA"; // Light Red
      default:
        return "#f0f0f0"; // Default Grey
    }
  };
  

const stormSeverityColors = [
    { severity: "light", color: "#034832" , bg: "#E5F2EC"},
    { severity: "medium", color: "#FFC107", bg: "#FFF8E1" },
    { severity: "heavy", color: "#FF4D4E", bg: "#FDEAEA" },
    { severity: "N/A", color: "#808080", bg: "#F5F5F5" },
]

const getSeverityColor = (severity) => {
    const severityColor = stormSeverityColors.find(item => item.severity === severity);
    return severityColor ? severityColor : { color: "#000000", bg: "#FFFFFF" }; // Default fallback
};

const generateStaffTimesheet = async (stormStart, stormEnd, staffName, vehicleName) => {
    try {
        // Convert stormStart and stormEnd to Unix timestamps
        const stormStartUnix = dayjs(stormStart).unix();
        const stormEndUnix = dayjs(stormEnd).unix();

        // Display a loading message
        message.loading({ content: "Generating timesheet...", key: "timesheet" });

        // Send the request to the API
        const response = await api.post(
            '/viaesys/generate-staff-timesheet/',
            {
                storm_start: stormStartUnix, // Send as Unix timestamp
                storm_end: stormEndUnix,    // Send as Unix timestamp
                staff_name: staffName,
                vehicle_name: vehicleName,
            },
            { responseType: "blob" } // Ensure response is treated as a file blob
        );

        // Check if the response is successful
        if (response.status === 200) {
            // Create a blob from the response data
            const blob = new Blob([response.data], { type: "text/csv" });

            // Generate a download URL for the blob
            const url = window.URL.createObjectURL(blob);

            // Create a temporary link element
            const link = document.createElement("a");
            link.href = url;

            // Set the download filename using the staff's name
            link.setAttribute("download", `${staffName}_timesheet.csv`);

            // Append the link to the document, trigger the download, and remove the link
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);

            // Display a success message
            message.success({ content: `Timesheet downloaded for ${staffName}!`, key: "timesheet" });
        } else {
            console.error('Failed to generate timesheet:', response.statusText);
            message.error('Failed to generate timesheet. Please try again.');
        }
    } catch (error) {
        console.error('Error generating timesheet:', error);
        message.error('An error occurred while generating the timesheet.');
    }
};


const DispatchHistoryPage = () => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [selectedObjectType, setSelectedObjectType] = useState("Walkway Crew's");
    const [dispatchLogs, setDispatchLogs] = useState({ "walkwayCrews": [], "saltingRoutes": [], "loaders": [] });
    
    // Separate region lists
    const [walkwayCrewRegions, setWalkwayCrewRegions] = useState([]);
    const [saltingRouteRegions, setSaltingRouteRegions] = useState([]);
    const [loadersRegions, setLoadersRegions] = useState([]);

    // Separate selected regions
    const [selectedWalkwayCrewRegion, setSelectedWalkwayCrewRegion] = useState('All');
    const [selectedSaltingRouteRegion, setSelectedSaltingRouteRegion] = useState('All');
    const [selectedLoadersRegion, setSelectedLoadersRegion] = useState('All');

    const [vehicleSearch, setVehicleSearch] = useState('');
    const [staffSearch, setStaffSearch] = useState('');
    const [siteSearch, setSiteSearch] = useState('');
    const [stormIds, setStormIds] = useState([]);
    const [selectedStormId, setSelectedStormId] = useState('All');
    const [stormOptions, setStormOptions] = useState([]);

    const handleResendDispatchMessage = async (dispatch_log_id, staff_id, type) => {
        if (type === "loader") {
            const response = await api.post("/dispatch/dispatchLogs/", {
                resend_loader_message: true,
                dispatch_log_id: dispatch_log_id,
                staff_id: staff_id,
              });
          
              if (response.status === 200) {
                message.success('Message resent successfully!');
              } else {
                message.error('Failed to resend the message.');
              }
        } else if (type === "walkwayCrew") {
            const response = await api.post("/dispatch/dispatchLogs/", {
                resend_walkway_crew_message: true,
                dispatch_log_id: dispatch_log_id,
                staff_id: staff_id,
              });
          
              if (response.status === 200) {
                message.success('Message resent successfully!');
              } else {
                message.error('Failed to resend the message.');
              }
        } else if (type === "saltingRoute") {
            const response = await api.post("/dispatch/dispatchLogs/", {
                resend_salting_route_message: true,
                dispatch_log_id: dispatch_log_id,
                staff_id: staff_id,
              });
          
              if (response.status === 200) {
                message.success('Message resent successfully!');
              } else {
                message.error('Failed to resend the message.');
              }
        }
      };

    const walkwayCrewsColumns = [
        {
            title: "Crew Name",
            dataIndex: "name", // Specifies the data field
            key: "name",
            width: 100,
            sorter: (a, b) => {
                const nameA = a.name ? a.name.toLowerCase() : "";
                const nameB = b.name ? b.name.toLowerCase() : "";
                return nameA.localeCompare(nameB);
            },
            sortDirections: ['ascend', 'descend'],
            render: (text) => <b>{text}</b>, // Renders the text in bold
        },    
        {
            title: "Region",
            dataIndex: "region", // Use dataIndex for better integration with Ant Design
            key: "region",
            width: 60,
            sorter: (a, b) => {
                // Handle cases where region might be null or undefined
                const regionA = a.region ? a.region.toLowerCase() : "";
                const regionB = b.region ? b.region.toLowerCase() : "";
                if (regionA < regionB) return -1;
                if (regionA > regionB) return 1;
                return 0;
            },
            sortDirections: ['ascend', 'descend'],
            render: (text) => <b>{text}</b>,
        },
        {
            title: "Event",
            key: "storm_type",
            width: 180,
            // render: (record) => <span>{record.storm_type} ({record.storm_severity})</span>,
            render: (text, record) => {
                const { color, bg } = getSeverityColor(record.storm_severity);
                return (
                <div style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'center', flexDirection: 'column', width: '100%', height: '50px' }}>
                    {record.storm_type === "snow" || record.storm_type === "Snow Showers" || record.storm_type === "Isolated Snow Showers"
                    || record.storm_type === "Snow" || record.storm_type === "Chance of Snow" || record.storm_type === "Light Snow"
                    || record.storm_type === "Chance of Sleet/Snow" || record.storm_type === "Scattered Snow Showers" ? (
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'row', gap: '7px' }}>
                            <div style={{ 
                                backgroundColor: bg,
                                height: '30px', 
                                width: '30px', 
                                display: 'flex', 
                                alignItems: 'center', 
                                justifyContent: 'center', 
                                border: `1px solid ${color}`, 
                                borderRadius: '4px', 
                                color: color 
                            }}>
                                <IconSnowflake size={18} stroke={2} />
                            </div>
                            <div style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start', flexDirection: 'column' }}>
                                <span style={{ fontWeight: '600', fontSize: '16px' }}>{record.storm_type === "snow" ? "Snow" : record.storm_type}</span>
                                <span style={{ fontSize: '14px', marginTop: '-5px' }}>Severity: {record.storm_severity}</span>
                                <span style={{ fontSize: '14px', marginTop: '-5px' }}>
                                    Date: {new Intl.DateTimeFormat('en-US', { month: 'long', day: '2-digit', year: 'numeric' }).format(new Date(record.storm_date))}
                                </span>
                                <span style={{ fontSize: '14px', marginTop: '-5px' }}>ID: {record.storm_id}</span>                       
                            </div>
                        </div>
                    ) : record.storm_type === "freezing_rain" || record.storm_type === "Wintry Mix" || record.storm_type === "Chance of Wintry Mix" || record.storm_type === "Fog" 
                    || record.storm_type === "Isolated Freezing Rain" || record.storm_type === "Chance of Freezing Rain"? (
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'row', gap: '7px' }}>
                            <div style={{ 
                                backgroundColor: bg,
                                height: '30px', 
                                width: '30px', 
                                display: 'flex', 
                                alignItems: 'center', 
                                justifyContent: 'center', 
                                border: `1px solid ${color}`, 
                                borderRadius: '4px', 
                                color: color 
                            }}>
                                <IconCloudRain size={18} stroke={2} />
                            </div>
                            <div style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start', flexDirection: 'column' }}>
                                <span style={{ fontWeight: '600', fontSize: '16px' }}>{record.storm_type === "freezing_rain" ? "Freezing Rain" : record.storm_type}</span>
                                <span style={{ fontSize: '14px', marginTop: '-5px' }}>Severity: {record.storm_severity}</span>
                                <span style={{ fontSize: '14px', marginTop: '-5px' }}>
                                    Date: {new Intl.DateTimeFormat('en-US', { month: 'long', day: '2-digit', year: 'numeric' }).format(new Date(record.storm_date))}
                                </span> 
                                <span style={{ fontSize: '14px', marginTop: '-5px' }}>ID: {record.storm_id}</span>                       
                            </div>
                        </div>
                    ) : record.storm_type === "salt" ? (
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'row', gap: '7px' }}>
                            <div style={{ 
                                backgroundColor: bg,
                                height: '30px', 
                                width: '30px', 
                                display: 'flex', 
                                alignItems: 'center', 
                                justifyContent: 'center', 
                                border: `1px solid ${color}`, 
                                borderRadius: '4px', 
                                color: color 
                            }}>
                                <IconGrain size={18} stroke={2} />
                            </div>
                            <div style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start', flexDirection: 'column' }}>
                                <span style={{ fontWeight: '600', fontSize: '16px' }}>Salting</span>
                                <span style={{ fontSize: '14px', marginTop: '-5px' }}>Severity: {record.storm_severity}</span>
                                <span style={{ fontSize: '14px', marginTop: '-5px' }}>
                                    Date: {new Intl.DateTimeFormat('en-US', { month: 'long', day: '2-digit', year: 'numeric' }).format(new Date(record.storm_date))}
                                </span> 
                                <span style={{ fontSize: '14px', marginTop: '-5px' }}>ID: {record.storm_id}</span>
                            </div>
                        </div>
                    ) : (
                        <div style={{ backgroundColor: '#FDEAEA', height: '30px', width: '30px', display: 'flex', alignItems: 'center', justifyContent: 'center', border: '1px solid #FF4D4E', borderRadius: '4px', color: "#FF4D4E" }}>
                            <IconTool size={18} stroke={2} />
                        </div>
                    )}
                </div>
            )}
        },
        {
            title: "Vehicle",
            key: "vehicle",
            width: 80,
            render: (text, record) => (
                <div style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'center', flexDirection: 'column', width: '100%', height: '50px' }}>
                <span style={{ fontWeight: '400', fontSize: '16px' }}><IconTractor size={14} style={{ marginRight: '3px' }} />{record.vehicle_name}</span>  
                </div>
            ),
        },
        {
            title: 'Staff Assigned',
            key: 'staff_assigned',
            width: 130,
            render: (text, record) => (
              <div style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'center', flexDirection: 'column', height: '85px' }}>
                {record.staff_details.slice(0, 3).map((staff) => (
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'flex-start',
                      flexDirection: 'row',
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: "ellipsis",
                      maxWidth: '250px'
                    }}
                    key={staff.staff_id}
                  >
                    <span style={{ fontWeight: '400', fontSize: '16px', marginRight: "4px" }}>
                      <IconUser size={14} style={{ marginRight: '3px' }} />
                      {staff.staff_name}
                    </span>
                  </div>
                ))}
      
                {/* Show "+ x more staff" if there are more than 3 staff */}
                {record.staff_details.length > 3 && (
                  <span style={{ fontSize: '14px', color: '#888' }}>
                    + {record.staff_details.length - 3} more staff
                  </span>
                )}
      
                {record.staff_details.length === 0 && 'No Staff Assigned'}
              </div>
            ),
        },
        {
            title: 'Sites Assigned',
            key: 'sites_assigned',
            width: 180,
            render: (text, record) => (
              <div style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'center', flexDirection: 'column', height: '85px' }}>
                {record.site_details.slice(0, 3).map((site) => (
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'flex-start',
                      flexDirection: 'row',
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: "ellipsis",
                      maxWidth: '250px'
                    }}
                    key={site.site_id}
                  >
                    <span style={{ fontWeight: '400', fontSize: '16px', marginRight: "4px" }}>
                      <IconBuildingFactory2 size={14} stroke={1.4} style={{ marginRight: '3px' }} />
                      {site.client_name}
                    </span>
                    <span style={{ fontSize: '14px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: "ellipsis" }}>
                      - {site.site_name}
                    </span>
                  </div>
                ))}
      
                {/* Show "+ x more sites" if there are more than 3 sites */}
                {record.site_details.length > 3 && (
                  <span style={{ fontSize: '14px', color: '#888' }}>
                    + {record.site_details.length - 3} more sites
                  </span>
                )}
      
                {record.site_details.length === 0 && 'No Sites Assigned'}
              </div>
            ),
        },
        {
            title: "Dispatched At",
            dataIndex: "dispatched_at",
            key: "dispatched_at",
            width: 100,
            render: (dispatched_at) =>
              dispatched_at ? <div style={{ display: 'flex', height: "50px", alignItems: 'flex-start', justifyContent: 'flex-start', flexDirection: 'column' }}>
                <span>{moment(dispatched_at).format("MMMM Do YYYY")} </span>
                <span>{moment(dispatched_at).format("h:mm:ss a")}</span>
                </div> :<div style={{ display: 'flex', height: "85px", alignItems: 'flex-start', justifyContent: 'flex-start', flexDirection: 'column' }}>N/A</div>,
            sorter: (a, b) => moment(a.dispatched_at).unix() - moment(b.dispatched_at).unix(),
          },
          {
            title: "Dispatched For",
            dataIndex: "dispatched_for",
            key: "dispatched_for",
            width: 100,
            render: (dispatched_for) =>
              dispatched_for ? <div style={{ display: 'flex', height: "50px", alignItems: 'flex-start', justifyContent: 'flex-start', flexDirection: 'column' }}>
                <span>{moment(dispatched_for).format("MMMM Do YYYY")} </span> <span>{moment(dispatched_for).format("h:mm a")}</span>
                </div> :<div style={{ display: 'flex', height: "85px", alignItems: 'flex-start', justifyContent: 'flex-start', flexDirection: 'column' }}>N/A</div>,
            sorter: (a, b) => moment(a.dispatched_for).unix() - moment(b.dispatched_for).unix(),
          },
          {
            title: "Overall Confirmation",
            key: "overall_staff_confirmation",
            width: 80,
            render: (record) => (
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', width: '100%', height: '50px' }}>
                    {record.overall_staff_confirmation === "Confirmed" && (
                        <div style={{ backgroundColor: '#E5F2EC', height: '30px', width: '30px', display: 'flex', alignItems: 'center', justifyContent: 'center', border: '1px solid #034832', borderRadius: '4px', color: "#034832" }}>
                            <IconCheck size={18} stroke={2} />
                        </div>
                    )}
                    {record.overall_staff_confirmation === "Not Confirmed" && (
                        <Tooltip title={( record.staff_details.map((staff) => (<><li>{staff.staff_name} - {staff.status}</li></>)) )}>
                        <div style={{ backgroundColor: '#FDEAEA', height: '30px', width: '30px', display: 'flex', alignItems: 'center', justifyContent: 'center', border: '1px solid #FF4D4E', borderRadius: '4px', color: "#FF4D4E" }}>
                            <IconX size={18} stroke={2} />
                        </div>
                        </Tooltip>
                    )}
                    {record.overall_staff_confirmation === "Created" && (
                        <div style={{ backgroundColor: '#ebebeb', height: '30px', width: '30px', display: 'flex', alignItems: 'center', justifyContent: 'center', border: '1px solid #034832', borderRadius: '4px', color: "#034832" }}>
                            <IconCheck size={18} stroke={2} />
                        </div>
                    )}
                </div>
            ),
          }
    ]
    
    const walkwayCrewsExpandedRow = (record) => (
        <div style={{ width: '100%', display: 'flex', alignItems: 'stretch', justifyContent: 'flex-start', flexDirection: 'row'}}>
            {/* Staff */}
            <div style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start', flexDirection: 'column', paddingRight: '30px', borderRight: "0.5px solid #e0e0e0" }}>
                <span style={{ fontWeight: '600', fontSize: '16px', marginBottom: '10px' }}>Staff Members:</span>
                <div style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start', flexDirection: 'column', gap: '10px' }}>
                    {record.staff_details.map((staff, index) => (
                       <div key={index} style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'space-between', flexDirection: 'row', width: '400px', border: '1px solid #e0e0e0', borderRadius: '5px', padding: '5px 10px', backgroundColor: getStaffCardBackgroundColor(staff.status) }}>
                       <div 
                        style={{ 
                          display: 'flex', 
                          alignItems: 'flex-start', 
                          justifyContent: 'flex-start', 
                          flexDirection: 'column', 
                        }} 
                      >
                        <span style={{ fontWeight: '500', fontSize: '16px', marginRight: "4px" }}>
                          <IconUser size={14} style={{ marginRight: '3px' }} />
                          {staff.staff_name}
                        </span>
                        <span style={{ fontSize: '14px'}}>
                          {staff.staff_position}
                        </span>       
                      </div>
                      <div style={{ display: 'flex', alignItems: 'flex-start', flexDirection: 'column', justifyContent: 'flex-start', gap: "5px" }}>
                        <span style={{ fontSize: '14px' }}><span style={{ fontWeight: '500' }}>Contact Number:</span>  {staff.staff_phone}</span>
                        <span style={{ fontSize: '14px' }}><span style={{ fontWeight: '500' }}>Message Status:</span> <Tag>{staff.status === "undelivered" ? "Error In Delivery" : staff.status}</Tag><Tooltip onClick={() => handleResendDispatchMessage(record.dispatch_log_id, staff.staff_id, "walkwayCrew")} title="Resend Message"><IconMessage size={14} style={{ cursor: 'pointer', paddingTop: '5px' }}/></Tooltip></span>
                    </div>
                    <div style={{ marginTop: '4px', display: 'flex', flexDirection:"column", gap: '4px' }}>
                        {/* Confirm Button - For all statuses with confirmation */}
                        <Popconfirm
                            title="Are you sure you want to confirm this dispatch response?"
                            onConfirm={() => handleConfirm(staff.dispatched_staff_id)}
                            okText="Yes"
                            cancelText="No"
                        >
                            <Button
                            type="primary"
                            style={{ backgroundColor: '#034832', borderColor: '#034832', color: 'white', width: '80px' }}
                            >
                            Confirm
                            </Button>
                        </Popconfirm>
                
                        {/* Deny Button - For all statuses with confirmation */}
                        <Popconfirm
                            title="Are you sure you want to deny this dispatch response?"
                            onConfirm={() => handleDeny(staff.dispatched_staff_id)}
                            okText="Yes"
                            cancelText="No"
                        >
                            <Button
                            type="primary"
                            style={{ backgroundColor: '#FF4D4E', borderColor: '#FF4D4E', color: 'white', width: '80px' }}
                            >
                            Deny
                            </Button>
                        </Popconfirm>
                    </div>
                      </div>
                    ))}
                </div>
            </div>
            {/* Sites */}
            <div style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start', flexDirection: 'column', paddingLeft: '30px', paddingRight: '30px', borderRight: "0.5px solid #e0e0e0" }}>
                <span style={{ fontWeight: '600', fontSize: '16px', marginBottom: '10px' }}>Sites:</span>
                <div style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start', flexDirection: 'column', gap: '5px' }}>
                    {record.site_details.map((site, index) => (
                        <div 
                        style={{ 
                          display: 'flex', 
                          alignItems: 'flex-start', 
                          justifyContent: 'flex-start', 
                          flexDirection: 'column',
                          border: '1px solid #e0e0e0', 
                          borderRadius: '5px', 
                          padding: '5px 10px', 
                          backgroundColor: '#f0f0f0',
                          width: "250px",
                          position: 'relative',
                        }} 
                        key={index}
                      >
                        <span style={{ fontWeight: '500', fontSize: '16px', marginRight: "4px" }}>
                          <IconBuildingFactory2 size={14} stroke={1.4} style={{ marginRight: '3px' }} />
                          {site.client_name}
                        </span>
                        <span style={{ fontSize: '14px', }}>
                          {site.site_name}
                        </span>    
                        <span style={{ fontSize: '14px', fontWeight: '500', position: 'absolute', top: '0', right: '0', marginTop: '5px', marginRight: '10px'}}>
                            {site.site_region}
                        </span>   
                      </div>
                    ))}
                </div>
            </div>
            {/* Equipment */}
            <div style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start', flexDirection: 'column', paddingLeft: '30px', paddingRight: '30px', borderRight: "0.5px solid #e0e0e0" }}>
                <span style={{ fontWeight: '600', fontSize: '16px', marginBottom: '10px' }}>Equipment:</span>
                <div style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start', flexDirection: 'column', gap: '5px' }}>
                    {record.equipment_details.map((equipment, index) => (
                        <div key={index} style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start', flexDirection: 'column', width: '250px', border: '1px solid #e0e0e0', borderRadius: '5px', padding: '5px 10px', backgroundColor: '#f0f0f0' }}>
                            <span style={{ fontWeight: '500', fontSize: '16px'}}>
                                <IconTractor size={14} stroke={1.4} style={{ marginRight: '3px' }} />
                                {equipment.equipment_name}
                            </span>
                            <span style={{ fontSize: '14px' }}>{equipment.equipment_type_name}</span>
                        </div>
                    ))}
                </div>
            </div>
            {/* Notes */}
            <div style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start', flexDirection: 'column', paddingLeft: '30px', paddingRight: '30px', borderRight: "0.5px solid #e0e0e0" }}>
                <span style={{ fontWeight: '600', fontSize: '16px', marginBottom: '10px', width:250 }}>Notes:</span>
                <div style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start', flexDirection: 'column', gap: '5px' }}>
                    <div style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start', flexDirection: 'column' }}>
                        <span style={{ fontSize: '14px'}}>
                            {record.message || "N/A"}
                        </span>
                    </div>
                </div>
            </div>
            {/* Generate Timesheet and Storm Time */}
            <div style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start', flexDirection: 'column', paddingLeft: '30px' }}>
                <span style={{ fontWeight: '600', fontSize: '16px', marginBottom: '10px' }}>Storm date and time:</span>
                <span style={{ fontSize: '14px', marginBottom: '5px' }}>
                    <b>Start:</b> {record.storm_date ? dayjs.utc(record.storm_date).tz('America/New_York').format('MMMM Do YYYY, h:mm A') : 'N/A'}
                </span>
                <span style={{ fontSize: '14px', marginBottom: '10px' }}>
                    <b>End:</b> {record.storm_date_end ? dayjs.utc(record.storm_date_end).tz('America/New_York').format('MMMM Do YYYY, h:mm A') : 'N/A'}
                </span>
                <span style={{ fontWeight: '600', fontSize: '16px', marginBottom: '10px' }}>Generate Timesheet:</span>
                {record.storm_date && record.storm_date_end ? (
                    <div style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start', flexDirection: 'column', gap: '5px' }}>
                        {record.staff_details.filter(staff => staff.status === "Confirmed").length > 0 ? (
                            record.staff_details
                                .filter(staff => staff.status === "Confirmed")
                                .map((staff, index) => (
                                    <div key={index} style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start', flexDirection: 'column' }}>
                                        <span style={{ fontSize: '14px' }}>
                                            <div
                                                className={styles.tableButton}
                                                onClick={() => generateStaffTimesheet(record.storm_date, record.storm_date_end, staff.staff_name, record.vehicle_name)}
                                                style={{ fontSize: '14px', padding: '8px 15px', cursor: 'pointer', marginBottom: 10 }}
                                            >
                                                Timesheet - {staff.staff_name}
                                            </div>
                                        </span>
                                    </div>
                                ))
                        ) : (
                            <div>
                                <span style={{ color: 'red' }}><em>* </em></span>
                                <span style={{ fontStyle: 'italic' }}>No confirmed staff</span>
                                <span style={{ color: 'red' }}><em> *</em></span>
                            </div>
                        )}
                    </div>
                ) : (
                    <div>
                        <span style={{ color: 'red' }}><em>* </em></span>
                        <span><em>Associated storm's start time and end time must be specified before generating timesheet</em></span>
                        <span style={{ color: 'red' }}><em> *</em></span>
                    </div>
                )}
            </div>
        </div>
    )
    
    const saltingRoutesColumns = [
        {
            title: "Route Name",
            dataIndex: "name", // Specifies the data field
            key: "name",
            width: 100,
            sorter: (a, b) => {
                const nameA = a.name ? a.name.toLowerCase() : "";
                const nameB = b.name ? b.name.toLowerCase() : "";
                return nameA.localeCompare(nameB);
            },
            sortDirections: ['ascend', 'descend'],
            render: (text) => <b>{text || ""}</b>, // Renders the text in bold, shows "N/A" if null
        }, 
        {
            title: "Region",
            dataIndex: "region", // Use dataIndex for better integration with Ant Design
            key: "region",
            width: 60,
            sorter: (a, b) => {
                // Handle cases where region might be null or undefined
                const regionA = a.region ? a.region.toLowerCase() : "";
                const regionB = b.region ? b.region.toLowerCase() : "";
                if (regionA < regionB) return -1;
                if (regionA > regionB) return 1;
                return 0;
            },
            sortDirections: ['ascend', 'descend'],
            render: (text) => <b>{text}</b>,
        },
        {
            title: "Event",
            key: "storm_type",
            width: 180,
            // render: (record) => <span>{record.storm_type} ({record.storm_severity})</span>,
            render: (text, record) => {
                const { color, bg } = getSeverityColor(record.storm_severity);
                return (
                <div style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'center', flexDirection: 'column', width: '100%', height: '50px' }}>
                    {record.storm_type === "snow" || record.storm_type === "Snow Showers" || record.storm_type === "Isolated Snow Showers"
                    || record.storm_type === "Snow" || record.storm_type === "Chance of Snow" || record.storm_type === "Light Snow"
                    || record.storm_type === "Chance of Sleet/Snow" || record.storm_type === "Scattered Snow Showers" ? (
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'row', gap: '7px' }}>
                            <div style={{ 
                                backgroundColor: bg,
                                height: '30px', 
                                width: '30px', 
                                display: 'flex', 
                                alignItems: 'center', 
                                justifyContent: 'center', 
                                border: `1px solid ${color}`, 
                                borderRadius: '4px', 
                                color: color 
                            }}>
                                <IconSnowflake size={18} stroke={2} />
                            </div>
                            <div style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start', flexDirection: 'column' }}>
                                <span style={{ fontWeight: '600', fontSize: '16px' }}>{record.storm_type === "snow" ? "Snow" : record.storm_type}</span>
                                <span style={{ fontSize: '14px', marginTop: '-5px' }}>Severity: {record.storm_severity}</span>
                                <span style={{ fontSize: '14px', marginTop: '-5px' }}>
                                    Date: {new Intl.DateTimeFormat('en-US', { month: 'long', day: '2-digit', year: 'numeric' }).format(new Date(record.storm_date))}
                                </span> 
                                <span style={{ fontSize: '14px', marginTop: '-5px' }}>ID: {record.storm_id}</span>
                            </div>
                        </div>
                    ) : record.storm_type === "freezing_rain" || record.storm_type === "Wintry Mix" || record.storm_type === "Chance of Wintry Mix" || record.storm_type === "Fog" 
                    || record.storm_type === "Isolated Freezing Rain" || record.storm_type === "Chance of Freezing Rain"? (
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'row', gap: '7px' }}>
                            <div style={{ 
                                backgroundColor: bg,
                                height: '30px', 
                                width: '30px', 
                                display: 'flex', 
                                alignItems: 'center', 
                                justifyContent: 'center', 
                                border: `1px solid ${color}`, 
                                borderRadius: '4px', 
                                color: color 
                            }}>
                                <IconCloudRain size={18} stroke={2} />
                            </div>
                            <div style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start', flexDirection: 'column' }}>
                                <span style={{ fontWeight: '600', fontSize: '16px' }}>{record.storm_type === "freezing_rain" ? "Freezing Rain" : record.storm_type}</span>
                                <span style={{ fontSize: '14px', marginTop: '-5px' }}>Severity: {record.storm_severity}</span>
                                <span style={{ fontSize: '14px', marginTop: '-5px' }}>
                                    Date: {new Intl.DateTimeFormat('en-US', { month: 'long', day: '2-digit', year: 'numeric' }).format(new Date(record.storm_date))}
                                </span> 
                                <span style={{ fontSize: '14px', marginTop: '-5px' }}>ID: {record.storm_id}</span>
                            </div>
                        </div>
                    ) : record.storm_type === "salt" ? (
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'row', gap: '7px' }}>
                            <div style={{ 
                                backgroundColor: bg,
                                height: '30px', 
                                width: '30px', 
                                display: 'flex', 
                                alignItems: 'center', 
                                justifyContent: 'center', 
                                border: `1px solid ${color}`, 
                                borderRadius: '4px', 
                                color: color 
                            }}>
                                <IconGrain size={18} stroke={2} />
                            </div>
                            <div style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start', flexDirection: 'column' }}>
                                <span style={{ fontWeight: '600', fontSize: '16px' }}>Salting</span>
                                <span style={{ fontSize: '14px', marginTop: '-5px' }}>Severity: {record.storm_severity}</span>
                                <span style={{ fontSize: '14px', marginTop: '-5px' }}>
                                    Date: {new Intl.DateTimeFormat('en-US', { month: 'long', day: '2-digit', year: 'numeric' }).format(new Date(record.storm_date))}
                                </span> 
                                <span style={{ fontSize: '14px', marginTop: '-5px' }}>ID: {record.storm_id}</span>
                            </div>
                        </div>
                    ) : (
                        <div style={{ backgroundColor: '#FDEAEA', height: '30px', width: '30px', display: 'flex', alignItems: 'center', justifyContent: 'center', border: '1px solid #FF4D4E', borderRadius: '4px', color: "#FF4D4E" }}>
                            <IconTool size={18} stroke={2} />
                        </div>
                    )}
                </div>
            )}
        },
        {
            title: "Vehicle",
            key: "vehicle",
            width: 100,
            render: (text, record) => (
                <div style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'center', flexDirection: 'column', width: '100%', height: '50px' }}>
                <span style={{ fontWeight: '400', fontSize: '16px' }}><IconTractor size={14} style={{ marginRight: '3px' }} />{record.vehicle_name}</span>  
                </div>
            ),
        },
        {
            title: 'Staff Assigned',
            key: 'staff_assigned',
            width: 130,
            render: (text, record) => (
              <div style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'center', flexDirection: 'column', height: '85px' }}>
                {record.staff_details.slice(0, 3).map((staff) => (
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'flex-start',
                      flexDirection: 'row',
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: "ellipsis",
                      maxWidth: '250px'
                    }}
                    key={staff.staff_id}
                  >
                    <span style={{ fontWeight: '400', fontSize: '16px', marginRight: "4px" }}>
                      <IconUser size={14} style={{ marginRight: '3px' }} />
                      {staff.staff_name}
                    </span>
                  </div>
                ))}
      
                {/* Show "+ x more staff" if there are more than 3 staff */}
                {record.staff_details.length > 3 && (
                  <span style={{ fontSize: '14px', color: '#888' }}>
                    + {record.staff_details.length - 3} more staff
                  </span>
                )}
      
                {record.staff_details.length === 0 && 'No Staff Assigned'}
              </div>
            ),
        },
        {
            title: 'Sites Assigned',
            key: 'sites_assigned',
            width: 180,
            render: (text, record) => (
              <div style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'center', flexDirection: 'column', height: '85px' }}>
                {record.site_details.slice(0, 3).map((site) => (
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'flex-start',
                      flexDirection: 'row',
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: "ellipsis",
                      maxWidth: '250px'
                    }}
                    key={site.site_id}
                  >
                    <span style={{ fontWeight: '400', fontSize: '16px', marginRight: "4px" }}>
                      <IconBuildingFactory2 size={14} stroke={1.4} style={{ marginRight: '3px' }} />
                      {site.site_name}
                    </span>
                  </div>
                ))}
      
                {/* Show "+ x more sites" if there are more than 3 sites */}
                {record.site_details.length > 3 && (
                  <span style={{ fontSize: '14px', color: '#888' }}>
                    + {record.site_details.length - 3} more sites
                  </span>
                )}
      
                {record.site_details.length === 0 && 'No Sites Assigned'}
              </div>
            ),
        },
        {
            title: "Dispatched At",
            dataIndex: "dispatched_at",
            key: "dispatched_at",
            width: 100,
            render: (dispatched_at) =>
              dispatched_at ? <div style={{ display: 'flex', height: "50px", alignItems: 'flex-start', justifyContent: 'flex-start', flexDirection: 'column' }}>
                <span>{moment(dispatched_at).format("MMMM Do YYYY")} </span>
                <span>{moment(dispatched_at).format("h:mm:ss a")}</span>
                </div> :<div style={{ display: 'flex', height: "85px", alignItems: 'flex-start', justifyContent: 'flex-start', flexDirection: 'column' }}>N/A</div>,
            sorter: (a, b) => moment(a.dispatched_at).unix() - moment(b.dispatched_at).unix(),
          },
          {
            title: "Dispatched For",
            dataIndex: "dispatched_for",
            key: "dispatched_for",
            width: 100,
            render: (dispatched_for) =>
              dispatched_for ? <div style={{ display: 'flex', height: "50px", alignItems: 'flex-start', justifyContent: 'flex-start', flexDirection: 'column' }}>
                <span>{moment(dispatched_for).format("MMMM Do YYYY")} </span>
                <span>{moment(dispatched_for).format("h:mm:ss a")}</span>
                </div> :<div style={{ display: 'flex', height: "85px", alignItems: 'flex-start', justifyContent: 'flex-start', flexDirection: 'column' }}>N/A</div>,
            sorter: (a, b) => moment(a.dispatched_for).unix() - moment(b.dispatched_for).unix(),
          },
          {
            title: "Overall Confirmation",
            key: "overall_staff_confirmation",
            width: 80,
            render: (record) => (
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', width: '100%', height: '50px' }}>
                    {record.overall_staff_confirmation === "Confirmed" && (
                        <div style={{ backgroundColor: '#E5F2EC', height: '30px', width: '30px', display: 'flex', alignItems: 'center', justifyContent: 'center', border: '1px solid #034832', borderRadius: '4px', color: "#034832" }}>
                            <IconCheck size={18} stroke={2} />
                        </div>
                    )}
                    {record.overall_staff_confirmation === "Not Confirmed" && (
                        <Tooltip title={( record.staff_details.map((staff) => (<><li>{staff.staff_name} - {staff.status}</li></>)) )}>
                        <div style={{ backgroundColor: '#FDEAEA', height: '30px', width: '30px', display: 'flex', alignItems: 'center', justifyContent: 'center', border: '1px solid #FF4D4E', borderRadius: '4px', color: "#FF4D4E" }}>
                            <IconX size={18} stroke={2} />
                        </div>
                        </Tooltip>
                    )}
                    {record.overall_staff_confirmation === "Created" && (
                        <div style={{ backgroundColor: '#ebebeb', height: '30px', width: '30px', display: 'flex', alignItems: 'center', justifyContent: 'center', border: '1px solid #034832', borderRadius: '4px', color: "#034832" }}>
                            <IconCheck size={18} stroke={2} />
                        </div>
                    )}
                </div>
            ),
          }
    ]
    
    const saltingRoutesExpandedRow = (record) => (
        <div style={{ width: '100%', display: 'flex', alignItems: 'stretch', justifyContent: 'flex-start', flexDirection: 'row'}}>
        {/* Staff */}
        <div style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start', flexDirection: 'column', paddingRight: '30px', borderRight: "0.5px solid #e0e0e0" }}>
            <span style={{ fontWeight: '600', fontSize: '16px', marginBottom: '10px' }}>Staff Members:</span>
            <div style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start', flexDirection: 'column', gap: '10px' }}>
                {record.staff_details.map((staff, index) => (
                   <div key={index} style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'space-between', flexDirection: 'row', width: '400px', border: '1px solid #e0e0e0', borderRadius: '5px', padding: '5px 10px', backgroundColor: getStaffCardBackgroundColor(staff.status) }}>
                   <div 
                    style={{ 
                      display: 'flex', 
                      alignItems: 'flex-start', 
                      justifyContent: 'flex-start', 
                      flexDirection: 'column', 
                    }} 
                  >
                    <span style={{ fontWeight: '500', fontSize: '16px', marginRight: "4px" }}>
                      <IconUser size={14} style={{ marginRight: '3px' }} />
                      {staff.staff_name}
                    </span>
                    <span style={{ fontSize: '14px'}}>
                      {staff.staff_position}
                    </span>       
                  </div>
                  <div style={{ display: 'flex', alignItems: 'flex-start', flexDirection: 'column', justifyContent: 'flex-start', gap: "5px" }}>
                    <span style={{ fontSize: '14px' }}><span style={{ fontWeight: '500' }}>Contact Number:</span>  {staff.staff_phone}</span>
                    <span style={{ fontSize: '14px' }}><span style={{ fontWeight: '500' }}>Message Status:</span> <Tag>{staff.status === "undelivered" ? "Error In Delivery" : staff.status}</Tag><Tooltip onClick={() => handleResendDispatchMessage(record.dispatch_log_id, staff.staff_id, "saltingRoute")} title="Resend Message"><IconMessage size={14} style={{ cursor: 'pointer', paddingTop: '5px' }}/></Tooltip></span>
                </div>
                <div style={{ marginTop: '4px', display: 'flex', flexDirection:"column", gap: '4px' }}>
                        {/* Confirm Button - For all statuses with confirmation */}
                        <Popconfirm
                            title="Are you sure you want to confirm this dispatch response?"
                            onConfirm={() => handleConfirm(staff.dispatched_staff_id)}
                            okText="Yes"
                            cancelText="No"
                        >
                            <Button
                            type="primary"
                            style={{ backgroundColor: '#034832', borderColor: '#034832', color: 'white', width: '80px' }}
                            >
                            Confirm
                            </Button>
                        </Popconfirm>
                
                        {/* Deny Button - For all statuses with confirmation */}
                        <Popconfirm
                            title="Are you sure you want to deny this dispatch response?"
                            onConfirm={() => handleDeny(staff.dispatched_staff_id)}
                            okText="Yes"
                            cancelText="No"
                        >
                            <Button
                            type="primary"
                            style={{ backgroundColor: '#FF4D4E', borderColor: '#FF4D4E', color: 'white', width: '80px' }}
                            >
                            Deny
                            </Button>
                        </Popconfirm>
                    </div>
                  </div>
                ))}
            </div>
        </div>
        {/* Sites */}
        <div style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start', flexDirection: 'column', paddingLeft: '30px', paddingRight: '30px', borderRight: "0.5px solid #e0e0e0" }}>
            <span style={{ fontWeight: '600', fontSize: '16px', marginBottom: '10px' }}>Sites:</span>
            <div style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start', flexDirection: 'column', gap: '5px' }}>
                {record.site_details.map((site, index) => (
                    <div 
                    style={{ 
                      display: 'flex', 
                      alignItems: 'flex-start', 
                      justifyContent: 'flex-start', 
                      flexDirection: 'column',
                      border: '1px solid #e0e0e0', 
                      borderRadius: '5px', 
                      padding: '5px 10px', 
                      backgroundColor: '#f0f0f0',
                      width: "350px",
                      position: 'relative',
                    }} 
                    key={index}
                  >
                    <span style={{ fontWeight: '500', fontSize: '16px', marginRight: "4px" }}>
                      <IconBuildingFactory2 size={14} stroke={1.4} style={{ marginRight: '3px' }} />
                      {site.client_name}
                    </span>
                    <span style={{ fontSize: '14px', }}>
                      {site.site_name}
                    </span>    
                    <span style={{ fontSize: '14px', fontWeight: '500', position: 'absolute', top: '0', right: '0', marginTop: '5px', marginRight: '10px'}}>
                        {site.site_region}
                    </span>   
                    
                  </div>
                ))}
            </div>
        </div>
        {/* Notes */}
        <div style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start', flexDirection: 'column', paddingLeft: '30px', paddingRight: '30px', borderRight: "0.5px solid #e0e0e0" }}>
            <span style={{ fontWeight: '600', fontSize: '16px', marginBottom: '10px', width:250 }}>Notes:</span>
            <div style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start', flexDirection: 'column', gap: '5px' }}>
                <div style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start', flexDirection: 'column' }}>
                    <span style={{ fontSize: '14px'}}>
                        {record.message || "N/A"}
                    </span>
                </div>
            </div>
        </div>
        {/* Generate Timesheet and Storm Time */}
        <div style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start', flexDirection: 'column', paddingLeft: '30px' }}>
            <span style={{ fontWeight: '600', fontSize: '16px', marginBottom: '10px' }}>Storm date and time:</span>
            <span style={{ fontSize: '14px', marginBottom: '5px' }}>
                <b>Start:</b> {record.storm_date ? dayjs.utc(record.storm_date).tz('America/New_York').format('MMMM Do YYYY, h:mm A') : 'N/A'}
            </span>
            <span style={{ fontSize: '14px', marginBottom: '10px' }}>
                <b>End:</b> {record.storm_date_end ? dayjs.utc(record.storm_date_end).tz('America/New_York').format('MMMM Do YYYY, h:mm A') : 'N/A'}
            </span>
            <span style={{ fontWeight: '600', fontSize: '16px', marginBottom: '10px' }}>Generate Timesheet:</span>
            {record.storm_date && record.storm_date_end ? (
                    <div style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start', flexDirection: 'column', gap: '5px' }}>
                        {record.staff_details.filter(staff => staff.status === "Confirmed").length > 0 ? (
                            record.staff_details
                                .filter(staff => staff.status === "Confirmed")
                                .map((staff, index) => (
                                    <div key={index} style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start', flexDirection: 'column' }}>
                                        <span style={{ fontSize: '14px' }}>
                                            <div
                                                className={styles.tableButton}
                                                onClick={() => generateStaffTimesheet(record.storm_date, record.storm_date_end, staff.staff_name, record.vehicle_name)}
                                                style={{ fontSize: '14px', padding: '8px 15px', cursor: 'pointer', marginBottom: 10 }}
                                            >
                                                Timesheet - {staff.staff_name}
                                            </div>
                                        </span>
                                    </div>
                                ))
                        ) : (
                            <div>
                                <span style={{ color: 'red' }}><em>* </em></span>
                                <span style={{ fontStyle: 'italic' }}>No confirmed staff</span>
                                <span style={{ color: 'red' }}><em> *</em></span>
                            </div>
                        )}
                    </div>
                ) : (
                    <div>
                        <span style={{ color: 'red' }}><em>* </em></span>
                        <span><em>Associated storm's start time and end time must be specified before generating timesheet</em></span>
                        <span style={{ color: 'red' }}><em> *</em></span>
                    </div>
                )}
            </div>
        </div>
    )
    
    const loadersColumns = [
        {
            title: "Region",
            dataIndex: "region", // Use dataIndex for better integration with Ant Design
            key: "region",
            width: 60,
            sorter: (a, b) => {
                // Handle cases where region might be null or undefined
                const regionA = a.region ? a.region.toLowerCase() : "";
                const regionB = b.region ? b.region.toLowerCase() : "";
                if (regionA < regionB) return -1;
                if (regionA > regionB) return 1;
                return 0;
            },
            sortDirections: ['ascend', 'descend'],
            render: (text) => <b>{text}</b>,
        },
        {
            title: "Event",
            key: "storm_type",
            width: 180,
            // render: (record) => <span>{record.storm_type} ({record.storm_severity})</span>,
            render: (text, record) => {
                const { color, bg } = getSeverityColor(record.storm_severity);
                return (
                <div style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'center', flexDirection: 'column', width: '100%', height: '50px' }}>
                    {record.storm_type === "snow" || record.storm_type === "Snow Showers" || record.storm_type === "Isolated Snow Showers"
                    || record.storm_type === "Snow" || record.storm_type === "Chance of Snow" || record.storm_type === "Light Snow"
                    || record.storm_type === "Chance of Sleet/Snow" || record.storm_type === "Scattered Snow Showers" ? (
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'row', gap: '7px' }}>
                            <div style={{ 
                                backgroundColor: bg,
                                height: '30px', 
                                width: '30px', 
                                display: 'flex', 
                                alignItems: 'center', 
                                justifyContent: 'center', 
                                border: `1px solid ${color}`, 
                                borderRadius: '4px', 
                                color: color 
                            }}>
                                <IconSnowflake size={18} stroke={2} />
                            </div>
                            <div style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start', flexDirection: 'column' }}>
                                <span style={{ fontWeight: '600', fontSize: '16px' }}>{record.storm_type === "snow" ? "Snow" : record.storm_type}</span>
                                <span style={{ fontSize: '14px', marginTop: '-5px' }}>Severity: {record.storm_severity}</span>
                                <span style={{ fontSize: '14px', marginTop: '-5px' }}>
                                    Date: {new Intl.DateTimeFormat('en-US', { month: 'long', day: '2-digit', year: 'numeric' }).format(new Date(record.storm_date))}
                                </span> 
                                <span style={{ fontSize: '14px', marginTop: '-5px' }}>ID: {record.storm_id}</span>
                            </div>
                        </div>
                    ) : record.storm_type === "freezing_rain" || record.storm_type === "Wintry Mix" || record.storm_type === "Chance of Wintry Mix" || record.storm_type === "Fog" 
                    || record.storm_type === "Isolated Freezing Rain" || record.storm_type === "Chance of Freezing Rain"? (
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'row', gap: '7px' }}>
                            <div style={{ 
                                backgroundColor: bg,
                                height: '30px', 
                                width: '30px', 
                                display: 'flex', 
                                alignItems: 'center', 
                                justifyContent: 'center', 
                                border: `1px solid ${color}`, 
                                borderRadius: '4px', 
                                color: color 
                            }}>
                                <IconCloudRain size={18} stroke={2} />
                            </div>
                            <div style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start', flexDirection: 'column' }}>
                                <span style={{ fontWeight: '600', fontSize: '16px' }}>{record.storm_type === "freezing_rain" ? "Freezing Rain" : record.storm_type}</span>
                                <span style={{ fontSize: '14px', marginTop: '-5px' }}>Severity: {record.storm_severity}</span>
                                <span style={{ fontSize: '14px', marginTop: '-5px' }}>
                                    Date: {new Intl.DateTimeFormat('en-US', { month: 'long', day: '2-digit', year: 'numeric' }).format(new Date(record.storm_date))}
                                </span> 
                                <span style={{ fontSize: '14px', marginTop: '-5px' }}>ID: {record.storm_id}</span>
                            </div>
                        </div>
                    ) : record.storm_type === "salt" ? (
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'row', gap: '7px' }}>
                            <div style={{ 
                                backgroundColor: bg,
                                height: '30px', 
                                width: '30px', 
                                display: 'flex', 
                                alignItems: 'center', 
                                justifyContent: 'center', 
                                border: `1px solid ${color}`, 
                                borderRadius: '4px', 
                                color: color 
                            }}>
                                <IconGrain size={18} stroke={2} />
                            </div>
                            <div style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start', flexDirection: 'column' }}>
                                <span style={{ fontWeight: '600', fontSize: '16px' }}>Salting</span>
                                <span style={{ fontSize: '14px', marginTop: '-5px' }}>Severity: {record.storm_severity}</span>
                                <span style={{ fontSize: '14px', marginTop: '-5px' }}>
                                    Date: {new Intl.DateTimeFormat('en-US', { month: 'long', day: '2-digit', year: 'numeric' }).format(new Date(record.storm_date))}
                                </span> 
                                <span style={{ fontSize: '14px', marginTop: '-5px' }}>ID: {record.storm_id}</span>
                            </div>
                        </div>
                    ) : (
                        <div style={{ backgroundColor: '#FDEAEA', height: '30px', width: '30px', display: 'flex', alignItems: 'center', justifyContent: 'center', border: '1px solid #FF4D4E', borderRadius: '4px', color: "#FF4D4E" }}>
                            <IconTool size={18} stroke={2} />
                        </div>
                    )}
                </div>
            )}
        },
        {
            title: "Vehicle",
            key: "vehicle",
            width: 100,
            render: (text, record) => (
                <div style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'center', flexDirection: 'column', width: '100%', height: '50px' }}>
                <span style={{ fontWeight: '400', fontSize: '16px' }}><IconTractor size={14} style={{ marginRight: '3px' }} />{record.vehicle_name}</span>   
                </div>
            ),
        },
        {
            title: 'Staff Assigned',
            key: 'staff_assigned',
            width: 130,
            render: (text, record) => (
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'center' }}>
                {record.staff_details.length > 0 ? (
                    record.staff_details.map((staff) => (
                    <div key={staff.staff_id} style={{ marginBottom: '4px' }}>
                        <span><IconUser size={14} style={{ marginRight: '3px' }} /></span>
                        <span style={{ fontWeight: '400', fontSize: '16px' }}>{staff.staff_name}</span>
                    </div>
                    ))
                ) : (
                    <span>No Staff Assigned</span>
                )}
                </div>
            ),
        },
        {
            title: 'Sites Assigned',
            key: 'sites_assigned',
            width: 180,
            render: (text, record) => (
              <div style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'center', flexDirection: 'column', height: '85px' }}>
                {record.site_details.slice(0, 3).map((site) => (
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'flex-start',
                      flexDirection: 'row',
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: "ellipsis",
                      maxWidth: '250px'
                    }}
                    key={site.site_id}
                  >
                    <span style={{ fontWeight: '400', fontSize: '16px', marginRight: "4px" }}>
                      <IconBuildingFactory2 size={14} stroke={1.4} style={{ marginRight: '3px' }} />
                      {site.site_name}
                    </span>
                  </div>
                ))}
      
                {/* Show "+ x more sites" if there are more than 3 sites */}
                {record.site_details.length > 3 && (
                  <span style={{ fontSize: '14px', color: '#888' }}>
                    + {record.site_details.length - 3} more sites
                  </span>
                )}
      
                {record.site_details.length === 0 && 'No Sites Assigned'}
              </div>
            ),
        },
        {
            title: "Dispatched At",
            dataIndex: "dispatched_at",
            key: "dispatched_at",
            width: 100,
            render: (dispatched_at) =>
              dispatched_at ? <div style={{ display: 'flex', height: "50px", alignItems: 'flex-start', justifyContent: 'center', flexDirection: 'column' }}>
                <span>{moment(dispatched_at).format("MMMM Do YYYY")} </span>
                <span>{moment(dispatched_at).format("h:mm:ss a")}</span>
                </div> :<div style={{ display: 'flex', height: "85px", alignItems: 'flex-start', justifyContent: 'flex-start', flexDirection: 'column' }}>N/A</div>,
            sorter: (a, b) => moment(a.dispatched_at).unix() - moment(b.dispatched_at).unix(),
          },
          {
            title: "Dispatched For",
            dataIndex: "dispatched_for",
            key: "dispatched_for",
            width: 100,
            render: (dispatched_for) =>
              dispatched_for ? <div style={{ display: 'flex', height: "50px", alignItems: 'flex-start', justifyContent: 'center', flexDirection: 'column' }}>
                <span>{moment(dispatched_for).format("MMMM Do YYYY")} </span>
                <span>{moment(dispatched_for).format("h:mm:ss a")}</span>
                </div> :<div style={{ display: 'flex', height: "85px", alignItems: 'flex-start', justifyContent: 'flex-start', flexDirection: 'column' }}>N/A</div>,
            sorter: (a, b) => moment(a.dispatched_for).unix() - moment(b.dispatched_for).unix(),
          },
          {
            title: "Overall Confirmation",
            key: "overall_staff_confirmation",
            width: 80,
            render: (record) => (
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', width: '100%', height: '50px' }}>
                    {record.overall_staff_confirmation === "Confirmed" && (
                        <div style={{ backgroundColor: '#E5F2EC', height: '30px', width: '30px', display: 'flex', alignItems: 'center', justifyContent: 'center', border: '1px solid #034832', borderRadius: '4px', color: "#034832" }}>
                            <IconCheck size={18} stroke={2} />
                        </div>
                    )}
                    {record.overall_staff_confirmation === "Not Confirmed" && (
                        <Tooltip title={( record.staff_details.map((staff) => (<><li>{staff.staff_name} - {staff.status}</li></>)) )}>
                        <div style={{ backgroundColor: '#FDEAEA', height: '30px', width: '30px', display: 'flex', alignItems: 'center', justifyContent: 'center', border: '1px solid #FF4D4E', borderRadius: '4px', color: "#FF4D4E" }}>
                            <IconX size={18} stroke={2} />
                        </div>
                        </Tooltip>
                    )}
                    {record.overall_staff_confirmation === "Created" && (
                        <div style={{ backgroundColor: '#ebebeb', height: '30px', width: '30px', display: 'flex', alignItems: 'center', justifyContent: 'center', border: '1px solid #034832', borderRadius: '4px', color: "#034832" }}>
                            <IconCheck size={18} stroke={2} />
                        </div>
                    )}
                </div>
            ),
          }
    ]
    
    const loadersExpandedRow = (record) => (
        <div style={{ width: '100%', display: 'flex', alignItems: 'stretch', justifyContent: 'flex-start', flexDirection: 'row'}}>
        {/* Staff */}
        <div style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start', flexDirection: 'column', paddingRight: '30px', borderRight: "0.5px solid #e0e0e0" }}>
            <span style={{ fontWeight: '600', fontSize: '16px', marginBottom: '10px' }}>Staff Members:</span>
            <div style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start', flexDirection: 'column', gap: '10px' }}>
                {record.staff_details.map((staff, index) => (
                   <div key={index} style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'space-between', flexDirection: 'row', width: '400px', border: '1px solid #e0e0e0', borderRadius: '5px', padding: '5px 10px', backgroundColor: getStaffCardBackgroundColor(staff.status) }}>
                   <div 
                    style={{ 
                      display: 'flex', 
                      alignItems: 'flex-start', 
                      justifyContent: 'flex-start', 
                      flexDirection: 'column', 
                    }} 
                  >
                    <span style={{ fontWeight: '500', fontSize: '16px', marginRight: "4px" }}>
                      <IconUser size={14} style={{ marginRight: '3px' }} />
                      {staff.staff_name}
                    </span>
                    <span style={{ fontSize: '14px'}}>
                      {staff.staff_position}
                    </span>       
                  </div>
                  <div style={{ display: 'flex', alignItems: 'flex-start', flexDirection: 'column', justifyContent: 'flex-start', gap: "5px" }}>
                    <span style={{ fontSize: '14px' }}><span style={{ fontWeight: '500' }}>Contact Number:</span>  {staff.staff_phone}</span>
                    <span style={{ fontSize: '14px' }}><span style={{ fontWeight: '500' }}>Message Status:</span> <Tag>{staff.status === "undelivered" ? "Error In Delivery" : staff.status}</Tag><Tooltip onClick={() => handleResendDispatchMessage(record.dispatch_log_id, staff.staff_id, "loader")} title="Resend Message"><IconMessage size={14} style={{ cursor: 'pointer', paddingTop: '5px' }}/></Tooltip></span>
                </div>
                <div style={{ marginTop: '4px', display: 'flex', flexDirection:"column", gap: '4px' }}>
                        {/* Confirm Button - For all statuses with confirmation */}
                        <Popconfirm
                            title="Are you sure you want to confirm this dispatch response?"
                            onConfirm={() => handleConfirm(staff.dispatched_staff_id)}
                            okText="Yes"
                            cancelText="No"
                        >
                            <Button
                            type="primary"
                            style={{ backgroundColor: '#034832', borderColor: '#034832', color: 'white', width: '80px' }}
                            >
                            Confirm
                            </Button>
                        </Popconfirm>
                
                        {/* Deny Button - For all statuses with confirmation */}
                        <Popconfirm
                            title="Are you sure you want to deny this dispatch response?"
                            onConfirm={() => handleDeny(staff.dispatched_staff_id)}
                            okText="Yes"
                            cancelText="No"
                        >
                            <Button
                            type="primary"
                            style={{ backgroundColor: '#FF4D4E', borderColor: '#FF4D4E', color: 'white', width: '80px' }}
                            >
                            Deny
                            </Button>
                        </Popconfirm>
                    </div>
                  </div>
                ))}
            </div>
        </div>
        {/* Sites */}
        <div style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start', flexDirection: 'column', paddingLeft: '30px', paddingRight: '30px', borderRight: "0.5px solid #e0e0e0" }}>
            <span style={{ fontWeight: '600', fontSize: '16px', marginBottom: '10px' }}>Sites:</span>
            <div style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start', flexDirection: 'column', gap: '5px' }}>
                {record.site_details.map((site, index) => (
                    <div 
                    style={{ 
                      display: 'flex', 
                      alignItems: 'flex-start', 
                      justifyContent: 'flex-start', 
                      flexDirection: 'column',
                      border: '1px solid #e0e0e0', 
                      borderRadius: '5px', 
                      padding: '5px 10px', 
                      backgroundColor: '#f0f0f0',
                      width: "350px",
                      position: 'relative',
                    }} 
                    key={index}
                  >
                    <span style={{ fontWeight: '500', fontSize: '16px', marginRight: "4px" }}>
                      <IconBuildingFactory2 size={14} stroke={1.4} style={{ marginRight: '3px' }} />
                      {site.client_name}
                    </span>
                    <span style={{ fontSize: '14px', }}>
                      {site.site_name}
                    </span>    
                    <span style={{ fontSize: '14px', fontWeight: '500', position: 'absolute', top: '0', right: '0', marginTop: '5px', marginRight: '10px'}}>
                        {site.site_region}
                    </span>   
                  </div>
                ))}
            </div>
        </div>
        {/* Notes */}
        <div style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start', flexDirection: 'column', paddingLeft: '30px', paddingRight: '30px', borderRight: "0.5px solid #e0e0e0" }}>
            <span style={{ fontWeight: '600', fontSize: '16px', marginBottom: '10px', width:250 }}>Notes:</span>
            <div style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start', flexDirection: 'column', gap: '5px' }}>
                <div style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start', flexDirection: 'column' }}>
                    <span style={{ fontSize: '14px'}}>
                        {record.message || "N/A"}
                    </span>
                </div>
            </div>
        </div>
        {/* Generate Timesheet and Storm Time */}
        <div style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start', flexDirection: 'column', paddingLeft: '30px' }}>
            <span style={{ fontWeight: '600', fontSize: '16px', marginBottom: '10px' }}>Storm date and time:</span>
            <span style={{ fontSize: '14px', marginBottom: '5px' }}>
                <b>Start:</b> {record.storm_date ? dayjs.utc(record.storm_date).tz('America/New_York').format('MMMM Do YYYY, h:mm A') : 'N/A'}
            </span>
            <span style={{ fontSize: '14px', marginBottom: '10px' }}>
                <b>End:</b> {record.storm_date_end ? dayjs.utc(record.storm_date_end).tz('America/New_York').format('MMMM Do YYYY, h:mm A') : 'N/A'}
            </span>
            <span style={{ fontWeight: '600', fontSize: '16px', marginBottom: '10px' }}>Generate Timesheet:</span>
            {record.storm_date && record.storm_date_end ? (
                    <div style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start', flexDirection: 'column', gap: '5px' }}>
                        {record.staff_details.filter(staff => staff.status === "Confirmed").length > 0 ? (
                            record.staff_details
                                .filter(staff => staff.status === "Confirmed")
                                .map((staff, index) => (
                                    <div key={index} style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start', flexDirection: 'column' }}>
                                        <span style={{ fontSize: '14px' }}>
                                            <div
                                                className={styles.tableButton}
                                                onClick={() => generateStaffTimesheet(record.storm_date, record.storm_date_end, staff.staff_name, record.vehicle_name)}
                                                style={{ fontSize: '14px', padding: '8px 15px', cursor: 'pointer', marginBottom: 10 }}
                                            >
                                                Timesheet - {staff.staff_name}
                                            </div>
                                        </span>
                                    </div>
                                ))
                        ) : (
                            <div>
                                <span style={{ color: 'red' }}><em>* </em></span>
                                <span style={{ fontStyle: 'italic' }}>No confirmed staff</span>
                                <span style={{ color: 'red' }}><em> *</em></span>
                            </div>
                        )}
                    </div>
                ) : (
                    <div>
                        <span style={{ color: 'red' }}><em>* </em></span>
                        <span><em>Associated storm's start time and end time must be specified before generating timesheet</em></span>
                        <span style={{ color: 'red' }}><em> *</em></span>
                    </div>
                )}
            </div>
        </div>
    )

    const fetchAllDispatchLogs = async () => {
        setLoading(true);
        try {
            const [walkwayResponse, saltingResponse, loadersResponse] = await Promise.all([
                api.get("/dispatch/dispatchLogs/Walkway/"),
                api.get("/dispatch/dispatchLogs/Salting/"),
                api.get("/dispatch/dispatchLogs/Loader/"),
            ]);

            setDispatchLogs({
                walkwayCrews: walkwayResponse.data,
                saltingRoutes: saltingResponse.data,
                loaders: loadersResponse.data,
            });

            // Extract unique regions for Walkway Crews
            const walkwayRegionsSet = new Set();
            walkwayResponse.data.forEach(item => {
                if(item.region) walkwayRegionsSet.add(item.region);
            });
            setWalkwayCrewRegions(['All', ...Array.from(walkwayRegionsSet).sort()]);

            // Extract unique regions for Salting Routes
            const saltingRegionsSet = new Set();
            saltingResponse.data.forEach(item => {
                if(item.region) saltingRegionsSet.add(item.region);
            });
            setSaltingRouteRegions(['All', ...Array.from(saltingRegionsSet).sort()]);

            // Extract unique regions for Loaders
            const loadersRegionsSet = new Set();
            loadersResponse.data.forEach(item => {
                if(item.region) loadersRegionsSet.add(item.region);
            });
            setLoadersRegions(['All', ...Array.from(loadersRegionsSet).sort()]);

            // Extract unique storm IDs and their details
            const stormMap = {};
            [...walkwayResponse.data, ...saltingResponse.data, ...loadersResponse.data].forEach(item => {
                if (item.storm_id && !stormMap[item.storm_id]) {
                    stormMap[item.storm_id] = {
                        id: item.storm_id,
                        type: formatStormType(item.storm_type),
                        date: item.storm_date,
                        created: item.storm_created,
                    };
                }
            });
            const uniqueStorms = Object.values(stormMap).sort((a, b) => new Date(b.date) - new Date(a.date));
            setStormOptions(['All', ...uniqueStorms]);

        } catch (error) {
            console.error("Failed to fetch dispatch logs:", error);
            message.error("Failed to fetch dispatch logs");
        } finally {
            setLoading(false);
        }
    };

    const handleConfirm = async (dispatched_staff_id) => {
        try {
          const response = await api.post("/dispatch/dispatchLogs/", {
            manual_confirm_dispatch: true,
            dispatched_staff_id: dispatched_staff_id,
          });
      
          if (response.status === 200) {
            message.success('Staff status set to Confirmed successfully!');
          } else {
            message.error('Failed to confirm the status.');
          }
        } catch {
          message.error('Failed to confirm the status.');
          console.log('Failed to confirm the status.');
        } finally {
            fetchAllDispatchLogs();
        }
      };
    
      const handleDeny = async (dispatched_staff_id) => {
        try {
          const response = await api.post("/dispatch/dispatchLogs/", {
            manual_deny_dispatch: true,
            dispatched_staff_id: dispatched_staff_id,
          });
      
          if (response.status === 200) {
            message.success('Staff status set to Denied successfully!');
          } else {
            message.error('Failed to confirm the message.');
          }
        } catch {
          message.error('Failed to confirm the message.');
          console.log('Failed to confirm the message.');
        } finally {
            fetchAllDispatchLogs();
        }
      };


    const handleGenerateReport = async () => {
        try {
            const regionMap = {
                "Walkway Crew's": selectedWalkwayCrewRegion,
                'Salting Routes': selectedSaltingRouteRegion,
                Loaders: selectedLoadersRegion,
            };
    
            const region = regionMap[selectedObjectType] !== 'All' ? regionMap[selectedObjectType] : undefined;
    
            const response = await api.get('/dispatch/storm-report/', {
                params: { region },
                responseType: 'blob',
            });
    
            const blob = new Blob([response.data], { type: 'application/pdf' });
            const link = document.createElement('a');
            link.href = URL.createObjectURL(blob);
            link.download = `storm_report_${region || 'all'}.pdf`;
            link.click();
        } catch (error) {
            console.error('Error generating report:', error);
            message.error('Failed to generate the storm report.');
        }
    };

    const handleGenerateSingleReport = async () => {
        try {
            if (selectedStormId === 'All') {
                message.error('Please select a specific storm to generate a report.');
                return;
            }
    
            message.loading({ content: 'Generating storm report...', key: 'storm_report' });
    
            const response = await api.get('/dispatch/single-storm-report/', {
                params: { storm_id: selectedStormId },
                responseType: 'blob',
            });
    
            if (response.status === 200) {
                const blob = new Blob([response.data], { type: 'application/pdf' });
    
                const url = window.URL.createObjectURL(blob);
    
                const link = document.createElement('a');
                link.href = url;
    
                link.setAttribute('download', `storm_report_single_${selectedStormId}.pdf`);
    
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
    
                message.success({ content: `Storm report downloaded for ID: ${selectedStormId}`, key: 'storm_report' });
            } else {
                console.error('Failed to generate storm report:', response.statusText);
                message.error('Failed to generate storm report. Please try again.');
            }
        } catch (error) {
            console.error('Error generating storm report:', error);
            message.error('An error occurred while generating the storm report.');
        }
    };

    const getButtonStyle = (list) => ({
        backgroundColor: selectedObjectType === list ? '#e6f2ed' : '#fff',
        color: selectedObjectType === list ? '#034832' : '#000',
        fontWeight: '550',
        padding: '8px 8px',
        fontSize: '13px',
        width: '140px'
    });

    useEffect(() => {
        fetchAllDispatchLogs();
    }, []);

    // Identify the storm with the latest 'storm_created'
    const latestCreatedStorm = useMemo(() => {
        // Filter out 'All' option and storms without 'created' field
        const stormsWithCreated = stormOptions.filter(storm => storm !== 'All' && storm.created);
        if (stormsWithCreated.length === 0) return null;

        // Find the storm with the latest 'storm_created' timestamp
        return stormsWithCreated.reduce((latest, current) => {
            return new Date(current.created) > new Date(latest.created) ? current : latest;
        }, stormsWithCreated[0]);
    }, [stormOptions]);

    // Set the default storm filter to the latest storm based on 'storm_created'
    useEffect(() => {
        if (latestCreatedStorm) {
            setSelectedStormId(latestCreatedStorm.id);
        } else {
            setSelectedStormId('All');
        }
    }, [latestCreatedStorm]);

    // Reset selected regions when object type changes
    useEffect(() => {
        if(selectedObjectType === "Walkway Crew's") {
            setSelectedWalkwayCrewRegion('All');
        } else if(selectedObjectType === 'Salting Routes') {
            setSelectedSaltingRouteRegion('All');
        }
    }, [selectedObjectType]);

    // Handlers for region changes
    const handleWalkwayCrewRegionChange = (value) => {
        setSelectedWalkwayCrewRegion(value);
    };

    const handleSaltingRouteRegionChange = (value) => {
        setSelectedSaltingRouteRegion(value);
    };

    const handleLoadersRegionChange = (value) => {
        setSelectedLoadersRegion(value);
    };

    const getFilteredData = () => {
        let data = [];
        if(selectedObjectType === "Walkway Crew's") {
            data = dispatchLogs.walkwayCrews;
            if(selectedWalkwayCrewRegion !== 'All') {
                data = data.filter(item => item.region === selectedWalkwayCrewRegion);
            }
        } else if(selectedObjectType === 'Salting Routes') {
            data = dispatchLogs.saltingRoutes;
            if(selectedSaltingRouteRegion !== 'All') {
                data = data.filter(item => item.region === selectedSaltingRouteRegion);
            }
        } else if(selectedObjectType === 'Loaders') {
            data = dispatchLogs.loaders;
            if(selectedLoadersRegion !== 'All') {
                data = data.filter(item => item.region === selectedLoadersRegion);
            }
        }
    
        // Apply storm ID filter
        if (selectedStormId !== 'All') {
            data = data.filter(item => item.storm_id === selectedStormId);
        }
    
        // Apply vehicle search filter
        if (vehicleSearch.trim() !== '') {
            data = data.filter(item => 
                item.vehicle_name?.toLowerCase().includes(vehicleSearch.toLowerCase())
            );
        }
    
        // Apply staff search filter
        if (staffSearch.trim() !== '') {
            data = data.filter(item => 
                item.staff_details.some(staff => 
                    staff.staff_name.toLowerCase().includes(staffSearch.toLowerCase())
                )
            );
        }
    
        // Apply site search filter
        if (siteSearch.trim() !== '') {
            data = data.filter(item => 
                item.site_details.some(site => 
                    site.site_name.toLowerCase().includes(siteSearch.toLowerCase())
                )
            );
        }
    
        return data;
    };

    const formatStormType = (stormType) => {
        if (!stormType) return '';
        return stormType
            .split('_') // Split by underscores
            .map(word => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize the first letter of each word
            .join(' '); // Join the words with a space
    };

    const items = [
        {
          key: '1',
          label: 'Generate Regional Storm Report',
          icon: <IconFile size={16} />,
          onClick: handleGenerateReport,
        },
        {
          key: '2',
          label: 'Generate Single Storm Report',
          icon: <IconFile size={16} />,
          onClick: handleGenerateSingleReport,
        },
      ];

    return (
        <ConfigProvider theme={{ token: { colorLinkHover: "#034832", colorPrimary: "#034832", controlItemBgActive: "#E4F2EB" } }}>
          <DndProvider backend={HTML5Backend}>
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'stretch', justifyContent: 'center', padding: '20px' }}>
          <div style={{ width: 'calc(100% - 10px)', marginLeft: '20px' }}>
                  <style>
                    {`.locked-row {
                        background-color: #f0f0f0 !important;
                      }
                    `}
                  </style>
                  <style>
                    {`.ant-table-tbody > tr:hover > td {
                        background: none !important;
                      }
                  `}
                  </style>
                  <div
                    style={{
                        display: 'flex',
                        width: '100%',
                        alignItems: 'flex-start',
                        justifyContent: 'space-between',
                        gap: '30px',
                        flexDirection: 'column'
                    }}
                    >
                    {/* Left-aligned elements: Span and New Buttons */}
                        <div style={{ display: 'flex', alignItems: 'center', width: '100%', flexDirection: 'row', justifyContent: 'space-between', marginBottom: '30px' }}>
                            <span
                                style={{
                                    fontFamily: "'Plus Jakarta Sans', sans-serif",
                                    fontWeight: '600',
                                    fontSize: '40px',
                                }}
                            >
                                Dispatch 
                                <span style={{ fontWeight: '400' }}> - History</span>
                            </span>
                            <Dropdown menu={{items}}>
                            <Button className={styles.tableButtonGreen} style={{ fontSize: '14px', padding: '8px 15px', marginLeft: 8 }}>
                                Download Reports <DownOutlined />
                            </Button>
                            </Dropdown>
                        </div>
                    </div>

                    {/* Right-aligned elements */}
                    <div style={{ display: 'flex', gap: '10px', flexWrap: 'wrap', marginBottom:15, justifyContent:'flex-start', width: '100%'}}>
                        {/* Object Type Toggle Buttons */}
                        <Select
                            value={
                                selectedObjectType === "Walkway Crew's"
                                    ? selectedWalkwayCrewRegion !== 'All'
                                        ? selectedWalkwayCrewRegion
                                        : undefined
                                    : selectedObjectType === 'Salting Routes'
                                    ? selectedSaltingRouteRegion !== 'All'
                                        ? selectedSaltingRouteRegion
                                        : undefined
                                    : selectedLoadersRegion !== 'All'
                                    ? selectedLoadersRegion
                                    : undefined
                            }
                            onChange={
                                selectedObjectType === "Walkway Crew's"
                                    ? handleWalkwayCrewRegionChange
                                    : selectedObjectType === 'Salting Routes'
                                    ? handleSaltingRouteRegionChange
                                    : handleLoadersRegionChange
                            }
                            style={{ width: 190 }}
                            placeholder="Region"
                        >
                            <Option key="all" value="All">
                                All
                            </Option>
                            {selectedObjectType === "Walkway Crew's" &&
                                [...new Set(walkwayCrewRegions.filter((region) => region !== "All"))].map((region) => (
                                    <Option key={region} value={region}>
                                        {region}
                                    </Option>
                                ))}
                            {selectedObjectType === 'Salting Routes' &&
                                [...new Set(saltingRouteRegions.filter((region) => region !== "All"))].map((region) => (
                                    <Option key={region} value={region}>
                                        {region}
                                    </Option>
                                ))}
                            {selectedObjectType === 'Loaders' &&
                                [...new Set(loadersRegions.filter((region) => region !== "All"))].map((region) => (
                                    <Option key={region} value={region}>
                                        {region}
                                    </Option>
                                ))}
                        </Select>
                    </div>
                    
                    <div style={{ display: 'flex', alignItems: 'center', gap: '5px', marginRight: 10, marginBottom:20}}>
                        <Select
                            showSearch
                            placeholder="Filter by Storm"
                            style={{ width: 400 }}
                            value={selectedStormId !== 'All' ? selectedStormId : undefined}
                            onChange={(value) => setSelectedStormId(value)}
                            filterOption={(input, option) =>
                                option.children.toLowerCase().includes(input.toLowerCase())
                            }
                        >
                            <Option key="all" value="All">
                                All Storms
                            </Option>
                            {stormOptions.filter(storm => storm !== 'All').map(storm => (
                                <Option key={storm.id} value={storm.id}>
                                    {`Type: ${storm.type} - ${storm.date ? moment(storm.date).format('MMMM Do, YYYY') : 'N/A'} - ID: ${storm.id}`}
                                </Option>
                            ))}
                        </Select>
                        <Input.Search
                            placeholder="Search vehicle"
                            onSearch={vehicleSearch}
                            onChange={(e) => setVehicleSearch(e.target.value)}
                            style={{ width: 190 }}
                        />
                        <Input.Search
                            placeholder="Search staff"
                            onSearch={staffSearch}
                            onChange={(e) => setStaffSearch(e.target.value)}
                            style={{ width: 190}}
                        />
                        <Input.Search
                            placeholder="Search site"
                            onSearch={siteSearch}
                            onChange={(e) => setSiteSearch(e.target.value)}
                            style={{ width: 190, marginRight: 'auto' }}
                        />
                        <Select
                            options={[{value: 'Walkway Crew\'s', label: 'Walkway Crew\'s'}, {value: 'Salting Routes', label: 'Salting Routes'}, {value: 'Loaders', label: 'Loaders'}]}   
                            value={selectedObjectType}
                            onChange={(value) => setSelectedObjectType(value)}
                            style={{ width: 190 }}  
                        />     
                        {/* <ToggleButtonGroup>
                        <ToggleButton
                            onClick={() => {
                            setSelectedObjectType("Walkway Crew's");
                            }}
                            isSelected={selectedObjectType === "Walkway Crew's"}
                            style={getButtonStyle("Walkway Crew's")}
                        >
                            Walkway Crew's
                        </ToggleButton>
                        <ToggleButton
                            onClick={() => {
                            setSelectedObjectType('Salting Routes');
                            }}
                            isSelected={selectedObjectType === 'Salting Routes'}
                            style={getButtonStyle('Salting Routes')}
                        >
                            Salting Routes
                        </ToggleButton>
                        <ToggleButton
                            onClick={() => {
                            setSelectedObjectType('Loaders');
                            }}
                            isSelected={selectedObjectType === 'Loaders'}
                            style={getButtonStyle('Loaders')}
                        >
                            Loaders
                        </ToggleButton>
                        </ToggleButtonGroup> */}
                    </div>

                <Spin spinning={loading}>
                    <div style={{ flex: 1, overflowY: 'auto', maxHeight: 'calc(100vh - 180px)' }}>
                      <Table
                        dataSource={getFilteredData()}
                        columns={selectedObjectType === "Walkway Crew's" ? walkwayCrewsColumns : selectedObjectType === 'Salting Routes' ? saltingRoutesColumns : loadersColumns}
                        rowKey="dispatch_log_id"
                        pagination={false}
                        scroll={{ y: 690 }}
                        expandable={{
                          expandedRowRender: (record) => (
                            selectedObjectType === "Walkway Crew's" ? walkwayCrewsExpandedRow(record) : 
                            selectedObjectType === 'Salting Routes' ? saltingRoutesExpandedRow(record) : 
                            loadersExpandedRow(record)
                          ),
                        }}
                        />
                    </div>
                    </Spin>
                </div>
              </div>
          </DndProvider>
        </ConfigProvider>
    );
};

export default DispatchHistoryPage;